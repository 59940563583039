import React, { cloneElement } from "react";

import { Loader, TabHeaders, Tabs } from "@transfr-inc/dashboard-components";

import { ChildOrganizationsTable } from "./child-orgs.tab";
import { OrganizationDetailTab } from "./organization-detail.tab";
import { UsersTabs } from "./users.tabs";

import { MULTI_ORG_PARENT } from "../../../../constants/organization-types";

import "./page.tabs.scss";

export const PageTabs = ({
  orgType,
  organization,
  classrooms,
  users,
  childOrgs,
  parentOrg,
  responsiveStyles,
  actionMenuOptions = {},
  bottomBarOptions,
  dataFiltered,
  isLoading,
}) => {
  const detailTab = OrganizationDetailTab({
    organization,
    parentOrg,
  });

  const childOrgsTab = ChildOrganizationsTable({
    data: childOrgs,
    parentOrg: organization,
    responsiveStyles,
    actionMenuOptions,
    bottomBarOptions,
  });

  const usersTabs = UsersTabs({
    data: users,
    classrooms,
    isMultiOrg: orgType === MULTI_ORG_PARENT.id,
    responsiveStyles,
    actionMenuOptions,
    bottomBarOptions,
    dataFiltered,
  });

  const firstTab = orgType === MULTI_ORG_PARENT.id ? childOrgsTab : detailTab;

  return (
    <>
      <Tabs className={"org-detail-tabs"} selectedTabName={firstTab.name}>
        <TabHeaders>
          {firstTab.header}
          {usersTabs.map(({ header, name }, i) =>
            cloneElement(header, { key: `tab-header-${name}-${i}` })
          )}
        </TabHeaders>
        {firstTab.content}
        {usersTabs.map(({ content, name }, i) =>
          cloneElement(content, { key: `tab-content-${name}-${i}` })
        )}
      </Tabs>
      {isLoading && <Loader fixed />}
    </>
  );
};
