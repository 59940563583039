import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { services } from "../../dependencies";
import { SearchList } from "./search-list";
import { useApiRequest } from "../../utils/http-client";
import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";
import { Role as ClientRole } from "@transfr-inc/dashboard-components/utils";
import { catchEditUserError } from "../../services";
import {
  MenuOptionSpec,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import {
  DeleteUserModal,
  EditUserModal,
  ResendInviteModal,
} from "../../components/modals";

import "./search.scss";

export default function Search({ responsiveStyles }) {
  const [searchTerm, setSearchTerm] = useState("");
  const { userService, authenticationService } = services;

  const {
    response: searchResults,
    sendRequest: getSearchResults,
    loading,
  } = useApiRequest(() => userService.searchUsers(searchTerm), false);

  async function onSearch() {
    searchTerm && getSearchResults();
  }

  const onChangeSearchTerm = (value) => {
    setSearchTerm(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchTerm && getSearchResults();
    }
  };

  const [notification, setNotification] = useState();

  // For delete user
  const [deletedUser, setDeletedUser] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState();

  // For edit user modal
  const [selectedUser, setSelectedUser] = useState();
  const [openEditUserModal, setOpenEditUserModal] = useState();

  // For resend invite
  const [openInviteModal, setOpenInviteModal] = useState();

  async function launchImpersonation(rowData) {
    try {
      await authenticationService.impersonate(rowData[0]);
    } catch {
      showNotification({
        type: NotificationType.error,
        message: <span>Something went wrong.</span>,
      });
    }
  }

  const showNotification = ({ type, message }) => {
    setNotification({ type, message });
    setTimeout(() => setNotification(), 5000);
  };

  // Delete User
  const onDeleteUser = async (key, value) => {
    setDeletedUser([value]);
    setOpenDeleteModal(true);
  };

  const onCloseDeleteUsers = () => {
    setOpenDeleteModal();
  };

  const onConfirmDeleteUsers = async (users) => {
    try {
      const userIds = users.map((x) => x["userId"]);
      await userService.deleteUsers({
        userIds: userIds,
        roleId: users[0]["roleId"],
      });
      onCloseDeleteUsers();
      getSearchResults();
      showNotification({
        type: NotificationType.success,
        message: <div>User has been deleted</div>,
      });
    } catch {
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong",
      });
    }
  };

  //Edit User
  const onOpenEditUser = async (data) => {
    const user = await userService.getUserDetails(data[0]);
    setSelectedUser(user);
    setOpenEditUserModal(true);
  };
  const onCloseEditDetails = () => {
    setOpenEditUserModal();
    setSelectedUser();
  };

  const onUpdateUser = async (user) => {
    try {
      const updatedUser = await userService.updateUserMetadata(user);
      showNotification({
        type: NotificationType.success,
        message: `User ${updatedUser.firstName} ${updatedUser.lastName} has been updated successfully.`,
        icon: ["fa-solid", "circle-check"],
      });

      onCloseEditDetails();
      getSearchResults();
      return updatedUser;
    } catch (error) {
      console.error(error);
      user.error = catchEditUserError(
        error,
        user.roleId == ClientRole.STUDENT.id
      );
      return user;
    }
  };

  // Resend Invite
  const onOpenInviteUser = async (key, value) => {
    setSelectedUser(value);
    setOpenInviteModal(true);
  };
  const onInvitationSentHandler = () => {
    setOpenInviteModal();
    showNotification({
      type: NotificationType.success,
      message: (
        <div>
          An account creation email has been sent to
          <b>
            {" "}
            {selectedUser.firstName} {selectedUser.lastName}
          </b>
        </div>
      ),
      icon: ["fa-solid", "circle-check"],
    });
    setSelectedUser();
  };
  const onCloseResendInvite = () => {
    setOpenInviteModal();
    setSelectedUser();
  };

  const actionMenuOptions = {
    delete: new MenuOptionSpec(
      "Delete",
      ["fa-regular", "fa-trash-can"],
      onDeleteUser
    ),
    impersonate: new MenuOptionSpec(
      "Impersonate",
      ["fa-regular", "fa-mask"],
      (rowData) => launchImpersonation(rowData)
    ),
    editUser: new MenuOptionSpec(
      "Edit Details",
      ["fa-regular", "fa-edit"],
      onOpenEditUser
    ),
    sendInvite: new MenuOptionSpec(
      "Send Invitation",
      ["fa-regular", "fa-paper-plane"],
      onOpenInviteUser
    ),
  };

  return (
    <PageLayout
      className="user-search-page"
      drawerColorEnabled
      header={
        <PageHeader title={"Search"} drawerColorEnabled>
          <div className="search-page">
            <div className="search-box">
              <FontAwesomeIcon
                icon={["fa-light", "magnifying-glass"]}
              ></FontAwesomeIcon>
              <input
                className="search-input"
                type="text"
                value={searchTerm}
                name="search-box"
                placeholder={"Search"}
                disabled={loading}
                onChange={(e) => onChangeSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </PageHeader>
      }
    >
      <SearchList
        responsiveStyles={responsiveStyles}
        users={searchResults || []}
        actionMenuOptions={actionMenuOptions}
        isLoading={loading}
        dataFiltered
      ></SearchList>
      <DeleteUserModal
        open={openDeleteModal}
        users={deletedUser}
        onCancel={onCloseDeleteUsers}
        onConfirmDelete={onConfirmDeleteUsers}
        clientUser
        async
      ></DeleteUserModal>
      <EditUserModal
        open={openEditUserModal}
        onClose={onCloseEditDetails}
        user={selectedUser}
        onUpdateUser={onUpdateUser}
        clientUser
      ></EditUserModal>
      <ResendInviteModal
        open={openInviteModal}
        userId={selectedUser?.userId}
        onClose={onCloseResendInvite}
        onInvitationSent={onInvitationSentHandler}
      ></ResendInviteModal>
      <div className="notification-container success-notification">
        {notification && (
          <Notification
            type={notification.type}
            icon={notification.icon}
            onClose={() => {
              setNotification();
            }}
            closable
            animated
          >
            {notification.message}
          </Notification>
        )}
      </div>
    </PageLayout>
  );
}
