import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Breadcrumb,
  CountText,
  Toolbar,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { PageHeader } from "@transfr-inc/dashboard-components/layouts";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import {
  EditOrganizationModal,
  NewClientUserModal,
} from "../../../../components/modals";

import {
  getOrgTypeName,
  MULTI_ORG_CHILD,
} from "../../../../constants/organization-types";
import { SessionContext } from "../../../../context";

import { services } from "../../../../dependencies";
import { useApiRequest } from "../../../../utils/http-client";
import "./page.header.scss";

export const OrgDetailPageHeader = ({
  organization,
  breadcrumb,
  onUserCreated,
  onOrgUpdated,
  parentOrg,
  onParentChange,
}) => {
  const { goBack } = useHistory();
  const [openCreateUserModal, setOpenCreateUserModal] = useState();
  const [openEditModal, setOpenEditModal] = useState();
  const { features } = useContext(SessionContext);
  const { canCreateClientUser, canEditOrg } = features;
  const { organizationService } = services;

  const { response: orgData, sendRequest: getOrgDetails } = useApiRequest(
    () => organizationService.getOrganizationDetails(organization?.code),
    false,
  );

  // Create New User
  const onCreateNewUserClick = () => {
    setOpenCreateUserModal(true);
  };

  const onCreateNewUserClose = () => {
    setOpenCreateUserModal();
  };

  const onUserCreatedHandler = (message) => {
    setOpenCreateUserModal();
    onUserCreated && onUserCreated(message);
  };

  // Edit Organization
  const onOpenEditOrg = async () => {
    await getOrgDetails();
    setOpenEditModal(true);
  };

  const onCloseEditOrg = () => {
    setOpenEditModal(false);
  };

  const onUpdateOrganization = (data) => {
    setOpenEditModal();
    onOrgUpdated && onOrgUpdated(data);
  };

  const getOrgData = (parentOrg) => {
    if (orgData?.typeId == MULTI_ORG_CHILD.id) {
      orgData.parent = parentOrg;
    }
    return orgData;
  };

  const toolbar = (
    <Toolbar>
      {canEditOrg && (
        <Button
          icon={["fa-regular", "pen-to-square"]}
          title={"Edit Organization"}
          onClick={onOpenEditOrg}
        />
      )}
      {canCreateClientUser && (
        <Button
          icon={["fa-regular", "user-plus"]}
          primary
          title={"Create User"}
          onClick={onCreateNewUserClick}
        />
      )}
    </Toolbar>
  );

  const subtitle = (
    <ul className="metadata">
      <li>Code: {organization?.code ?? "..."}</li>
      <li>Type: {getOrgTypeName(organization?.typeId) ?? "..."}</li>
      {organization?.expiration && (
        <li>Expiration: {formatDateDigits(organization?.expiration)}</li>
      )}
      {organization?.seats > 0 && (
        <li>
          Trainee Limit: <CountText count={organization?.seats}></CountText>
        </li>
      )}
    </ul>
  );

  return (
    <>
      <PageHeader
        title={organization?.name ?? "Loading..."}
        subTitle={subtitle}
        breadcrumb={<Breadcrumb onClick={goBack} label={breadcrumb} />}
        drawerColorEnabled
        className={"detail"}
      >
        {toolbar}
      </PageHeader>
      <NewClientUserModal
        organization={organization}
        open={openCreateUserModal}
        onClose={onCreateNewUserClose}
        onUserCreated={onUserCreatedHandler}
      ></NewClientUserModal>
      <EditOrganizationModal
        open={openEditModal}
        onClose={onCloseEditOrg}
        organization={getOrgData(parentOrg)}
        onUpdateOrg={onUpdateOrganization}
        onParentChange={onParentChange}
      ></EditOrganizationModal>
    </>
  );
};
