import React, { useEffect, useState } from "react";
import { Input } from "@transfr-inc/dashboard-components/forms";
import {
  Notification,
  NotificationStack,
  NotificationType,
  RequiredItem,
} from "@transfr-inc/dashboard-components";
import { ValidationItem } from "@transfr-inc/dashboard-components/forms";

import "./user-info.scss";
import { useFormContext, Controller } from "react-hook-form";

/**
 * UserInfo component
 *
 * This component provides first name, last name, and email fields.
 *
 * If register, errors, and dirtyFields are not given values, FormContext is utilized to obtain register, errors, and
 * dirtyFields form properties. If values are provided for all three props, they will be used during component
 * registration. Otherwise, an error is thrown; in other words, you must specify values for all three or leave all three
 * unspecified.
 */
export function UserInfo({
  responseNotification,
  onNotificationChange,
  defaultValues,
  register,
  control,
  formState,
  emailRequired = false,
  passwordEnabled = false,
  passwordRequired = false,
  usernameEnabled = false,
}) {
  const [notifications, setNotifications] = useState();

  if (!register && !formState && !control) {
    ({ register, formState, control } = useFormContext());
  } else if (!register || !formState || !control) {
    throw (
      "UserInfo: for register, control, and formState, all values must be specified or entirely left unspecified " +
      "Specifying a value for only the errors prop, for example, will result in this error message."
    );
  }

  const { errors, dirtyFields } = formState;

  const checkErrors = () => {
    if (Object.keys(errors).length > 0) {
      const messages = new Set(Object.values(errors).map((e) => e.message));
      showError(...messages.values());
    } else {
      setNotifications();
    }
  };

  const showError = (...errors) => {
    setNotifications(
      errors.map((e) => ({
        type: NotificationType.error,
        message: e,
      }))
    );
  };

  useEffect(() => {
    if (errors) {
      checkErrors();
    }
  }, [formState]);

  return (
    <div className="user-details">
      <div className="validation-errors">
        {notifications?.length && (
          <NotificationStack notifications={notifications}></NotificationStack>
        )}
        {responseNotification?.type == NotificationType.error && (
          <div className="error">
            <Notification
              {...responseNotification}
              closable
              animated
              onClose={() => onNotificationChange()}
            >
              {responseNotification.message}
            </Notification>
          </div>
        )}
      </div>
      <div>
        <div className="half-length-input">
          <Controller
            control={control}
            name="firstName"
            defaultValue={defaultValues?.firstName}
            rules={{
              required: {
                value: true,
                message: "Required fields are missing.",
              },
              maxLength: 50,
              pattern: {
                value: /^[\d ',.a-z-]+$/i,
                message:
                  "First name formatted incorrectly. Only letters, hyphens, numbers, and spaces are allowed.",
              },
            }}
            render={({ field: { onBlur, onChange, value, ref } }) => {
              return (
                <Input
                  className="name-input"
                  label={<RequiredItem text="First Name" />}
                  type="text"
                  name="firstName"
                  errors={errors?.firstName}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value ?? ""}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="lastName"
            defaultValue={defaultValues?.lastName}
            rules={{
              required: {
                value: true,
                message: "Required fields are missing.",
              },
              maxLength: 50,
              pattern: {
                value: /^[\d ',.a-z-]+$/i,
                message:
                  "Last name formatted incorrectly. Only letters, hyphens, numbers, and spaces are allowed.",
              },
            }}
            render={({ field: { onBlur, onChange, value, ref } }) => {
              return (
                <Input
                  className="name-input"
                  label={<RequiredItem text="Last Name" />}
                  type="text"
                  name="lastName"
                  errors={errors?.lastName}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value ?? ""}
                />
              );
            }}
          />
        </div>
      </div>
      {usernameEnabled && (
        <div className="full-length-input">
          <Controller
            control={control}
            name="username"
            defaultValue={defaultValues?.username}
            rules={{
              required: {
                value: true,
                message: "Required fields are missing.",
              },
              maxLength: 50,
            }}
            render={({ field: { onBlur, onChange, value, ref } }) => {
              return (
                <Input
                  className="name-input"
                  label={<RequiredItem text="Username" />}
                  type="text"
                  name="username"
                  errors={errors?.username}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  defaultValue={defaultValues?.username}
                  autocomplete="new-password"
                />
              );
            }}
          />
        </div>
      )}
      {passwordEnabled && (
        <div className="full-length-input">
          <Controller
            control={control}
            name="password"
            defaultValue={defaultValues?.password}
            rules={{
              onBlur: checkErrors,
              required: {
                value: passwordRequired,
                message: "Required fields are missing.",
              },
              maxLength: 50,
              validate: {
                count: (v) =>
                  v.length >= 4 || "Password must be at least 4 characters",
                whiteSpace: (v) =>
                  /^\S*$/.test(v) || "White spaces are not allowed in password",
              },
            }}
            render={({ field: { onBlur, onChange, value, ref } }) => {
              return (
                <Input
                  className="name-input"
                  label={<RequiredItem text="Password" />}
                  type="password"
                  errors={errors?.password}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  defaultValue={defaultValues?.password}
                  autocomplete="new-password"
                />
              );
            }}
          />
        </div>
      )}
      <div className="full-length-input">
        <Controller
          control={control}
          name="email"
          defaultValue={defaultValues?.email}
          rules={{
            required: {
              value: emailRequired,
              message: "Required fields are missing.",
            },
            maxLength: 255,
            pattern: {
              value:
                /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/,
              message: "Email formatted incorrectly.",
            },
          }}
          render={({ field: { onBlur, onChange, value, ref } }) => {
            return (
              <Input
                className="name-input"
                label={emailRequired ? <RequiredItem text="Email" /> : "Email"}
                type="email"
                name="email"
                errors={errors?.email}
                ref={ref}
                onBlur={onBlur}
                onChange={onChange}
                value={value ?? ""}
              />
            );
          }}
        />
      </div>
      {passwordEnabled && (
        <div className="requirements">
          <ul>
            <li>
              <div className="title">Password Requirements</div>
            </li>
            <li>
              <ValidationItem
                label={"Minimum of 4 characters"}
                valid={!errors.password?.types.count}
                enabled={dirtyFields.password}
              />
            </li>
            <li>
              <ValidationItem
                label={"No whitespace allowed"}
                valid={!errors.password?.types.whiteSpace}
                enabled={dirtyFields.password}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
