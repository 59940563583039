import clsx from "clsx";
import React from "react";

import { RequiredFieldsText } from "@transfr-inc/dashboard-components";

import "./org-form.editor.scss";

export function OrgFormEditor({ className, children }) {
  return (
    <div className={clsx("org-form-editor", className)}>
      <RequiredFieldsText />
      {children}
    </div>
  );
}
