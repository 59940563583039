import clsx from "clsx";
import React, { useEffect, useState } from "react";

import {
  Column,
  ColumnType,
  DataTable,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";
import { SearchBox, useSearch } from "@transfr-inc/dashboard-components/forms";
import { Product, Role } from "@transfr-inc/dashboard-components/utils";

import { ZeroStateMessage } from "../../../users/list/components/zero-state-message";
import { ClientUsersColumns } from "../../utils/users.columns";

import "./trainee-users.tab.scss";

export const TraineeUsersTab = ({
  data,
  classrooms,
  bottomBarOptions,
  actionMenuOptions,
  responsiveStyles = {},
  dataFiltered,
  className,
  searchEnabled = true,
  isRowActive,
}) => {
  const trekClassrooms = new Set(
    classrooms
      .filter((c) => c.products?.includes(Product.TRK))
      .map((c) => c.classroomId)
  );

  const isMenuActionVisible = (rowData, options) =>
    options.filter((o) => {
      if (o?.id === "impersonate-trek") {
        return (
          rowData.classrooms?.some((c) => trekClassrooms.has(c.classroomId)) ??
          false
        );
      }

      return true;
    });

  const columns = [
    ...(bottomBarOptions
      ? [new Column("select", undefined, ColumnType.SELECTION)]
      : []),
    ClientUsersColumns.AVATAR,
    ClientUsersColumns.LAST_NAME,
    ClientUsersColumns.FIRST_NAME,
    ClientUsersColumns.ORGANIZATION,
    ClientUsersColumns.CLASSROOM,
    ClientUsersColumns.USERNAME,
    ClientUsersColumns.EMAIL,
  ];

  const [trainees, setTrainees] = useState(data);
  const [searchActive, setSearchActive] = useState(false);

  const nameSearch = useSearch({
    data: data,
    searchKeys: ["firstName", "lastName", "username"],
  });

  const onResultSelected = (option) => {
    setTrainees([option]);
  };

  const onNameTermChange = (term) => {
    term.length > 0 && setSearchActive(true);

    const results = nameSearch.queryData(term);
    setTrainees(results);
  };

  useEffect(() => {
    const results = nameSearch.updateData(data);
    setTrainees(results);
  }, [data]);

  const zeroStateMessage = (
    <ZeroStateMessage
      role={Role.STUDENT}
      isFiltered={searchActive || dataFiltered}
    ></ZeroStateMessage>
  );

  return (
    <div className="trainees-tab">
      {trainees?.length > 0 && searchEnabled && (
        <SearchBox
          placeholder={"Search"}
          query={nameSearch.queryData}
          onSearchTermChange={onNameTermChange}
          onResultSelected={onResultSelected}
          customOptionLabel={(r) =>
            `${r?.lastName}, ${r?.firstName} (${r?.username})`
          }
        ></SearchBox>
      )}
      <div className="trainees-table-container">
        <DataTable
          columns={columns}
          data={trainees}
          className={clsx(
            "trainees-table",
            className,
            bottomBarOptions && "selection"
          )}
          keyFields={["userId"]}
          keyPrefix="trainee-users"
          defaultSortPropName="name"
          defaultSortDirection={SortDirection.ASCENDING}
          zeroStateMessage={zeroStateMessage}
          responsiveStyles={responsiveStyles}
          bottomBarOptions={bottomBarOptions}
          tableContentName={{ singular: "user", plural: "users" }}
          actionMenuOptions={actionMenuOptions}
          isMenuActionVisible={isMenuActionVisible}
          isRowActive={isRowActive}
          rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
          virtualRowEnabled
        />
      </div>
    </div>
  );
};
