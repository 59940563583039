import { PageLayout } from "@transfr-inc/dashboard-components/layouts";
import React, { useEffect, useState } from "react";

import {
  MenuOptionSpec,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Role as ClientRole } from "@transfr-inc/dashboard-components/utils";

import { OrgDetailPageHeader } from "./components/page.header";
import { PageTabs } from "./components/page.tabs";

import { useLocation } from "react-router-dom";
import { services } from "../../../dependencies";
import { catchEditUserError } from "../../../services";
import { useApiRequest } from "../../../utils/http-client";
import { getRoleMap } from "./utils/user-roles.utils";

import { MULTI_ORG_PARENT } from "../../../constants/organization-types";

import {
  DeleteUserModal,
  EditOrganizationModal,
  EditUserModal,
  ResendInviteModal,
} from "../../../components/modals";

import "./index.scss";

export const OrganizationDetail = ({ responsiveStyles }) => {
  const [notification, setNotification] = useState();
  const [parentOrg, setParentOrg] = useState();
  const { state } = useLocation();
  const { code, type, breadcrumbLabel, parent } = state;
  const {
    classroomService,
    organizationService,
    userService,
    authenticationService,
  } = services;

  // For edit organization modal
  const [orgSelected, setOrgSelected] = useState({});
  const [openEditOrg, setOpenEditOrg] = useState(false);

  const onParentChange = (parent) => {
    setParentOrg(parent);
    state.parent = parent;
  };

  // For delete user
  const [deletedUser, setDeletedUser] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState();

  // For edit user modal
  const [selectedUser, setSelectedUser] = useState();
  const [openEditUserModal, setOpenEditUserModal] = useState();

  // For resend invite
  const [openInviteModal, setOpenInviteModal] = useState();

  const {
    loading: loadingOrg,
    response: organization,
    sendRequest: getOrganizationDetails,
    resetResponse,
  } = useApiRequest(() => {
    resetResponse();
    return organizationService.getOrganizationDetails(code);
  }, false);

  const {
    response: classrooms,
    sendRequest: getClassrooms,
    resetResponse: resetClassrooms,
  } = useApiRequest(() => classroomService.getClassrooms(code, true), false);

  const {
    response: users,
    sendRequest: getClientUsers,
    resetResponse: resetUsers,
  } = useApiRequest(
    () => userService.getOrganizationUsers(code, type).then(getRoleMap),
    false
  );

  const {
    response: childOrgs,
    loading: loadingOrgs,
    sendRequest: getChildOrgs,
    resetResponse: resetChildOrgs,
  } = useApiRequest(() => organizationService.getChildOrgs(code), false);

  useEffect(() => {
    if (type === MULTI_ORG_PARENT.id) {
      getChildOrgs();
    }

    resetClassrooms();
    resetUsers();
    resetChildOrgs();
    getOrganizationDetails();
    getClassrooms();
    getClientUsers();
  }, [code, type]);

  useEffect(() => {
    if (organization?.typeId === MULTI_ORG_PARENT.id) {
      setParentOrg(organization);
    } else if (parent) {
      setParentOrg(parent);
    }
  }, [organization]);

  async function launchImpersonation(
    rowData,
    destination = process.env.DASHBOARD_URL
  ) {
    try {
      await authenticationService.impersonate(rowData[0], destination);
    } catch {
      showNotification({
        type: NotificationType.error,
        message: <span>Something went wrong.</span>,
      });
    }
  }

  const onUserCreated = (message) => {
    showNotification({ type: NotificationType.success, message });
    getClientUsers();
  };

  const showNotification = ({ type, message }) => {
    setNotification({ type, message });
    setTimeout(() => setNotification(), 5000);
  };

  // Edit Child Organization
  const onOpenEditOrg = async (code, data) => {
    const orgDetails = await organizationService.getOrganizationDetails(code);
    setOrgSelected({
      ...orgDetails,
      products: data.products,
      parent: organization,
    });
    setOpenEditOrg(true);
  };

  const onCloseEditOrg = () => {
    setOpenEditOrg(false);
  };

  const onUpdateOrganization = (data) => {
    getChildOrgs();
    setOpenEditOrg();
    showNotification({
      type: NotificationType.success,
      message: (
        <span>
          The details for <b>{data.name}</b> have been updated.
        </span>
      ),
    });
  };

  // Edit Organization
  const onOrgUpdated = async (data) => {
    await getOrganizationDetails();
    showNotification({
      type: NotificationType.success,
      message: (
        <span>
          The details for <b>{data.name}</b> have been updated.
        </span>
      ),
    });
  };

  // Delete User
  const onDeleteUser = async (key, value) => {
    setDeletedUser([value]);
    setOpenDeleteModal(true);
  };

  const onCloseDeleteUsers = () => {
    setOpenDeleteModal();
  };

  const onConfirmDeleteUsers = async (users) => {
    try {
      const userIds = users.map((x) => x["userId"]);
      await userService.deleteUsers({
        userIds: userIds,
        roleId: users[0]["roleId"],
      });
      onCloseDeleteUsers();
      getClientUsers();
      showNotification({
        type: NotificationType.success,
        message: <div>User has been deleted</div>,
      });
    } catch {
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong",
      });
    }
  };

  //Edit User
  const onOpenEditUser = async (data) => {
    const user = await userService.getUserDetails(data[0]);
    setSelectedUser(user);
    setOpenEditUserModal(true);
  };
  const onCloseEditDetails = () => {
    setOpenEditUserModal();
    setSelectedUser();
  };

  const onUpdateUser = async (user) => {
    try {
      const updatedUser = await userService.updateUserMetadata(user);
      showNotification({
        type: NotificationType.success,
        message: `User ${updatedUser.firstName} ${updatedUser.lastName} has been updated successfully.`,
        icon: ["fa-solid", "circle-check"],
      });

      onCloseEditDetails();
      getClientUsers();
      return updatedUser;
    } catch (error) {
      console.error(error);
      user.error = catchEditUserError(
        error,
        user.roleId == ClientRole.STUDENT.id
      );
      return user;
    }
  };

  // Resend Invite
  const onOpenInviteUser = async (key, value) => {
    setSelectedUser(value);
    setOpenInviteModal(true);
  };
  const onInvitationSentHandler = () => {
    setOpenInviteModal();
    showNotification({
      type: NotificationType.success,
      message: (
        <div>
          An account creation email has been sent to
          <b>
            {" "}
            {selectedUser.firstName} {selectedUser.lastName}
          </b>
        </div>
      ),
      icon: ["fa-solid", "circle-check"],
    });
    setSelectedUser();
  };
  const onCloseResendInvite = () => {
    setOpenInviteModal();
    setSelectedUser();
  };

  const actionMenuOptions = {
    delete: new MenuOptionSpec(
      "Delete",
      ["fa-regular", "fa-trash-can"],
      onDeleteUser
    ),
    impersonate: new MenuOptionSpec(
      "Impersonate",
      ["fa-regular", "fa-mask"],
      (rowData) => launchImpersonation(rowData)
    ),
    impersonateTrek: new MenuOptionSpec(
      "Impersonate (Trek)",
      ["fa-regular", "fa-mask"],
      (rowData) => launchImpersonation(rowData, process.env.CESE_URL),
      "impersonate-trek"
    ),
    edit: new MenuOptionSpec(
      "Edit Details",
      ["fa-regular", "fa-edit"],
      onOpenEditOrg
    ),
    editUser: new MenuOptionSpec(
      "Edit Details",
      ["fa-regular", "fa-edit"],
      onOpenEditUser
    ),
    sendInvite: new MenuOptionSpec(
      "Send Invitation",
      ["fa-regular", "fa-paper-plane"],
      onOpenInviteUser
    ),
  };

  return (
    <PageLayout
      className="organization-detail-page"
      drawerColorEnabled
      header={
        <OrgDetailPageHeader
          organization={organization}
          breadcrumb={breadcrumbLabel}
          onUserCreated={onUserCreated}
          onOrgUpdated={onOrgUpdated}
          parentOrg={parentOrg}
          onParentChange={onParentChange}
        />
      }
    >
      <PageTabs
        orgType={type}
        organization={organization}
        parentOrg={parentOrg}
        classrooms={classrooms}
        users={users}
        childOrgs={childOrgs}
        responsiveStyles={responsiveStyles}
        isLoading={loadingOrg || loadingOrgs}
        actionMenuOptions={actionMenuOptions}
      />
      <EditOrganizationModal
        open={openEditOrg}
        onClose={onCloseEditOrg}
        organization={orgSelected}
        onUpdateOrg={onUpdateOrganization}
      ></EditOrganizationModal>
      <DeleteUserModal
        open={openDeleteModal}
        users={deletedUser}
        onCancel={onCloseDeleteUsers}
        onConfirmDelete={onConfirmDeleteUsers}
        clientUser
        async
      ></DeleteUserModal>
      <EditUserModal
        open={openEditUserModal}
        onClose={onCloseEditDetails}
        user={selectedUser}
        onUpdateUser={onUpdateUser}
        clientUser
      ></EditUserModal>
      <ResendInviteModal
        open={openInviteModal}
        userId={selectedUser?.userId}
        onClose={onCloseResendInvite}
        onInvitationSent={onInvitationSentHandler}
      ></ResendInviteModal>
      <div className="notification-container success-notification">
        {notification && (
          <Notification
            type={notification.type}
            icon={notification.icon}
            onClose={() => {
              setNotification();
            }}
            closable
            animated
          >
            {notification.message}
          </Notification>
        )}
      </div>
    </PageLayout>
  );
};
