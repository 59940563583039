import HttpClient from "../utils/http-client";

export class TrekService extends HttpClient {
  async getOrganizationTreks(orgCode = null) {
    const url = "/careers";
    return this.http.get(url, { params: { org_code: orgCode } });
  }

  async upsertOrganizationCareers(orgCode, payload) {
    return await this.http.post(`/organizations/${orgCode}/careers`, payload);
  }
}
