import React, { useImperativeHandle, useRef } from "react";
import clsx from "clsx";
import Pwa from "../pwa";

import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import { NavigationBar, Routes } from "../components/navbar";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ContainerRoute from "../components/routing/container-route";

import "./app.scss";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 767,
  },
  "medium-panel": {
    minWidth: 768,
    maxWidth: 1024,
  },
  "large-panel": {
    minWidth: 1024,
  },
};

export default function AppLayout() {
  const { path } = useRouteMatch();
  const { path: defaultPath } = Routes[0];

  const [params, containerRef] = useContainerQuery(query);

  const contentRef = useRef();
  useImperativeHandle(containerRef, () => contentRef.current);

  const onSkipNavigation = (e) => {
    e.preventDefault();
    contentRef.current.focus();
  };

  return (
    <div className="app-layout">
      <NavigationBar onSkipNavigation={onSkipNavigation}></NavigationBar>
      <div
        ref={contentRef}
        className={clsx("route-container", params)}
        tabIndex="-1"
      >
        <Switch>
          {Routes.map(
            (route, index) =>
              !route.isExternalLink && (
                <ContainerRoute
                  key={index}
                  path={`${path}${route.path}`}
                  exact={route.exact}
                  component={route.component}
                  responsiveStyles={params}
                ></ContainerRoute>
              )
          )}
          <Route path="">
            <Redirect to={`${path}${defaultPath}`} />
          </Route>
        </Switch>
      </div>
      <Pwa></Pwa>
    </div>
  );
}
