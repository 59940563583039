import React from "react";
import { useHistory } from "react-router-dom";

import { Breadcrumb, Toolbar } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { PageHeader } from "@transfr-inc/dashboard-components/layouts";
import { UNIVERSAL_MENU_NAME } from "../../../../../constants/template-types";

import "./page.header.scss";

export const MenuDetailPageHeader = ({
  menu,
  breadcrumb,
  onEditMenuClick,
  onDeleteMenuClick,
}) => {
  const { goBack } = useHistory();

  const handleDelete = () => {
    onDeleteMenuClick(!!menu?.organizations?.length);
  };

  const menuEditable = menu.name !== UNIVERSAL_MENU_NAME;
  const toolbar = (
    <Toolbar>
      <Button
        icon={["fa-regular", "pen-to-square"]}
        title={"Edit Menu"}
        onClick={onEditMenuClick}
      />
      <Button
        icon={["fa-regular", "trash-can"]}
        title={"Delete Menu"}
        onClick={handleDelete}
      />
    </Toolbar>
  );

  return (
    <>
      <PageHeader
        title={menu?.name ?? "Loading..."}
        subTitle={menu?.description ?? "Loading..."}
        breadcrumb={<Breadcrumb onClick={goBack} label={breadcrumb} />}
        drawerColorEnabled
        className={"detail"}
      >
        {menuEditable && toolbar}
      </PageHeader>
    </>
  );
};
