import React from "react";

import { createRoot } from "react-dom/client";

import { caches, toggles } from "./dependencies";
import Router from "./router";

import SessionContext from "./context/session.context";
import UsersContext from "./context/users.context";
import OrganizationContext from "./context/organization.context";
import MenuBuilderContext from "./context/menu-builder-context";
import AppContext from "./context/app.context";

import "./index.scss";
import { initIcons } from "./lib/icon-set";

initIcons();
const appRoot = document.querySelector("#root");
const { userCache, tokenCache } = caches;

createRoot(appRoot).render(
  <SessionContext
    userCache={userCache}
    tokenCache={tokenCache}
    toggles={toggles}
  >
    <AppContext>
      <UsersContext>
        <OrganizationContext>
          <MenuBuilderContext>
            <Router />
          </MenuBuilderContext>
        </OrganizationContext>
      </UsersContext>
    </AppContext>
  </SessionContext>
);
