import React from "react";

export const ResetToDefaultSuccess =
  "This menu has been successfully reset. All custom changes have been removed.";

export const AddModulesSuccess = (categoryName, modules) => {
  const isMoreThanOne = modules.length > 1;
  const modulesNames = isMoreThanOne
    ? modules.map((m) => m.displayName).join(", ")
    : modules[0]?.displayName;
  const verb = isMoreThanOne ? "were" : "was";

  return modules.length > 0 ? (
    <>
      <strong>{modulesNames}</strong> {verb} added to {categoryName}
    </>
  ) : (
    <>
      Modules for category <strong>{categoryName}</strong> have been updated.
    </>
  );
};

export const AddCategorySuccess = (categoryName) => {
  return (
    <>
      Category <strong>{categoryName}</strong> has been created
    </>
  );
};

export const EditCategorySuccess = (category) => {
  return (
    <>
      Category <strong>{category.name}</strong> has been updated.
    </>
  );
};

export const EditMenuSuccess = (menu) => {
  return (
    <>
      Menu <strong>{menu.name}</strong> has been updated.
    </>
  );
};

export const UpdateModuleSimulationsSuccess = (module) => {
  const { moduleName } = module;
  return (
    <>
      Simulations for module <strong>{moduleName}</strong> have been updated.
    </>
  );
};

export const UpdateModulesSuccess = (modules) => {
  return (
    <>
      <strong>{modules.length}</strong> modules have been updated.
    </>
  );
};

export const UpdateCategoriesSuccess = () => {
  return <>Categories have been updated.</>;
};

export const DeleteMenuSuccess = (menu) => {
  return (
    <>
      <strong>{menu.name}</strong> has been deleted.
    </>
  );
};

export const DeleteCategorySuccess = (category) => {
  return (
    <>
      <strong>{category.name}</strong> has been deleted.
    </>
  );
};

export const GenericError = "Something went wrong. Please try again.";
