import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import {
  Button,
  CheckboxStates,
  TriCheckBox,
} from "@transfr-inc/dashboard-components/forms";

import { Badge } from "@transfr-inc/dashboard-components";
import { Category } from "../../constants/category.model";

import "./list-item.edition.scss";

export const Movement = {
  Up: 1,
  Down: 2,
};

const { CHECKED, EMPTY, INDETERMINATE } = CheckboxStates;

export const ListItemEdition = ({
  isChecked,
  onChecked,
  label,
  isUpEnabled,
  isDownEnabled,
  onChangeOrder,
  isIndeterminate,
  children,
  type,
}) => {
  const [checkboxValue, setCheckboxValue] = useState();

  const checkedState = useMemo(() => {
    return isIndeterminate ? INDETERMINATE : CHECKED;
  }, [isIndeterminate]);

  useEffect(() => {
    setCheckboxValue(isChecked ? checkedState : EMPTY);
  }, [isChecked, checkedState]);

  const showCustomBadge = useMemo(() => {
    if (type === Category.INTERNAL_CUSTOM) {
      return <Badge value={"Custom"} />;
    }
    return;
  }, [type]);

  return (
    <div className={clsx("list-item-container", isChecked && "selected")}>
      <TriCheckBox value={checkboxValue} onChange={onChecked}></TriCheckBox>
      {children ? children : <span>{label} </span>}
      {showCustomBadge}
      {(isUpEnabled || isDownEnabled) && (
        <div className="arrows-container">
          {isUpEnabled && (
            <Button
              className="arrow-button"
              icon={["fa-solid", "fa-up"]}
              onClick={() => onChangeOrder?.(Movement.Up)}
            />
          )}
          {isDownEnabled && (
            <Button
              className="arrow-button"
              icon={["fa-solid", "fa-down"]}
              onClick={() => onChangeOrder?.(Movement.Down)}
            />
          )}
        </div>
      )}
    </div>
  );
};
