import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import { RequiredItem } from "@transfr-inc/dashboard-components";
import { Textarea } from "@transfr-inc/dashboard-components/forms";
import "./category-form.scss";

export function CategoryDescEditor({ className, name }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={clsx("editor-section", className)}>
      <Controller
        control={control}
        name={name}
        rules={{
          required: {
            value: true,
            message: "Required fields are missing.",
          },
        }}
        render={({ field: { onBlur, onChange, value, ref } }) => {
          return (
            <Textarea
              label={
                <div className="desc-title">
                  <h2>Category Description *</h2>
                </div>
              }
              name={name}
              ref={ref}
              onBlur={onBlur}
              onInputChange={onChange}
              value={value}
              required
            />
          );
        }}
      />
    </div>
  );
}
