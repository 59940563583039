import clsx from "clsx";
import React from "react";

import { RequiredFieldsText } from "@transfr-inc/dashboard-components";

import "./user-form.section.scss";

export function UserFormSection({ className, children }) {
  return (
    <div className={clsx("user-form-editor", className)}>
      <RequiredFieldsText />
      {children}
    </div>
  );
}
