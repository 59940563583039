import React, { useEffect } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import { RequiredItem } from "@transfr-inc/dashboard-components";
import { OrganizationDropdown } from "../organization.dropdown";

import { services } from "../../../dependencies";
import { useApiRequest } from "../../../utils/http-client";

export const OrganizationSelectorSection = ({
  organization,
  required = false,
  onOrgChange,
  className,
}) => {
  const { control } = useFormContext();
  const { organizationService } = services;

  const { response: orgOptions, sendRequest: getChildOrgs } = useApiRequest(
    () => organizationService.getChildOrganizations(organization.code),
    false
  );

  useEffect(() => {
    if (organization) {
      getChildOrgs();
    }
  }, [organization]);

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>
          {required ? <RequiredItem text="Organization" /> : "Organization"}
        </h2>
      </div>
      <Controller
        control={control}
        name="org"
        rules={{
          required: {
            value: required,
            message: "Required fields are missing.",
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <OrganizationDropdown
              data={orgOptions}
              onOrgSelected={(e) => {
                onOrgChange && onOrgChange(e);
                onChange(e);
              }}
              selectedOrg={value}
            ></OrganizationDropdown>
          );
        }}
      />
    </div>
  );
};
