import clsx from "clsx";
import React from "react";

import { Link } from "@transfr-inc/dashboard-components";

import "./footer.scss";

export default function Footer({ className }) {
  return (
    <footer className={clsx(className)}>
      <div>
        Copyright &copy; <span id="current-year">2022</span> Transfr Inc. All
        Rights Reserved.
      </div>
      <ul>
        <li>
          <Link href="https://www.transfrvr.com/privacy" underline>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link href="https://www.transfrvr.com/termsofuse" underline>
            Terms of Use
          </Link>
        </li>
      </ul>
    </footer>
  );
}
