export const Role = {
  CS_ADMIN: {
    id: 10,
    displayName: "Admin",
    pluralDisplayName: "Admins",
    icon: "fa-light fa-users-gear",
    displayOrder: 1,
    description:
      "Can create clients, has full permissions to menu builder, manage users, and manage category templates.",
    features: {
      canCreateUser: true,
      canEditUser: true,
      canDeleteUser: true,
      canCreateOrg: true,
      canEditOrg: true,
      canCreateClientUser: true,
      canEditClientUser: true,
      canDeleteClientUser: true,
      canImpersonateClientUser: true,
      canImpersonateTrekUser: true,
    },
  },
  CS_MANAGER: {
    id: 11,
    displayName: "Manager",
    pluralDisplayName: "Managers",
    icon: "fa-light fa-user-lock",
    displayOrder: 2,
    description:
      "Can’t create clients, has limited permissions to menu builder, and has no access to category templates.",
    features: {
      canCreateUser: false,
      canEditUser: false,
      canDeleteUser: false,
      canCreateOrg: false,
      canEditOrg: false,
      canCreateClientUser: true,
      canEditClientUser: true,
      canDeleteClientUser: true,
      canImpersonateClientUser: true,
      canImpersonateTrekUser: true,
    },
  },
};

export const AllRoles = Object.values(Role);

export const hasRole = (values = [], roleId) => {
  return values.find((r) => r.id === roleId);
};

export const getRoleIconClassName = (id) => {
  return hasRole(AllRoles, id)?.roleIconClassName;
};

export const getRoleAvatarColor = (id) => {
  return hasRole(AllRoles, id)?.avatarColor;
};

export const getRoleName = (id) => {
  return hasRole(AllRoles, id)?.displayName;
};

export const getRoleFeatures = (id) => {
  return hasRole(AllRoles, id)?.features;
};

export const getRole = (id) => {
  return hasRole(AllRoles, id);
};

export const areRolesEqual = (lhs, rhs) => {
  return lhs.id === rhs.id;
};
