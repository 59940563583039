import React from "react";

import {
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";
import { templateColumns } from "./templates.columns";

import { ZeroStateMessage } from "./zero-state-message";

import "./templates.table.scss";
import { useRouteMatch } from "react-router-dom";
import { RootPath, TemplatesRoute } from "../../../../components/navbar";

export function TemplatesTable({
  data,
  responsiveStyles,
  actionMenuOptions,
  dataType,
  isMenuActionVisible,
  openMenuOrgsModal = null,
  className = "templates-table",
}) {
  const { path } = useRouteMatch(RootPath + TemplatesRoute.path);
  const columns = templateColumns(dataType, path, openMenuOrgsModal);

  const zeroStateMessage = <ZeroStateMessage />;

  return (
    <DataTable
      columns={columns}
      data={data}
      className={className}
      keyFields={["name"]}
      keyPrefix={"templates-table"}
      defaultSortPropName="name"
      defaultSortDirection={SortDirection.ASCENDING}
      responsiveStyles={responsiveStyles}
      zeroStateMessage={zeroStateMessage}
      tableContentName={{ singular: "template", plural: "templates" }}
      actionMenuOptions={actionMenuOptions}
      isMenuActionVisible={isMenuActionVisible}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
      virtualRowEnabled
    />
  );
}
