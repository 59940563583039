import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";

import { useForm, FormProvider } from "react-hook-form";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalHeader,
  FullScreenModalFooter,
  Notification,
  NotificationType,
  Loader,
  RequiredItem,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { Product } from "@transfr-inc/dashboard-components/utils";
import { TemplateTypeDropdown } from "../../../components/forms/template-type-dropdown";
import { MenuTemplateForm } from "../../../components/forms/menu-template";
import CategoriesForm from "../categories-form";
import { services } from "../../../dependencies";
import { MenuBuilderContext } from "../../../context";
import "./new-template-modal.scss";

const newMenuFormDefaultValues = {
  name: "",
  product: Product.TS,
  categories: [],
  description: "",
};

const newCategoryFormDefaultValues = {
  name: "",
  products: {
    displayName: "Training Simulations",
    value: 1,
    icon: "fa-light fa-objects-column",
    id: 1,
  },
  tsmodules: [],
  image: "",
  description: "",
};

export const NewTemplateModal = ({
  setShow,
  show,
  className,
  onTemplateCreated,
}) => {
  // State variables & functions for dropdowns
  const [loading, setLoading] = useState(true);
  const { categories } = useContext(MenuBuilderContext);

  const [formInvalid, setFormInvalid] = useState(true);
  const [notification, setNotification] = useState();
  const [templateType, setTemplateType] = useState();
  const { menuBuilderService } = services;

  const categoryMethods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: newCategoryFormDefaultValues,
    shouldUnregister: true,
  });

  const menuMethods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: newMenuFormDefaultValues,
    shouldUnregister: true,
  });

  const onClose = () => {
    categoryReset(newCategoryFormDefaultValues);
    menuReset(newMenuFormDefaultValues);
    setShow(false);
  };

  // refs:
  // https://react-hook-form.com/api/useform/formstate
  // https://react-hook-form.com/advanced-usage#FormProviderPerformance
  //
  // Although formState is not used in this component, we still need to "subscribe" to it due to the following mention
  // in the first link above:
  // "formState is wrapped with a Proxy to improve render performance and skip extra logic if specific state is not
  // subscribed to. Therefore make sure you invoke or read it before a render in order to enable the state update."
  // The second link demonstrates sample usage with FormProvider that we are employing here.

  const { handleSubmit: handleMenuSubmit, reset: menuReset } = menuMethods;
  const { handleSubmit: handleCategorySubmit, reset: categoryReset } =
    categoryMethods;

  menuMethods.formState.errors;
  menuMethods.formState.isValid;
  menuMethods.formState.dirtyFields;
  categoryMethods.formState.errors;
  categoryMethods.formState.isValid;
  categoryMethods.formState.dirtyFields;

  async function submitForm(values, e) {
    e.preventDefault();
    setLoading(true);

    if (templateType?.id === 1) {
      try {
        const menuInfo = {
          ...values,
          categories: values.categories.map(({ data: { id } }) => id),
        };
        const menu = await menuBuilderService.createMenu(menuInfo);
        onTemplateCreated(menu, true);
      } catch (error) {
        const message =
          error.data === undefined
            ? "Something went wrong. Please try again."
            : error.data.detail;
        setNotification({
          type: NotificationType.error,
          message,
        });
      } finally {
        setLoading();
      }
    } else if (templateType?.id === 2) {
      try {
        const mappedModules = values.tsmodules.map((mod) => ({
          moduleId: mod.id,
          moduleName: mod.text,
        }));
        const categoryData = {
          categoryName: values.name,
          categoryImage: values.image,
          categoryDescription: values.description,
          modules: mappedModules,
        };

        const category = await menuBuilderService.addTemplateCategory(
          categoryData
        );
        onTemplateCreated(category, false);
      } catch (error) {
        const message =
          error.data === undefined
            ? "Something went wrong. Please try again."
            : error.data.detail;
        setNotification({
          type: NotificationType.error,
          message,
        });
      } finally {
        setLoading();
      }
    }
  }

  const onClick = (e) => {
    (async () => {
      if (templateType?.id === 1) {
        await handleMenuSubmit(submitForm)(e);
        menuReset(newMenuFormDefaultValues);
      }
      if (templateType?.id === 2) {
        await handleCategorySubmit(submitForm)(e);
        categoryReset(newCategoryFormDefaultValues);
      }
    })();
  };

  useEffect(() => {
    if (templateType?.id === 1) {
      setFormInvalid(!menuMethods.formState.isValid);
    }
    if (templateType?.id === 2) {
      setFormInvalid(!categoryMethods.formState.isValid);
    }
  }, [menuMethods.formState.isValid, categoryMethods.formState.isValid]);

  useEffect(() => {
    if (show) {
      categoryReset(newCategoryFormDefaultValues);
      menuReset(newMenuFormDefaultValues);
      setLoading();
    }
  }, [show]);

  return (
    <div className={clsx("new-template-modal", className)}>
      <FullScreenModal onClose={onClose} open={show} preventClose>
        <FullScreenModalHeader
          title="Create a Template"
          titleIcon="fa-regular fa-layer-group"
        />
        <FullScreenModalBody>
          <div className="new-template">
            {loading && <Loader overlay></Loader>}
            <div className="text-with-info">
              <label>
                Required Fields<sup>*</sup>
              </label>
              <div className="template-info">
                {notification?.type == NotificationType.error && (
                  <div className="error">
                    <Notification
                      {...notification}
                      closable
                      animated
                      onClose={() => setNotification()}
                    >
                      {notification.message}
                    </Notification>
                  </div>
                )}
                <div className="template-type">
                  <div className="section-title">
                    <RequiredItem text="Template Type" />
                  </div>
                  <TemplateTypeDropdown
                    className="template-dropdown"
                    onTypeSelected={setTemplateType}
                    selectedType={templateType}
                  />
                  {templateType?.id === 1 && (
                    <FormProvider {...menuMethods}>
                      <form
                        className={clsx("template-form", className)}
                        noValidate={true}
                      >
                        <MenuTemplateForm categories={categories} />
                      </form>
                    </FormProvider>
                  )}
                  {templateType?.id === 2 && (
                    <FormProvider {...categoryMethods}>
                      <form
                        className={clsx("template-form", className)}
                        noValidate={true}
                      >
                        <CategoriesForm />
                      </form>
                    </FormProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </FullScreenModalBody>
        <FullScreenModalFooter>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button primary disabled={formInvalid || loading} onClick={onClick}>
            Create Template
          </Button>
        </FullScreenModalFooter>
      </FullScreenModal>
    </div>
  );
};

export default NewTemplateModal;
