import React, { useState, useContext, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useApiRequest } from "../../../utils/http-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";

import {
  Button,
  SearchBox,
  useSearch,
  FileInput,
} from "@transfr-inc/dashboard-components/forms";
import {
  Toolbar,
  Notification,
  TextTooltip,
  Loader,
  MenuOptionSpec,
  NotificationType,
  NotificationStack,
} from "@transfr-inc/dashboard-components";

import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";

import { NewOrgModal } from "../new-organization/new-org-modal";
import { OrganizationsTable } from "./components/organizations.table";
import { EditOrganizationModal } from "../../../components/modals";

import { OrganizationContext, SessionContext } from "../../../context";
import { OrganizationsRoute, RootPath } from "../../../components/navbar";
import { MULTI_ORG_CHILD } from "../../../constants/organization-types";
import { services } from "../../../dependencies";

import "./index.scss";
import { getOrganizationName } from "../utils/organizations.columns";

const ERROR_CSV_FILE = "errors.csv";

export const OrganizationList = ({ responsiveStyles }) => {
  const { url } = useRouteMatch(`${RootPath}${OrganizationsRoute.path}`);
  const history = useHistory();
  const { features } = useContext(SessionContext);
  const {
    organizations,
    orgsCount,
    loadingOrgs,
    updateOrganizations,
    organizationsNames,
  } = useContext(OrganizationContext);
  const { canCreateOrg } = features;
  const [orgSelected, setOrgSelected] = useState({});
  const { organizationService, userService } = services;
  const [uploadingUsers, setUploadingUsers] = useState();

  const [orgsData, setOrgsData] = useState();

  // Modals
  const [openCreateOrg, setOpenCreateOrg] = useState(false);
  const [openEditOrg, setOpenEditOrg] = useState(false);

  const [notifications, setNotifications] = useState();

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  const { loading: downloadingFile, sendRequest: exportParentChildPairs } =
    useApiRequest(() => {
      const filename = "organization-hierarchy.csv";

      return organizationService.exportParentChildPairs(filename);
    }, false);

  const organizationsSearch = useSearch({
    data: organizations,
    queryFn: (data, term) =>
      data.filter((d) =>
        getOrganizationName(d).toLowerCase().includes(term?.toLowerCase())
      ),
  });

  useEffect(() => {
    const results = organizationsSearch.updateData(organizations);
    setOrgsData(results);
  }, [organizations]);

  const showNotification = async (notification) => {
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onOrgCreated = async ({ notification, organization, parentOrg }) => {
    showNotification(notification);
    await updateOrganizations();
    goDetailPage(organization, parentOrg);
  };

  const goDetailPage = (organization, parentOrg) => {
    const { code, orgTypeId } = organization;
    history.push({
      pathname: `${url}/${code}`,
      state: {
        code,
        type: orgTypeId,
        parent: parentOrg,
        breadcrumbLabel: "All Organizations",
      },
    });
  };

  // Edit Organization
  const onOpenEditOrg = async (code, data) => {
    const orgDetails = await organizationService.getOrganizationDetails(code);
    const org = { ...orgDetails, products: data.products };
    if (org.typeId == MULTI_ORG_CHILD.id) {
      org.parent = data.organizations[0];
    }
    setOpenEditOrg(true);
    setOrgSelected(org);
  };

  const onCloseEditOrg = () => {
    setOpenEditOrg(false);
  };

  const onUpdateOrganization = (data) => {
    updateOrganizations();
    setOpenEditOrg();
    showNotification({
      type: NotificationType.success,
      message: (
        <span>
          The details for <b>{data.name}</b> have been updated.
        </span>
      ),
    });
  };

  const onSearchTermChange = (term) => {
    const results = organizationsSearch.queryData(term);
    setOrgsData(results);
  };

  const actionMenuOptions = {
    edit: new MenuOptionSpec(
      "Edit Organization",
      ["fa-light", "fa-edit"],
      onOpenEditOrg
    ),
  };

  const toolbar = (
    <Toolbar>
      <Button icon={["fa-regular", "circle-question"]} title={"Help"}></Button>
      {canCreateOrg && (
        <Button
          icon={["fa-regular", "plus"]}
          primary
          title={"Create Organization"}
          onClick={() => setOpenCreateOrg(true)}
        ></Button>
      )}
    </Toolbar>
  );

  const pageHeader = (
    <PageHeader title={"Organizations"} badge={loadingOrgs ? "..." : orgsCount}>
      {toolbar}
    </PageHeader>
  );

  const onHandleFile = async (file) => {
    const response = {};
    try {
      setUploadingUsers(true);
      const csvData = await userService.uploadUsers(file);
      const rows = csvData.output_rows;

      if (rows && rows.length > 0) {
        response.users = [];
        for (let row of rows) {
          if (row.userId) {
            response.users.push(row);
          }
        }
      }
      setUploadingUsers();
      if (response.users?.length > 0) {
        showNotification({
          type: NotificationType.success,
          icon: "fa-regular fa-head-side-goggles",
          closable: true,
          message: `${response.users?.length} User accounts have been created`,
        });
      }

      if (csvData.errors.length > 0) {
        showNotification({
          type: NotificationType.error,
          closable: true,
          message: (
            <>
              Errors Occurred. Please download{" "}
              <a
                className="error-link"
                href="#"
                onClick={() => downloadErrorCsv(csvData)}
              >
                {ERROR_CSV_FILE}
              </a>{" "}
              for more information.
            </>
          ),
        });
      }
    } catch (error) {
      setUploadingUsers();
      showNotification({
        type: NotificationType.error,
        closable: true,
        message:
          error.data === undefined
            ? "Something went wrong. Please try again."
            : error.data.detail,
      });
    }
  };

  const downloadErrorCsv = async (data) => {
    const csvString = await userService.getErrorsCsv(data);
    const blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
    saveAs(blob, ERROR_CSV_FILE);
  };

  return (
    <PageLayout className="organization-list-page" header={pageHeader}>
      {loadingOrgs && <Loader fixed></Loader>}

      <div className="subheader">
        <SearchBox
          className="sims-search"
          placeholder="Search organization name"
          data={organizationsNames}
          disabled={!organizations}
          onSearchTermChange={onSearchTermChange}
          onResultSelected={onSearchTermChange}
        ></SearchBox>
        <div className="file-button-group">
          <Button
            primary
            icon={["fa-light", "file-export"]}
            onClick={exportParentChildPairs}
          >
            Export Data (.csv)
          </Button>
          <FileInput
            primary={true}
            size="small"
            icon={["fa-light", "upload"]}
            className="main-dialog"
            onHandleFile={onHandleFile}
            label={"Import Users (.csv)"}
            disabled={uploadingUsers}
          ></FileInput>
          <TextTooltip
            text={
              "Please do not exceed 500 users. Column headers are 'Client Code', 'Role ID', 'First Name', 'Last Name', and 'Email'."
            }
            overflowEnabled
          >
            <FontAwesomeIcon icon="fa-light fa-circle-info" />
          </TextTooltip>
        </div>
      </div>
      <OrganizationsTable
        className={"cs-organizations-table"}
        data={orgsData}
        actionMenuOptions={actionMenuOptions}
        responsiveStyles={responsiveStyles}
      ></OrganizationsTable>
      <NewOrgModal
        show={openCreateOrg}
        setShow={() => setOpenCreateOrg()}
        onOrgCreated={onOrgCreated}
      />
      <EditOrganizationModal
        open={openEditOrg}
        onClose={onCloseEditOrg}
        organization={orgSelected}
        onUpdateOrg={onUpdateOrganization}
      ></EditOrganizationModal>
      <div className="notification-container success-notification">
        {notifications && (
          <NotificationStack
            notifications={notifications}
            closable
            animated
            onClose={onCloseNotification}
          ></NotificationStack>
        )}
      </div>

      <div className="notification-loader-container">
        {downloadingFile && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download of all organization parent-child pairs.
            Please wait...
          </Notification>
        )}
        {uploadingUsers && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re creating the uploaded users. Please wait...
          </Notification>
        )}
      </div>
    </PageLayout>
  );
};
