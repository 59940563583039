import React, { cloneElement, useContext } from "react";

import {
  Tabs,
  Tab,
  TabHeaders,
  TabHeader,
  TabBadge,
} from "@transfr-inc/dashboard-components";

import { Role } from "../../../../constants/role";
import { UsersTable } from "./users.table";
import { SessionContext } from "../../../../context";

import "./users.tabs.scss";

export const UsersTabs = ({
  data,
  responsiveStyles,
  actionMenuOptions,
  bottomBarOptions,
}) => {
  const { features } = useContext(SessionContext);
  const { canEditUser } = features;

  const adminData = data.get(Role.CS_ADMIN.id);
  const managerData = data.get(Role.CS_MANAGER.id);
  const { edit } = actionMenuOptions;

  const AdminUsersTab = {
    name: "cs-admins",
    header: (
      <TabHeader name="cs-admins" className="cs-admins-tab-header">
        <div className="tab-header-text">Admins</div>
        <TabBadge count={adminData.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="cs-admins">
        <UsersTable
          className="cs-users-table"
          data={adminData}
          role={Role.CS_ADMIN}
          responsiveStyles={responsiveStyles}
          bottomBarOptions={bottomBarOptions}
          actionMenuOptions={[canEditUser && edit]}
        ></UsersTable>
      </Tab>
    ),
  };

  const managersTab = {
    name: "cs-managers",
    header: (
      <TabHeader name="cs-managers" className="cs-managers-tab-header">
        <div className="tab-header-text">Managers</div>
        <TabBadge count={managerData.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="cs-managers">
        <UsersTable
          className="cs-users-table"
          data={managerData}
          role={Role.CS_MANAGER}
          responsiveStyles={responsiveStyles}
          bottomBarOptions={bottomBarOptions}
          actionMenuOptions={[canEditUser && edit]}
        ></UsersTable>
      </Tab>
    ),
  };

  const getUserTabs = () => {
    const tabs = [];
    tabs.push(AdminUsersTab, managersTab);

    return tabs;
  };

  const tabs = getUserTabs();

  return (
    <Tabs className={"users-tabs"} selectedTabName={tabs[0].name}>
      <TabHeaders>
        {tabs.map(({ header, name }, i) =>
          cloneElement(header, { key: `tab-header-${name}-${i}` })
        )}
      </TabHeaders>
      {tabs.map(({ content, name }, i) =>
        cloneElement(content, { key: `tab-content-${name}-${i}` })
      )}
    </Tabs>
  );
};
