import React from "react";
import clsx from "clsx";

import {
  Column,
  ColumnType,
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";

import { ClientUsersColumns } from "../../utils/users.columns";
import { ZeroStateMessage } from "../../../users/list/components/zero-state-message";

import "./admin-users.tab.scss";

export const AdminUsersTab = ({
  data,
  role,
  responsiveStyles,
  bottomBarOptions,
  actionMenuOptions,
  onSetShowNewUser,
  dataFiltered,
  className,
  isRowActive,
}) => {
  const columns = [
    ...(bottomBarOptions
      ? [new Column("select", undefined, ColumnType.SELECTION)]
      : []),
    ClientUsersColumns.AVATAR,
    ClientUsersColumns.LAST_NAME,
    ClientUsersColumns.FIRST_NAME,
    ClientUsersColumns.ORGANIZATION,
    ClientUsersColumns.EMAIL,
  ];

  const zeroStateMessage = (
    <ZeroStateMessage
      role={role}
      onShowButton={onSetShowNewUser}
      isFiltered={dataFiltered}
    ></ZeroStateMessage>
  );

  return (
    data && (
      <div className="admins-tab">
        <DataTable
          columns={columns}
          data={data}
          className={clsx(
            className,
            bottomBarOptions && "selection",
            "admins-table"
          )}
          keyFields={["userId"]}
          keyPrefix={"admin-users-" + role.id}
          defaultSortPropName="firstName"
          defaultSortDirection={SortDirection.ASCENDING}
          responsiveStyles={responsiveStyles}
          zeroStateMessage={zeroStateMessage}
          bottomBarOptions={bottomBarOptions}
          tableContentName={{ singular: "user", plural: "users" }}
          actionMenuOptions={actionMenuOptions}
          isRowActive={isRowActive}
          rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
          virtualRowEnabled
        />
      </div>
    )
  );
};
