import { useLocation } from "react-router-dom";

export function useQueryString() {
  const { search } = useLocation();
  return (
    search
      .slice(1)
      .split("&")
      // eslint-disable-next-line unicorn/no-array-reduce
      .reduce((acc, kvp) => {
        const [key, value] = kvp.split("=");
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {})
  );
}
