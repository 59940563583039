import React from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./zero-state-message.scss";

export function ZeroStateMessage({ className }) {
  const emptyStateText = `There are no organizations configured with this menu.`;
  return (
    <div className={clsx("zero-state-message", className)}>
      <div className="text">
        <FontAwesomeIcon icon={["fa-light", "user-plus"]} />
        <span>{emptyStateText}</span>
      </div>
    </div>
  );
}
