export const MENU = {
  id: 1,
  displayName: "Menu",
  icon: "fa-light fa-objects-column",
  displayOrder: 1,
  description: "",
};
export const CATEGORY = {
  id: 2,
  displayName: "Category",
  icon: "fa-light fa-objects-column",
  displayOrder: 2,
  description: "",
};

const templateTypesMap = new Map();
templateTypesMap.set(MENU.id, MENU);
templateTypesMap.set(CATEGORY.id, CATEGORY);

export function getTemplateType(typeId) {
  return templateTypesMap.get(typeId) ?? {};
}

export function getTemplateTypeName(typeId) {
  return templateTypesMap.get(typeId)?.displayName;
}

export const AllTemplateTypes = [...templateTypesMap.values()];

export const UNIVERSAL_MENU_NAME = "Universal Access";
