import React, { useContext } from "react";
import { AppContext } from "../../../../context";
import clsx from "clsx";

import { ProductsTabs } from "./products.tabs";
import { DisciplinesSection } from "./disciplines.section";

import "./products.section.scss";

export const ProductsSection = ({ organization, className }) => {
  const { products } = organization || {};
  const { ceEnabled } = useContext(AppContext);

  return (
    <div>
      {ceEnabled ? (
        <div className={clsx("products-section", className)}>
          <h2>
            Products <span className="badge">{products?.length}</span>{" "}
          </h2>
          <div className="box-section">
            <ProductsTabs organization={organization}></ProductsTabs>
          </div>
        </div>
      ) : (
        <DisciplinesSection organization={organization} />
      )}
    </div>
  );
};
