import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

import {
  Badge,
  HyphenIcon,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { EditMenuCategoriesModal } from "../modals/index.js";

import "./menu-builder.header.scss";
import { ActivityLogModal } from "../../../../../../../components/modals/activity-logs/activity-log.modal.jsx";
import { services } from "../../../../../../../dependencies.js";
import { useApiRequest } from "../../../../../../../utils/http-client.js";
import { GenericError } from "../../../../../../../components/utils/notification.constants.jsx";

export const MenuBuilderHeader = ({
  categories,
  onCategoryFilterSelected,
  onCreateCategory,
  onEditCategoriesClick,
  className,
  menu,
  showNotification,
  menuEditable,
}) => {
  const [openEditModal, setOpenEditModal] = useState();
  const [openActivityLogModal, setOpenActivityLogModal] = useState();

  const categoryCount = useMemo(
    () => categories?.filter((c) => c.isVisible).length,
    [categories]
  );

  const onEditCategoriesHandler = (categories) => {
    return onEditCategoriesClick(categories).finally(() => setOpenEditModal());
  };

  const { menuBuilderService } = services;
  const { id: menuId } = menu ?? {};

  const {
    response: logs,
    loading,
    sendRequest: getActivityLogs,
  } = useApiRequest(
    () =>
      menuBuilderService.getMenuActivityLogs(menu.id).catch((error) => {
        showNotification(NotificationType.error, GenericError);
        setOpenActivityLogModal();
        return;
      }),
    false
  );

  return (
    <>
      <div className={clsx("menu-builder-header", className)}>
        <div className="left-section">
          <h2>Categories</h2>
          <Badge value={categoryCount ?? <HyphenIcon />} />
          {menuEditable && (
            <Button
              icon={["fa-regular", "pen-to-square"]}
              onClick={() => setOpenEditModal(true)}
              tooltip="Edit Categories"
            />
          )}
        </div>
        <div className="right-section">
          <Button
            icon={["fa-regular", "list-timeline"]}
            onClick={() => setOpenActivityLogModal(true)}
          >
            Activity Log
          </Button>
        </div>
      </div>
      {createPortal(
        <EditMenuCategoriesModal
          open={openEditModal}
          onClose={() => setOpenEditModal()}
          categories={categories}
          onApplyChanges={onEditCategoriesHandler}
          confirmRequired={menu?.organizations?.length > 0}
          async
        />,
        document.body
      )}
      {createPortal(
        <ActivityLogModal
          open={openActivityLogModal}
          onClose={() => setOpenActivityLogModal()}
          logItemId={menuId}
          logs={logs}
          loading={loading}
          getActivityLogs={getActivityLogs}
          async
        ></ActivityLogModal>,
        document.body
      )}
    </>
  );
};
