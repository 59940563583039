import {
  OrganizationsPage,
  ProfilePage,
  SearchPage,
  UsersPage,
  TemplatesPage,
} from "../../views";

export const RootPath = "/app";

export const OrganizationsRoute = {
  path: "/organizations",
  component: OrganizationsPage,
  exact: false,
  icon: "sitemap",
  label: "Organizations",
  labelAbbr: "Orgs",
};

export const UsersRoute = {
  path: "/users",
  component: UsersPage,
  exact: false,
  icon: "users",
  label: "Users",
  labelAbbr: "Users",
};

export const SearchRoute = {
  path: "/search",
  component: SearchPage,
  exact: false,
  icon: "magnifying-glass",
  label: "Search",
  labelAbbr: "Search",
};

export const ProfileRoute = {
  path: "/profile",
  component: ProfilePage,
  exact: true,
};

export const TemplatesRoute = {
  path: "/templates",
  component: TemplatesPage,
  exact: false,
  icon: "list",
  label: "Templates",
  labelAbbr: "Templates",
};

export const Routes = [
  OrganizationsRoute,
  UsersRoute,
  ProfileRoute,
  SearchRoute,
  TemplatesRoute,
];
