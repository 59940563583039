import React, { useEffect } from "react";
import clsx from "clsx";
import {
  Loader,
  Modal,
  ModalHeader,
  ModalBody,
} from "@transfr-inc/dashboard-components";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";
import "./activity-log.modal.scss";
import { ZeroStateMessage } from "./zero-state-message";

export const ActivityLogModal = ({
  open,
  onClose,
  logItemId,
  className,
  logs,
  loading,
  getActivityLogs,
}) => {
  useEffect(() => {
    if (open && logItemId) {
      getActivityLogs();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      modalClassName={clsx("activity-logs-modal", className)}
    >
      <ModalHeader
        icon={["fa-regular", "list-timeline"]}
        title="Activity Log"
      ></ModalHeader>
      <ModalBody>
        {loading && <Loader overlay />}
        {logs && logs.length === 0 && <ZeroStateMessage />}
        {logs && logs.length > 0 && (
          <ul className="logs-list">
            {logs.map((logItem, index) => (
              <li className="log-item" key={`log.${index}`}>
                <span className="log-date">
                  {formatDateDigits(logItem.date, true)}
                </span>
                <span className="log-description">
                  {logItem.message ?? logItem["log_type_name"]}
                </span>
                <span className="log-user">{logItem.user}</span>
              </li>
            ))}
          </ul>
        )}
      </ModalBody>
    </Modal>
  );
};
