import React from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import { DatePickerButton } from "@transfr-inc/dashboard-components/forms";

export function OrgExpirationDateEditor({ className, name = "expiration" }) {
  const { control } = useFormContext();

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>Expiration Date</h2>
      </div>
      <Controller
        control={control}
        name={name}
        rules={{
          required: false,
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <div>
              <DatePickerButton date={value} onDateSelected={onChange} />
            </div>
          );
        }}
      />
    </div>
  );
}
