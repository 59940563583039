import React from "react";
import { NavLink } from "react-router-dom";

import {
  HyphenIcon,
  Link,
  OverflowText,
  PlaceholderProducts,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import {
  BasicCell,
  Column,
  ColumnOptions,
  ColumnType,
} from "@transfr-inc/dashboard-components/data-table";

import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

const ProductsCell = ({ products }) => {
  return (
    products?.length > 0 && (
      <PlaceholderProducts products={products}></PlaceholderProducts>
    )
  );
};

export const templateColumns = (type, path, openMenuOrgsModal = null) => {
  if (type === "Menus") {
    return [
      TemplatesColumnDefinition.NAME(path, type),
      TemplatesColumnDefinition.DESCRIPTION,
      TemplatesColumnDefinition.CREATED,
      TemplatesColumnDefinition.AUTHOR,
      TemplatesColumnDefinition.ORGS(openMenuOrgsModal),
      TemplatesColumnDefinition.CATEGORIES,
      TemplatesColumnDefinition.PRODUCTS,
    ];
  } else if (type === "Categories") {
    return [
      TemplatesColumnDefinition.NAME(path, type),
      TemplatesColumnDefinition.DESCRIPTION,
      TemplatesColumnDefinition.CREATED,
      TemplatesColumnDefinition.AUTHOR,
      TemplatesColumnDefinition.MENUS,
      TemplatesColumnDefinition.MODULES,
      TemplatesColumnDefinition.PRODUCTS,
    ];
  }
  return [];
};

const linkMap = (value) => {
  return value?.toLowerCase();
};

export const TemplatesColumnDefinition = {
  NAME: (templatesPath, breadcrumbLabel) => {
    return new Column(
      "name",
      "Name",
      ColumnType.CUSTOM,
      Object.assign(
        new ColumnOptions({
          sortingEnabled: true,
          customFunction: (_value, row) => {
            return (
              <OverflowText text={_value}>
                <Link
                  routerComponent={NavLink}
                  to={{
                    pathname: `${templatesPath}/${linkMap(breadcrumbLabel)}/${
                      row.id
                    }`,
                    state: {
                      data: row,
                      breadcrumbLabel: "templates",
                    },
                  }}
                >
                  {`${row.name}`}
                </Link>
              </OverflowText>
            );
          },
          sortKeyFields: ["name"],
        }),
      ),
    );
  },
  DESCRIPTION: new Column(
    "description",
    "Description",
    ColumnType.BASIC,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
    }),
  ),
  CREATED: new Column(
    "created",
    "Created",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
      customFunction: (_value, row) => {
        const dateCreated = row.date_created || row.created;
        const formattedDate = dateCreated
          ? formatDateDigits(dateCreated)
          : undefined;
        return <BasicCell value={formattedDate}></BasicCell>;
      },
    }),
  ),
  AUTHOR: new Column(
    "author",
    "Author",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: false,
      customFunction: (_value, row) => {
        const name = _value?.firstName ? (
          `${_value?.firstName} ${_value?.lastName}`
        ) : (
          <HyphenIcon />
        );
        return <div className="templates-cell">{name}</div>;
      },
      sortKeyFields: ["author"],
    }),
  ),
  ORGS: (openMenuOrgsModal) => {
    return new Column(
      "organizations",
      "Orgs",
      ColumnType.CUSTOM,
      Object.assign(
        new ColumnOptions({
          sortingEnabled: true,
          customFunction: (_value, row) => {
            if (row.organizations.length === 0) {
              return (
                <div className="templates-cell templates-cell-navy">
                  <HyphenIcon />
                </div>
              );
            }
            if (row.organizations.length <= 10) {
              const tooltipText = row.organizations
                .map(({ name }) => name)
                .join("<br/>");
              return (
                <TextTooltip className="info" text={tooltipText}>
                  <div className="templates-cell templates-cell-navy templates-cell-underline">
                    {row.organizations.length}
                  </div>
                </TextTooltip>
              );
            }
            return (
              <div
                className="templates-cell templates-cell-navy templates-cell-underline templates-cell-clickable"
                onClick={openMenuOrgsModal && (() => openMenuOrgsModal(row))}
              >
                {row.organizations.length}
              </div>
            );
          },
          sortKeyFunctions: [(row) => row.organizations.length],
        }),
      ),
    );
  },
  CATEGORIES: new Column(
    "categories",
    "Categories",
    ColumnType.CUSTOM,
    Object.assign(
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (_value, row) => {
          if (row.categories.length === 0) {
            return (
              <div className="templates-cell templates-cell-navy">
                <HyphenIcon />
              </div>
            );
          }
          const tooltipText = row.categories
            .map(({ categoryName }) => categoryName)
            .join("<br/>");
          return (
            <TextTooltip className="info" text={tooltipText}>
              <div className="templates-cell templates-cell-navy templates-cell-underline">
                {row.categories.length}
              </div>
            </TextTooltip>
          );
        },
        sortKeyFunctions: [(row) => row.categories.length],
      }),
    ),
  ),
  PRODUCTS: new Column(
    "products",
    "Products",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
      customFunction: (value) => {
        return <ProductsCell products={value}></ProductsCell>;
      },
    }),
  ),
  MENUS: new Column(
    "menus",
    "Menus",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: false,
      customFunction: (_value, row) => {
        if (row.menus.length === 0) {
          return (
            <div className="templates-cell templates-cell-navy">
              <HyphenIcon />
            </div>
          );
        }
        const tooltipText = row.menus.map(({ name }) => name).join("<br/>");
        return (
          <TextTooltip className="info" text={tooltipText}>
            <div className="templates-cell templates-cell-navy templates-cell-underline">
              {row.menus.length}
            </div>
          </TextTooltip>
        );
      },
      sortKeyFunctions: [(row) => row.menus.length],
    }),
  ),
  MODULES: new Column(
    "modules",
    "Modules",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: false,
      customFunction: (_value, row) => {
        if (row.modules.length === 0) {
          return (
            <div className="templates-cell templates-cell-navy">
              <HyphenIcon />
            </div>
          );
        }
        const tooltipText = row.modules
          .map(({ moduleName }) => moduleName)
          .join("<br/>");
        return (
          <TextTooltip className="info" text={tooltipText}>
            <div className="templates-cell templates-cell-navy templates-cell-underline">
              {row.modules.length}
            </div>
          </TextTooltip>
        );
      },
      sortKeyFunctions: [(row) => row.modules.length],
    }),
  ),
};
