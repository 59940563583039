import React from "react";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

export function ProductDropdown({
  className,
  onTypeSelected,
  selectedType,
  products,
  disabled,
}) {
  return (
    <Dropdown
      className={className}
      selected={selectedType}
      onOptionSelected={onTypeSelected}
      disabled={disabled}
    >
      <DropdownButton
        placeholder={"Select a product..."}
        icon={selectedType?.icon}
      >
        {selectedType?.displayName}
      </DropdownButton>
      <DropdownItems>
        {products.map((type) => (
          <DropdownItem key={type.id} value={type}>
            {type.displayName}
          </DropdownItem>
        ))}
      </DropdownItems>
    </Dropdown>
  );
}
