import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSitemap,
  faGauge,
  faUsers,
  faScreenUsers,
  faFileCertificate,
  faBookOpenCover,
  faUserHeadset,
  faTriangleExclamation,
  faCheck,
  faLock,
  faUserCheck,
  faDash,
  faCaretRight,
  faCircleCheck,
  faMedal,
  faCircleInfo,
  faBullseyeArrow,
  faQuestionCircle,
  faEye,
  faEyeSlash,
  faSpinner,
  faLockA,
  faCircleNodes,
  faDownload,
  faHyphen,
  faBooks,
  faUserSecret,
  faPersonRunning,
  faList,
  faClockRotateLeft,
  faLayerPlus,
  faLayerGroup,
  faObjectsColumn,
  faUp,
  faDown,
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faSitemap as farSitemap,
  faGauge as farGauge,
  faUsers as farUsers,
  faScreenUsers as farScreenUsers,
  faFileCertificate as farFileCertificate,
  faBookOpenCover as farBookOpenCover,
  faUserHeadset as farUserHeadset,
  faArrowLeft as farArrowLeft,
  faChevronRight as farChevronRight,
  faXmark as farXmark,
  faPenToSquare as farPenToSquare,
  faTrashCan as farTrashCan,
  faMagnifyingGlass as farMagnifyingGlass,
  faCircleQuestion as farCircleQuestion,
  faCircleInfo as farCircleInfo,
  faCalendarPlus as farCalendarPlus,
  faQuestionCircle as farQuestionCircle,
  faUserPlus as farUserPlus,
  faTimes as farTimes,
  faDoorOpen as farDoorOpen,
  faUsersGear as farUsersGear,
  faUserLock as farUserLock,
  faLockKeyhole as farLockKeyhole,
  faEdit as farEdit,
  faUserGear as farUserGear,
  faUserXmark as farUserXmark,
  faChalkboardUser as farChalkboardUser,
  faHeadSideGoggles as farHeadSideGoggles,
  faPlus as farPlus,
  faEllipsisVertical as farEllipsisVertical,
  faKey as farkey,
  faCircleNodes as farCircleNodes,
  faMask as farMask,
  faBooks as farBooks,
  faCircle as farCircle,
  faSliders as farSliders,
  faCircleXmark as farCircleXmark,
  faPersonRunning as farPersonRunning,
  faList as farList,
  faUserHardHat as farUserHardHat,
  faMedal as farMedal,
  faHand as farHand,
  faEnvelope as farEnvelope,
  faClock as farClock,
  faListTimeline as farListTimeline,
  faClockRotateLeft as farClockRotateLeft,
  faLayerPlus as farLayerPlus,
  faLayerGroup as farLayerGroup,
  faObjectsColumn as farObjectsColumn,
  faTriangleExclamation as farTriangleExclamation,
  faExclamationTriangle as farExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faSitemap as falSitemap,
  faGauge as falGauge,
  faUsers as falUsers,
  faScreenUsers as falScreenUsers,
  faFileCertificate as falFileCertificate,
  faBookOpenCover as falBookOpenCover,
  faUserHeadset as falUserHeadset,
  faArrowLeft as falArrowLeft,
  faChevronRight as falChevronRight,
  faXmark as falXmark,
  faCircleInfo as falCircleInfo,
  faFaceLaughBeam as falFaceLaughBeam,
  faAlien8bit as falAlien8bit,
  faRobot as falRobot,
  faOtter as falOtter,
  faFilter as falFilter,
  faSignsPost as falSignsPosts,
  faUserXmark as falUserXmark,
  faFileExport as falFileExport,
  faCircle as falCircle,
  faSlashForward as falSlashForward,
  faMagnifyingGlass as falMagnifyingGlass,
  faTrashCan as falTrashCan,
  faChalkboardUser as falChalkboardUser,
  faPersonChalkboard as falPersonChalkboard,
  faUserPlus as falUserPlus,
  faUpload as falUpload,
  faDownload as falDownload,
  faPlus as falPlus,
  faHeadSideGoggles as falHeadSideGoggles,
  faUsersGear as falUsersGear,
  faUserLock as falUserLock,
  faUserGear as falUserGear,
  faEdit as falEdit,
  faCircleNodes as falCircleNodes,
  faKey as falKey,
  faPaperPlane as falPaperPlane,
  faBooks as falBooks,
  faQuestionCircle as falQuestionCircle,
  faHand as falHand,
  faMedal as falMedal,
  faPersonRunning as falPersonRunning,
  faArrowsFromLine as falArrowsFromLine,
  faArrowsToLine as falArrowsToLine,
  faCamera as falCamera,
  faUserHardHat as falUserHardHat,
  faList as falList,
  faExclamationTriangle as falExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";

import { faHyphen as fatHyphen } from "@fortawesome/pro-thin-svg-icons";

export function initIcons() {
  // Add solid style icons
  library.add(
    faSitemap,
    faGauge,
    faUsers,
    faScreenUsers,
    faFileCertificate,
    faBookOpenCover,
    faUserHeadset,
    faTriangleExclamation,
    faCheck,
    faLock,
    faUserCheck,
    faDash,
    faCaretRight,
    faCircleCheck,
    faMedal,
    faBullseyeArrow,
    faQuestionCircle,
    faCircleInfo,
    faEye,
    faEyeSlash,
    faSpinner,
    faLockA,
    faCircleNodes,
    faDownload,
    faHyphen,
    faBooks,
    faUserSecret,
    faPersonRunning,
    faList,
    faLayerPlus,
    faLayerGroup,
    faObjectsColumn,
    faUp,
    faDown,
    faClockRotateLeft,
    faExclamationTriangle
  );

  // Add regular style icons
  library.add(
    farSitemap,
    farGauge,
    farUsers,
    farScreenUsers,
    farFileCertificate,
    farBookOpenCover,
    farUserHeadset,
    farArrowLeft,
    farChevronRight,
    farXmark,
    farPenToSquare,
    farTrashCan,
    farMagnifyingGlass,
    farCircleQuestion,
    farCircleInfo,
    farCalendarPlus,
    farQuestionCircle,
    farTimes,
    farDoorOpen,
    farUserPlus,
    farUsersGear,
    farUserLock,
    farLockKeyhole,
    farEdit,
    farUserGear,
    farChalkboardUser,
    farHeadSideGoggles,
    farPlus,
    farEllipsisVertical,
    farkey,
    farCircleNodes,
    farMask,
    farBooks,
    farUserXmark,
    farCircle,
    farSliders,
    farCircleXmark,
    farPersonRunning,
    farList,
    farUserHardHat,
    farMedal,
    farHand,
    farEnvelope,
    farClock,
    farListTimeline,
    farClockRotateLeft,
    farLayerPlus,
    farLayerGroup,
    farObjectsColumn,
    farTriangleExclamation,
    farExclamationTriangle
  );

  // Add light style icons
  library.add(
    falSitemap,
    falGauge,
    falUsers,
    falScreenUsers,
    falFileCertificate,
    falBookOpenCover,
    falUserHeadset,
    falArrowLeft,
    falChevronRight,
    falXmark,
    falCircleInfo,
    falFaceLaughBeam,
    falAlien8bit,
    falRobot,
    falOtter,
    falFilter,
    falXmark,
    falSignsPosts,
    falUserXmark,
    falFileExport,
    falCircle,
    falSlashForward,
    falMagnifyingGlass,
    falTrashCan,
    falChalkboardUser,
    falPersonChalkboard,
    falUserPlus,
    falUpload,
    falDownload,
    falPlus,
    falHeadSideGoggles,
    falUsersGear,
    falUserLock,
    falUserGear,
    falEdit,
    falCircleNodes,
    falKey,
    falPaperPlane,
    falBooks,
    falQuestionCircle,
    falHand,
    falMedal,
    falPersonRunning,
    falArrowsFromLine,
    falArrowsToLine,
    falCamera,
    falList,
    falUserHardHat,
    falExclamationTriangle
  );

  // Add thin style icons
  library.add(fatHyphen);
}
