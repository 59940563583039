import React, { useEffect } from "react";
import { useState } from "react";
import clsx from "clsx";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { CategoryImage } from "./category.image";
import {
  CategoriesImages,
  getCategoryImage,
} from "../../../../../../../components/utils/categories-images.mapper";

import { RequiredItem } from "@transfr-inc/dashboard-components";

export function CategoryImageDropdown({
  className,
  onCategoryImageSelected,
  selectedImage,
}) {
  const [selectedCategoryImage, setSelectedCategoryImage] =
    useState(selectedImage);

  const onOptionSelected = (option) => {
    setSelectedCategoryImage(option);
    onCategoryImageSelected(option);
  };

  useEffect(() => {
    setSelectedCategoryImage(selectedImage);
  }, [selectedImage]);

  const categoryImageOptions = CategoriesImages?.map((i) => {
    return (
      <DropdownItem key={`category-image-${i.bundlePath}`} value={i.bundlePath}>
        <CategoryImage categoryImageConfig={getCategoryImage(i.bundlePath)} />
      </DropdownItem>
    );
  });

  return (
    <div className={clsx("category-dropdown-list", className)}>
      <div className="section-title">
        <RequiredItem text="Image" />
      </div>
      <Dropdown
        selected={selectedCategoryImage}
        onOptionSelected={onOptionSelected}
        className={"category-dropdown"}
      >
        <DropdownButton placeholder={"Select an image..."} required>
          {selectedCategoryImage && selectedCategoryImage !== "" ? (
            <CategoryImage
              categoryImageConfig={getCategoryImage(selectedCategoryImage)}
            ></CategoryImage>
          ) : undefined}
        </DropdownButton>
        <DropdownItems>{categoryImageOptions}</DropdownItems>
      </Dropdown>
    </div>
  );
}
