import { useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { EditWarningModal } from "../components/modals/edit-warning/edit-warning.modal";

export const useEditWarningModal = (props) => {
  const { buttons, ...restProps } = props;
  const [open, setOpen] = useState();

  return [(
    <EditWarningModal
      open={open}
      onCancel={() => setOpen()}
      {...restProps}
    >
      {buttons?.map((button) => {
        const { id, handler, label, ...restButtonProps } = button;
        return (
          <Button
            key={id}
            onClick={() => {
              setOpen();
              if (handler) {
                handler();
              }
            }}
            {...restButtonProps}
          >
            {label}
          </Button>
        )
      })}
    </EditWarningModal>),
    () => setOpen(true),
  ];
}