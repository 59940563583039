import axios from "axios";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";

export function useApiRequest(apiRequest, onMount = true) {
  const [loading, setLoading] = useState(onMount);
  const [response, setResponse] = useState();
  const [error, setError] = useState();

  const sendRequest = async () => {
    setLoading(true);
    return apiRequest()
      .then(setResponse)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    onMount && sendRequest();
  }, []);

  return {
    loading,
    response,
    error,
    sendRequest,
    resetResponse: () => setResponse(),
  };
}

/**
 * Base class for remote HTTP calls
 */
class HttpClient {
  /**
   *
   * @param {string} baseUrl Base URL for your remote calls
   * @param {object} options options for the http client
   * @param {string[]} options.publicPaths Paths that do not require an auth token
   * @param {number} [options.timeout] Default timeout for the client
   * @param {object} dependencies deps for the http client
   * @param {LocalStorageCache} dependencies.tokenCache Cache holding the auth token
   */
  constructor(
    baseUrl,
    { publicPaths = [], timeout = 30000 } = {},
    { tokenCache } = {}
  ) {
    this.abortControllers = new Map();
    this.singleRequestCache = new Map();
    this.http = axios.create({
      baseURL: baseUrl,
      timeout,
    });
    this.tokenCache = tokenCache;

    this.http.interceptors.request.use(
      (config) => {
        const abortController = new AbortController();
        const skipAuth = publicPaths.some((url) => url.includes(config.url));

        if (skipAuth) {
          return config;
        }
        config.headers["Authorization"] = `Bearer ${this.token}`;

        config.signal = abortController.signal;
        this.abortControllers.set(config.url, abortController);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.http.interceptors.response.use(
      (response) => {
        this.abortControllers.delete(response.config?.url);
        return response.data;
      },
      (error) => {
        this.abortControllers.delete(error.config?.url);
        return Promise.reject(error.response ? error.response : error);
      }
    );
  }

  get token() {
    return this.tokenCache.get();
  }

  downloadBlob(config, fileName, fileExtension = "csv") {
    const requestConfig = { ...config, responseType: "blob" };
    return this.http.request(requestConfig).then((response) => {
      const blob = new Blob([response], { type: "text/plain;charset=utf-8" });
      const name = `${fileName ?? Date.now()}.${fileExtension}`;
      saveAs(blob, name);
      return true;
    });
  }

  // TODO: Add the logic to cancel specific request
  cancelRequest() {
    // Cancel all ongoing requests
    for (const [key, controller] of this.abortControllers.entries()) {
      controller.abort();
      this.abortControllers.delete(key);
    }
  }

  cachedRequest(url, method = "get", data) {
    if (!this.singleRequestCache.has(url)) {
      const request = this.http({ url, method, data }).then((response) => {
        this.singleRequestCache.delete(url);
        return response;
      });
      this.singleRequestCache.set(url, request);
    }

    return this.singleRequestCache.get(url);
  }
}

export default HttpClient;
