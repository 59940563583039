import React from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import { RoleSelector } from "@transfr-inc/dashboard-components/forms";

export const RoleSelectorSection = ({
  options,
  required = false,
  onRoleChange,
  className,
}) => {
  const { control } = useFormContext();

  return (
    <div className={clsx("editor-section", className)}>
      <Controller
        control={control}
        name="role"
        rules={{
          required: {
            value: required,
            message: "Required fields are missing.",
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <RoleSelector
              roles={options}
              onRoleSelected={(e) => {
                onRoleChange && onRoleChange(e);
                onChange(e);
              }}
              selectedRole={value}
            />
          );
        }}
      />
    </div>
  );
};
