import React from "react";
import { NavLink } from "react-router-dom";

import {
  Link,
  OverflowText,
  PlaceholderProducts,
} from "@transfr-inc/dashboard-components";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import {
  BasicCell,
  Column,
  ColumnOptions,
  ColumnType,
  TableCellListContent,
} from "@transfr-inc/dashboard-components/data-table";

import {
  MULTI_ORG_CHILD,
  MULTI_ORG_PARENT,
} from "../../../constants/organization-types";

const ProductsCell = ({ products }) => {
  return (
    products?.length > 0 && (
      <PlaceholderProducts products={products}></PlaceholderProducts>
    )
  );
};

export const getOrganizationName = (organization) => {
  if (organization.typeId === MULTI_ORG_CHILD.id) {
    const parentOrg = organization.organizations
      ? organization.organizations[0]
      : {};
    return organization.name.startsWith(parentOrg.name)
      ? organization.name
      : `${parentOrg.name ? `${parentOrg.name} - ` : ""} ${
          organization.name
        }`.trim();
  } else {
    return organization.name;
  }
};

export const OrganizationsColumnDefinition = {
  Name: (orgsPath, parent) =>
    new Column(
      "name",
      "Name",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const parentOrg = parent?.id
            ? parent
            : row.typeId === MULTI_ORG_CHILD.id && row.organizations
            ? row.organizations[0]
            : undefined;
          const breadcrumbLabel = parent ? parent?.name : "All Organizations";
          return (
            <OverflowText text={value}>
              <Link
                routerComponent={NavLink}
                to={{
                  pathname: `${orgsPath}/${row.code}`,
                  state: {
                    code: row.code,
                    type: row.typeId,
                    breadcrumbLabel,
                    parent: parentOrg,
                  },
                }}
              >
                {getOrganizationName(row)}
              </Link>
            </OverflowText>
          );
        },
        sortKeyFunctions: [getOrganizationName],
      }),
    ),
  OrgCode: new Column(
    "code",
    "Client Code",
    ColumnType.BASIC,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
    }),
  ),
  MaxTrainees: new Column(
    "seats",
    "Max Trainees",
    ColumnType.BASIC,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
    }),
  ),
  ChildOrgs: new Column(
    "organizations",
    "Child Orgs",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
      customFunction: (value, row) => {
        const orgsNames =
          row.typeId === MULTI_ORG_PARENT.id
            ? row.organizations?.map((o) => o.name)
            : [];
        const orgsCount = orgsNames?.length ?? 0;
        return (
          <TableCellListContent
            collection={orgsNames}
            collectionCount={orgsCount}
          />
        );
      },
    }),
  ),
  ExpirationDate: new Column(
    "expiration",
    "Expiration Date",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
      customFunction: (value) => {
        const formattedDate = value ? formatDateDigits(value) : undefined;
        return <BasicCell value={formattedDate}></BasicCell>;
      },
    }),
  ),
  Products: new Column(
    "products",
    "Products",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
      customFunction: (value) => {
        return <ProductsCell products={value}></ProductsCell>;
      },
    }),
  ),
};
