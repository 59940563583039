import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import {
  Select,
  OptionItem,
  ToggleButton,
} from "@transfr-inc/dashboard-components/forms";
import { RequiredItem } from "@transfr-inc/dashboard-components";

import "./org-disciplines.editor.scss";

export const getSimulationsOptions = (data) =>
  data?.map((d) => new OptionItem({ id: d.id, text: d.name, data: d }));

const defaultPlaceholder = "Select one or more sims for this organization...";

export function OrgSimsEditor({
  data,
  placeholder,
  className,
  name = "simulations",
  required = false,
}) {
  const fieldTitle = "CE Sims";
  const [options, setOptions] = useState();
  const { control, watch, resetField } = useFormContext();
  const hasAllSims = watch("allSims");

  useEffect(() => {
    setOptions(getSimulationsOptions(data));
  }, [data]);

  useEffect(() => {
    resetField(name);
  }, [hasAllSims]);

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>{required ? <RequiredItem text={fieldTitle} /> : fieldTitle}</h2>
      </div>
      <>
        <Controller
          control={control}
          rules={{
            required: false,
          }}
          name="allSims"
          render={({ field: { value, onChange } }) => {
            return (
              <div className="universal-access-container">
                <span>Select All</span>
                <ToggleButton value={value} onChange={onChange}></ToggleButton>
              </div>
            );
          }}
        />
        <div className="instructions">
          {hasAllSims
            ? "Sims will automatically be updated."
            : "Newly released sims will not be automatically added."}
        </div>
        {!hasAllSims && (
          <Controller
            control={control}
            rules={{
              required,
            }}
            name={name}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  selected={value ?? []}
                  placeholder={placeholder ?? defaultPlaceholder}
                  icon={["fa-light", "fa-objects-column"]}
                  onOptionsChange={onChange}
                  className={"product-select"}
                  options={options}
                  searchEnabled
                ></Select>
              );
            }}
          />
        )}
      </>
    </div>
  );
}
