import React from "react";

import { Tab, TabHeader } from "@transfr-inc/dashboard-components";

import { ParentOrgsSection } from "./parent-orgs.section";
import { ProductsSection } from "./products.section";

import "./organization-detail.tab.scss";

export function OrganizationDetailTab({ parentOrg, organization }) {
  return {
    name: "organizationDetail",
    header: (
      <TabHeader name="organizationDetail" className="org-detail-tab-header">
        <div className="tab-header-text">Detail</div>
      </TabHeader>
    ),
    content: (
      <Tab name="organizationDetail">
        <div className="org-detail-tab">
          {parentOrg && (
            <ParentOrgsSection
              parentOrg={parentOrg}
              organization={organization}
            />
          )}
          {organization && <ProductsSection organization={organization} />}
        </div>
      </Tab>
    ),
  };
}
