export function catchEditUserError(response, isTrainee = false) {
  let errorMessage = "Something went wrong. Please try again.";
  if (response.status == 409) {
    errorMessage = isTrainee
      ? "Email or username is not unique."
      : "Email is not unique.";
  } else if (response.error) {
    errorMessage = response.error;
  }

  return errorMessage;
}

export function catchCreateUserError(response) {
  let errorMessage = "Unable to create user. Please try again.";
  if (response.status == 409) {
    errorMessage = "Record already exists for this email.";
  } else if (response.error) {
    errorMessage = response.error;
  }

  return errorMessage;
}
