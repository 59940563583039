import HttpClient from "../utils/http-client";

export class DisciplinesService extends HttpClient {
  async getAllDiscplines() {
    return await this.http.get(`/client-services/disciplines`);
  }

  async getOrganizationDisciplines(code) {
    return await this.http.get(
      `/client-services/organizations/${code}/disciplines`
    );
  }
}
