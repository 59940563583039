import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import ContainerRoute from "../../components/routing/container-route";
import { TemplateList } from "./list";
import { MenuDetail } from "./menus/detail";
import { CategoryDetail } from "./categories";

export default ({ responsiveStyles = {} }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={path}
        component={TemplateList}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
      <ContainerRoute
        exact={true}
        path={`${path}/menus/:id`}
        component={MenuDetail}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
      <ContainerRoute
        exact={true}
        path={`${path}/categories/:id`}
        component={CategoryDetail}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
    </Switch>
  );
};
