import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
  NotificationType,
  RequiredFieldsText,
} from "@transfr-inc/dashboard-components";

import { Button, RoleSelector } from "@transfr-inc/dashboard-components/forms";

import { UserInfo } from "../../forms/user-info";

import { getRole } from "../../../constants/role";
import { getRoleTransformations } from "../../../constants/role-transformation";
import {
  getRoleTransformations as getClientRoleTransformations,
  getRole as getClientRole,
  Role as ClientRole,
} from "@transfr-inc/dashboard-components/utils";

import "./edit-user.modal.scss";

/**
 * Modal which allows editing of user's role, name, and email   address. Only specific role transformations are involved.
 */
export default ({
  onClose,
  open,
  onUpdateUser,
  className,
  user,
  clientUser = false,
}) => {
  const [defaultValues, setDefaultValues] = useState(user);
  const { register, handleSubmit, control, formState, trigger, reset } =
    useForm({
      criteriaMode: "all",
      mode: "onChange",
      defaultValues,
    });
  const { isValid } = formState;
  const originalDataRef = useRef(user);

  const [selectedRole, setSelectedRole] = useState();
  const [stateNotification, setStateNotification] = useState();
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState();
  const [isTraineeUser, setIsTraineeUser] = useState();

  const submitForm = async (values) => {
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: selectedRole.id,
      userId: originalDataRef.current.userId,
    };

    if (values.email) user["email"] = values.email;
    if (values.username) user["username"] = values.username;

    const updatedUser = await onUpdateUser(user);
    setLoading(false);

    if (updatedUser.error) {
      setStateNotification({
        type: NotificationType.error,
        message: updatedUser.error,
      });
      return false;
    }
    setStateNotification();
    setDefaultValues(updatedUser);
    onClose();

    return true;
  };

  const editUser = () => {
    setLoading(true);
    handleSubmit(submitForm)();
  };

  const onRoleSelected = (role) => {
    setSelectedRole(role);
  };

  const initModal = () => {
    user.roleId == ClientRole.STUDENT.id
      ? setIsTraineeUser(true)
      : setIsTraineeUser();
    const initialRole = clientUser
      ? getClientRole(user.roleId)
      : getRole(user.roleId);
    const roleTransformations = clientUser
      ? getClientRoleTransformations(initialRole)
      : getRoleTransformations(initialRole);

    originalDataRef.current = user;
    reset(user);
    setDefaultValues(user);
    setSelectedRole(initialRole);
    setRoleOptions([initialRole, ...(roleTransformations ?? [])]);
  };

  useEffect(() => {
    if (user) {
      initModal();
    }
  }, [user]);

  useEffect(() => {
    open && trigger();
  }, [open]);

  return (
    <div className={clsx("edit-user-modal", className)}>
      <Modal
        modalClassName={"edit-user-modal"}
        open={open}
        onClose={onClose}
        uniqueName="edit-user-modal"
      >
        {loading && <Loader overlay />}
        <ModalHeader
          className="blue-icon small"
          icon={["fa-light", "edit"]}
          title="Edit User Details"
          uniqueName="edit-user-modal"
        ></ModalHeader>

        <ModalBody className={"create-trainee modal-user-details"}>
          <RequiredFieldsText />
          <RoleSelector
            onRoleSelected={onRoleSelected}
            roles={roleOptions}
            selectedRole={selectedRole}
          />
          <UserInfo
            register={register}
            control={control}
            defaultValues={defaultValues}
            formState={formState}
            responseNotification={stateNotification}
            onNotificationChange={setStateNotification}
            usernameEnabled={isTraineeUser}
            emailRequired={!isTraineeUser}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={editUser} disabled={!isValid}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
