import React from "react";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

export const OrganizationDropdown = ({
  data,
  className,
  onOrgSelected,
  selectedOrg,
}) => {
  return (
    <Dropdown
      className={className}
      selected={selectedOrg}
      onOptionSelected={onOrgSelected}
    >
      <DropdownButton
        placeholder={"Select an organization..."}
        icon={selectedOrg?.icon}
      >
        {selectedOrg?.name}
      </DropdownButton>
      <DropdownItems>
        {data?.map((organization) => (
          <DropdownItem key={organization.code} value={organization}>
            {organization.name}
          </DropdownItem>
        ))}
      </DropdownItems>
    </Dropdown>
  );
};
