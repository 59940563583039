import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import { RequiredItem } from "@transfr-inc/dashboard-components";
import { ClassroomDropdown } from "../classroom.dropdown";

import { services } from "../../../dependencies";
import { useApiRequest } from "../../../utils/http-client";

export const ClassroomSelectorSection = ({
  organization,
  required = false,
  onClassroomChange,
  clearEnabled = false,
  className,
}) => {
  const { control } = useFormContext();
  const { classroomService } = services;

  const { response: classroomOptions, sendRequest: getClassrooms } =
    useApiRequest(
      () => classroomService.getClassrooms(organization.code),
      false
    );

  useEffect(() => {
    if (organization) {
      getClassrooms();
    }
  }, [organization]);

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>{required ? <RequiredItem text="Classroom" /> : "Classroom"}</h2>
      </div>
      <Controller
        control={control}
        name="classroom"
        rules={{
          required: {
            value: required,
            message: "Required fields are missing.",
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <ClassroomDropdown
              data={classroomOptions}
              onClassroomSelected={(e) => {
                onClassroomChange && onClassroomChange(e);
                onChange(e);
              }}
              selectedClassroom={value}
              clearEnabled={clearEnabled}
            ></ClassroomDropdown>
          );
        }}
      />
    </div>
  );
};
