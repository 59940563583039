import React, { useContext } from "react";

import { Tab, TabBadge, TabHeader } from "@transfr-inc/dashboard-components";

import { AdminUsersTab } from "./admin-users.tab";
import { InstructorUsersTab } from "./instructor-users.tab";
import { TraineeUsersTab } from "./trainee-users.tab";

import { Role } from "@transfr-inc/dashboard-components/utils";
import { SessionContext } from "../../../../context";

export const UsersTabs = ({
  data,
  classrooms = [],
  isMultiOrg,
  responsiveStyles,
  actionMenuOptions = {},
  bottomBarOptions,
  dataFiltered,
  isRowActive,
  searchEnabled,
}) => {
  const { features } = useContext(SessionContext);
  const {
    canEditClientUser,
    canDeleteClientUser,
    canImpersonateClientUser,
    canImpersonateTrekUser,
  } = features;
  const {
    delete: deleteAction,
    editUser: editAction,
    impersonate: impersonateAction,
    impersonateTrek: impersonateTrekAction,
    sendInvite: sendAction,
  } = actionMenuOptions;
  const superAdminData = data?.get(Role.MULTI_ORG_ADMIN.id);
  const reporterAdminData = data?.get(Role.MULTI_ORG_VIEWER.id);
  const orgAdminData = data?.get(Role.ORG_ADMIN.id);
  const instructorData = data?.get(Role.INSTRUCTOR.id);
  const traineeData = data?.get(Role.STUDENT.id);

  const superAdminUsersTab = {
    name: "superAdmins",
    header: (
      <TabHeader name="superAdmins" className="super-admins-tab-header">
        <div className="tab-header-text">Super Admins</div>
        <TabBadge count={superAdminData?.length ?? "..."}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="superAdmins">
        {superAdminData && (
          <AdminUsersTab
            data={superAdminData}
            role={Role.MULTI_ORG_ADMIN}
            responsiveStyles={responsiveStyles}
            className="admin-users-tab-data-table"
            bottomBarOptions={canDeleteClientUser && bottomBarOptions}
            actionMenuOptions={[
              canDeleteClientUser && deleteAction,
              canImpersonateClientUser && impersonateAction,
              canEditClientUser && editAction,
              canEditClientUser && sendAction,
            ]}
            isRowActive={isRowActive}
            dataFiltered={dataFiltered}
          ></AdminUsersTab>
        )}
      </Tab>
    ),
  };

  const reporterUsersTab = {
    name: "reporters",
    header: (
      <TabHeader name="reporters" className="reporters-tab-header">
        <div className="tab-header-text">Reporters</div>
        <TabBadge count={reporterAdminData?.length ?? "..."}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="reporters">
        {reporterAdminData && (
          <AdminUsersTab
            data={reporterAdminData}
            role={Role.MULTI_ORG_VIEWER}
            responsiveStyles={responsiveStyles}
            className="admin-users-tab-data-table"
            bottomBarOptions={canDeleteClientUser && bottomBarOptions}
            actionMenuOptions={[
              canDeleteClientUser && deleteAction,
              canImpersonateClientUser && impersonateAction,
              canEditClientUser && editAction,
              canEditClientUser && sendAction,
            ]}
            isRowActive={isRowActive}
            dataFiltered={dataFiltered}
          ></AdminUsersTab>
        )}
      </Tab>
    ),
  };

  const orgAdminUsersTab = {
    name: "orgAdmins",
    header: (
      <TabHeader name="orgAdmins" className="org-admins-tab-header">
        <div className="tab-header-text">Admins</div>
        <TabBadge count={orgAdminData?.length ?? "..."}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="orgAdmins">
        {orgAdminData && (
          <AdminUsersTab
            data={orgAdminData}
            role={Role.ORG_ADMIN}
            responsiveStyles={responsiveStyles}
            className="admin-users-tab-data-table"
            bottomBarOptions={canDeleteClientUser && bottomBarOptions}
            actionMenuOptions={[
              canDeleteClientUser && deleteAction,
              canImpersonateClientUser && impersonateAction,
              canEditClientUser && editAction,
              canEditClientUser && sendAction,
            ]}
            isRowActive={isRowActive}
            dataFiltered={dataFiltered}
          ></AdminUsersTab>
        )}
      </Tab>
    ),
  };

  const instructorUsersTab = {
    name: "instructors",
    header: (
      <TabHeader name="instructors" className="instructors-tab-header">
        <div className="tab-header-text">Instructors</div>
        <TabBadge count={instructorData?.length ?? "..."}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="instructors">
        {instructorData && (
          <InstructorUsersTab
            data={instructorData}
            responsiveStyles={responsiveStyles}
            className="instructor-users-tab-data-table"
            bottomBarOptions={canDeleteClientUser && bottomBarOptions}
            actionMenuOptions={[
              canDeleteClientUser && deleteAction,
              canImpersonateClientUser && impersonateAction,
              canEditClientUser && editAction,
              canEditClientUser && sendAction,
            ]}
            isRowActive={isRowActive}
            dataFiltered={dataFiltered}
          ></InstructorUsersTab>
        )}
      </Tab>
    ),
  };

  const traineeUsersTab = {
    name: "trainees",
    header: (
      <TabHeader name="trainees" className="trainees-tab-header">
        <div className="tab-header-text">Trainees</div>
        <TabBadge count={traineeData?.length ?? "..."}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="trainees">
        {traineeData && (
          <TraineeUsersTab
            data={traineeData}
            classrooms={classrooms}
            responsiveStyles={responsiveStyles}
            className="trainee-users-tab-data-table"
            bottomBarOptions={canDeleteClientUser && bottomBarOptions}
            actionMenuOptions={[
              canDeleteClientUser && deleteAction,
              canImpersonateTrekUser && impersonateTrekAction,
              canEditClientUser && editAction,
            ]}
            isRowActive={isRowActive}
            dataFiltered={dataFiltered}
            searchEnabled={searchEnabled}
          ></TraineeUsersTab>
        )}
      </Tab>
    ),
  };

  const getUserTabs = () => {
    const tabs = [];

    isMultiOrg && tabs.push(superAdminUsersTab, reporterUsersTab);
    tabs.push(orgAdminUsersTab, instructorUsersTab, traineeUsersTab);
    return tabs;
  };

  return getUserTabs();
};
