import React from "react";
import clsx from "clsx";

import { OrganizationBox } from "./organization.box";

import "./parent-orgs.section.scss";

export const ParentOrgsSection = ({ parentOrg, organization, className }) => {
  return (
    <div className={clsx("parent-orgs-section", className)}>
      <h2>Organization&apos;s Parent</h2>
      <div className="box-section">
        <OrganizationBox
          organization={parentOrg}
          breadcrumbLabel={organization?.name}
        />
      </div>
    </div>
  );
};
