import React, { useEffect, useState, useContext, useMemo } from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import {
  Loader,
  Notification,
  NotificationType,
  NotificationStack,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RequiredItem,
} from "@transfr-inc/dashboard-components";
import {
  Button,
  Input,
  Textarea,
  Select,
} from "@transfr-inc/dashboard-components/forms";
import { services } from "../../../../../../../dependencies";
import "./edit-menu-details.modal.scss";
import { MenuBuilderContext } from "../../../../../../../context";
import { WarningMessages } from "../../../../../../../components/modals/edit-warning/edit-warning.modal";
import { useEditWarningModal } from "../../../../../../../hooks";
import { EditMenuSuccess } from "../../../../../../../components/utils/notification.constants";
import { getTsCategoriesOptions } from "../../../../../../../utils/menu-builder";

export function EditMenuDetailsModal({
  menu,
  open,
  onCancel,
  onEditMenuDetailsHandler,
  showNotification,
}) {
  const { categories } = useContext(MenuBuilderContext);
  const { menuBuilderService } = services;

  const [loading, setLoading] = useState();
  const [notifications, setNotifications] = useState();
  const [responseNotification, setResponseNotification] = useState();

  const categoryOptions = useMemo(() => {
    if (categories?.length) {
      return getTsCategoriesOptions(categories, true);
    }
    return [];
  }, [categories]);

  const menuCategories = useMemo(() => {
    if (categories && menu.categories?.length) {
      const menuCategoryIds = new Set(
        menu.categories.map((mc) => mc.categoryId)
      );
      return categories.filter((cat) => menuCategoryIds.has(cat.id));
    }
    return [];
  }, [categories, menu.categories]);

  const methods = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues: {
      ...menu,
      tsCategories: getTsCategoriesOptions(menuCategories, true),
    },
  });

  const { handleSubmit, reset, formState, control } = methods;

  const { errors, isValid } = formState;

  const showError = (...errors) => {
    setNotifications(
      errors.map((e) => ({
        type: NotificationType.error,
        message: e,
      }))
    );
  };

  const checkErrors = () => {
    if (Object.keys(errors).length > 0) {
      const messages = new Set(Object.values(errors).map((e) => e.message));
      showError(...messages.values());
    } else {
      setNotifications();
    }
  };

  useEffect(() => {
    if (errors) {
      checkErrors();
    }
  }, [formState]);

  useEffect(() => {
    !open && reset();
  }, [open]);

  const onCloseHandler = () => {
    onCancel && onCancel();
    setNotifications();
    reset(menu, { keepDefaultValues: true });
  };

  const submitForm = async (values) => {
    try {
      const formattedData = {
        name: values.name,
        description: values.description,
        categories: (values.tsCategories || []).map((cat, i) => ({
          categoryId: cat.id,
          isVisible: true,
          orderNumber: i,
        })),
      };

      const updatedMenuData = await menuBuilderService.editMenuMetaDetails(
        menu.id,
        formattedData
      );
      await onEditMenuDetailsHandler(updatedMenuData);
      if (showNotification) {
        showNotification(
          NotificationType.success,
          EditMenuSuccess(updatedMenuData),
          "circle-check"
        );
      } else {
        setNotifications([
          {
            type: NotificationType.success,
            message: "Menu edited successfully",
          },
        ]);
      }
      onCloseHandler();
    } catch (error) {
      setResponseNotification({
        type: NotificationType.error,
        message: !error?.data ? "Something went wrong" : error.data.detail,
      });
    } finally {
      setLoading();
    }

    return true;
  };

  const editMenuDetailsHandler = () => {
    setLoading(true);
    handleSubmit(submitForm)();
  };

  const [warningEditModal, openWarningEditModal] = useEditWarningModal({
    title: WarningMessages.menu.title,
    message: WarningMessages.menu.edit,
    buttons: [
      {
        id: "no",
        label: "No, Cancel",
        handler: onCancel,
      },
      {
        id: "yes",
        label: "Yes, I'm Sure",
        handler: editMenuDetailsHandler,
        primary: true,
      },
    ],
  });

  const onEditMenuDetails = () => {
    if (menu?.organizations?.length > 0) {
      openWarningEditModal();
    } else {
      editMenuDetailsHandler();
    }
  };

  return (
    <>
      <Modal
        modalClassName={"edit-menu-modal"}
        open={open}
        onClose={onCloseHandler}
        preventClose={loading}
      >
        {loading && <Loader overlay></Loader>}
        <ModalHeader
          className="blue-icon"
          icon={["fa-regular", "pen-to-square"]}
          title="Edit Menu Details"
        ></ModalHeader>
        <ModalBody>
          <div className="validation-errors">
            {notifications?.length && (
              <NotificationStack
                notifications={notifications}
              ></NotificationStack>
            )}
            {responseNotification?.type == NotificationType.error && (
              <div className="error">
                <Notification
                  {...responseNotification}
                  closable
                  onClose={() => setResponseNotification()}
                >
                  {responseNotification.message}
                </Notification>
              </div>
            )}
          </div>
          <div className="text-with-info">
            <label>
              Required Fields<sup>*</sup>
            </label>
          </div>
          <FormProvider {...methods}>
            <form className="edit-menu-form">
              <Controller
                control={control}
                name="name"
                defaultValue={menu?.name}
                rules={{
                  required: {
                    value: true,
                    message: "Required fields are missing.",
                  },
                }}
                render={({ field: { onBlur, onChange, value, ref } }) => {
                  return (
                    <Input
                      label={<RequiredItem text="Name" />}
                      type="text"
                      name="name"
                      errors={errors?.name}
                      ref={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      required
                    />
                  );
                }}
              />
              <div>
                <div className="section-title">
                  <h2>{"TS Categories *"}</h2>
                </div>
                <Controller
                  control={control}
                  name="tsCategories"
                  rules={{ required: true, minLength: 1 }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        selected={value ?? []}
                        placeholder={
                          "Select one or more categories for this menu..."
                        }
                        icon={["fa-light", "fa-objects-column"]}
                        onOptionsChange={onChange}
                        options={categoryOptions}
                        searchEnabled
                        displayTooltip
                      ></Select>
                    );
                  }}
                />
              </div>
              <Controller
                control={control}
                defaultValue={menu?.description}
                name="description"
                rules={{
                  required: {
                    value: true,
                    message: "Required fields are missing.",
                  },
                }}
                render={({ field: { onBlur, onChange, value, ref } }) => {
                  return (
                    <Textarea
                      label={<RequiredItem text="Description" />}
                      name="description"
                      errors={errors?.name}
                      ref={ref}
                      onBlur={onBlur}
                      onInputChange={onChange}
                      value={value}
                      required
                    />
                  );
                }}
              />
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseHandler}>Cancel</Button>
          <Button primary onClick={onEditMenuDetails} disabled={!isValid}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
      {warningEditModal}
    </>
  );
}
