import React from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import { Select, OptionItem } from "@transfr-inc/dashboard-components/forms";
import { RequiredItem } from "@transfr-inc/dashboard-components";
import { getProducts, Product } from "@transfr-inc/dashboard-components/utils";
import { toggles } from "../../../dependencies";

export const productOptions = () => {
  const { ceEnabled } = toggles;
  return getProducts().map(
    (product) =>
      (product.id === Product.TS || ceEnabled) && // temporary while TF is the only product
      new OptionItem({ id: product.id, text: product.displayName, data: product })
  );
};

export const getProductsOptions = (products) =>
  products
    ?.map((p) => productOptions().find((po) => po.id === p))
    .filter((p) => p);

const defaultPlaceholder =
  "Select one or more products for this organization...";

export function OrgProductsEditor({
  placeholder,
  className,
  name = "products",
}) {
  const { control } = useFormContext();

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>
          <RequiredItem text="Products" />
        </h2>
      </div>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        name={name}
        render={({ field: { value, onChange } }) => {
          return (
            <Select
              selected={value ?? []}
              placeholder={placeholder ?? defaultPlaceholder}
              icon={["fa-light", "fa-objects-column"]}
              onOptionsChange={onChange}
              className={"product-select"}
              options={productOptions}
            ></Select>
          );
        }}
      />
    </div>
  );
}
