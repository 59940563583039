import React, { useContext, useEffect, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";
import {
  Toolbar,
  BarOptionDefinition,
  MenuOptionSpec,
  NotificationType,
  Loader,
} from "@transfr-inc/dashboard-components";
import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";
import { Notification } from "@transfr-inc/dashboard-components";

import { UsersTabs } from "./components/users.tabs";
import { UsersContext } from "../../../context/users.context";
import { AllRoles } from "../../../constants/role";
import { NewUserModal } from "../new-user/new-user-modal";
import { EditUserModal, DeleteUserModal } from "../../../components/modals";

import { SessionContext } from "../../../context";
import { services } from "../../../dependencies";
import { catchEditUserError } from "../../../services";

import "./index.scss";

const getRoleUsersMap = (data) => {
  const roleMap = new Map();

  for (const user of data) {
    if (!roleMap.has(user.roleId)) {
      roleMap.set(user.roleId, []);
    }

    const userArray = roleMap.get(user.roleId);
    userArray.push(user);
  }

  for (const role of AllRoles) {
    if (!roleMap.has(role.id)) {
      roleMap.set(role.id, []);
    }
  }
  return roleMap;
};

export const UserList = ({ responsiveStyles }) => {
  const [usersMap, setUsersMap] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [notification, setNotification] = useState();
  const [userSelected, setUserSelected] = useState();
  const [rowsSelected, setRowsSelected] = useState([]);
  const [loading, setLoading] = useState();

  const { users, usersCount, updateUsers } = useContext(UsersContext);
  const { features } = useContext(SessionContext);
  const { canCreateUser, canDeleteUser } = features;

  // Modals
  const [openEditModal, setOpenEditModal] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState();

  const { userService } = services;

  // Edit User
  const onEditDetails = async (keys, data) => {
    const user = await userService.getUserDetails(data.userId);
    setUserSelected(user);
    setOpenEditModal(true);
  };

  const onCloseEditDetails = () => {
    setOpenEditModal();
    setUserSelected();
  };

  const onUpdateUser = async (user) => {
    try {
      const updatedUser = await userService.updateUserMetadata(user);
      showNotification({
        type: NotificationType.success,
        message: `User ${updatedUser.firstName} ${updatedUser.lastName} has been updated successfully.`,
        icon: ["fa-solid", "circle-check"],
      });

      onCloseEditDetails();
      updateUsersTable();
      return updatedUser;
    } catch (error) {
      console.error(error);
      user.error = catchEditUserError(error);
      return user;
    }
  };

  // Delete Users
  const onDeleteUsers = (data) => {
    setRowsSelected(data);
    setOpenDeleteModal(true);
  };

  const onCloseDeleteUsers = () => {
    setOpenDeleteModal();
    setUserSelected();
  };

  const onConfirmDeleteUsers = async (users) => {
    try {
      const userIds = users.map((x) => x["userId"]);
      await userService.deleteUsers({
        userIds: userIds,
        roleId: users[0]["roleId"],
      });
      onCloseDeleteUsers();
      updateUsersTable();
      showNotification({
        type: NotificationType.success,
        message: (
          <div>
            <b>{users.length} </b>{" "}
            {userIds.length > 1 ? "users have" : "user has"} been deleted
          </div>
        ),
      });
    } catch {
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong",
      });
    }
  };

  const updateUsersTable = async () => {
    setLoading(true);
    try {
      await updateUsers();
      setLoading();
    } catch {
      setLoading();
    }
  };

  const actionMenuOptions = {
    edit: new MenuOptionSpec(
      "Edit Details",
      ["fa-light", "fa-edit"],
      onEditDetails
    ),
  };

  const bottomBarOptions = canDeleteUser && [
    new BarOptionDefinition(onDeleteUsers, {
      text: "Delete",
      icon: ["fa-light", "fa-trash-can"],
      destructive: true,
    }),
  ];

  const toolbar = (
    <Toolbar>
      <Button icon={["fa-regular", "circle-question"]} title={"Help"}></Button>
      {canCreateUser && (
        <Button
          icon={["fa-regular", "user-plus"]}
          primary
          title={"Create User"}
          onClick={() => setOpenCreateModal(true)}
        ></Button>
      )}
    </Toolbar>
  );

  const showNotification = async (notification) => {
    setNotification(notification);
    setTimeout(() => {
      setNotification();
    }, 10000);
  };

  const onUserCreated = async (notification) => {
    showNotification(notification);
    updateUsersTable();
    // notification && getUsers();
  };

  useEffect(() => {
    users && setUsersMap(getRoleUsersMap(users));
  }, [users]);

  const pageHeader = (
    <PageHeader title={"Users"} badge={usersCount}>
      {toolbar}
    </PageHeader>
  );

  return (
    <PageLayout
      className="user-list-page"
      responsiveStyles={responsiveStyles}
      header={pageHeader}
    >
      {loading && <Loader overlay></Loader>}
      {usersMap && (
        <UsersTabs
          data={usersMap}
          responsiveStyles={responsiveStyles}
          bottomBarOptions={bottomBarOptions}
          actionMenuOptions={actionMenuOptions}
        ></UsersTabs>
      )}
      <NewUserModal
        show={openCreateModal}
        setShow={() => setOpenCreateModal()}
        onUserCreated={onUserCreated}
      />
      <div className="notification-container success-notification">
        {notification && (
          <Notification
            type={notification.type}
            icon={notification.icon}
            onClose={() => {
              setNotification();
            }}
            closable
            animated
          >
            {notification.message}
          </Notification>
        )}
      </div>
      <EditUserModal
        open={openEditModal}
        onClose={onCloseEditDetails}
        user={userSelected}
        onUpdateUser={onUpdateUser}
      ></EditUserModal>
      <DeleteUserModal
        open={openDeleteModal}
        users={rowsSelected}
        onCancel={onCloseDeleteUsers}
        onConfirmDelete={onConfirmDeleteUsers}
        async
      ></DeleteUserModal>
    </PageLayout>
  );
};
