import React, { useState, useEffect, useMemo, useContext } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { useHistory } from "react-router";

import {
  Toolbar,
  Loader,
  MenuOptionSpec,
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { TemplatesTabs } from "./components/templates.tabs";
import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";
import NewTemplateModal from "../new-template/new-template-modal";
import { DeleteMenuModal } from "../menus/detail/components/menu-builder/modals";
import { EditMenuDetailsModal } from "../menus/detail/components/modals/edit-menu-details/edit-menu-details.modal";
import { WarningMessages } from "../../../components/modals/edit-warning/edit-warning.modal";
import { DeleteCategoryModal } from "../menus/detail/components/menu-builder/modals/delete-category/delete-category.modal";
import { EditCategoryModal } from "../menus/detail/components/menu-builder/modals/edit-category/edit-category.modal";
import {
  DeleteCategorySuccess,
  DeleteMenuSuccess,
  GenericError,
} from "../../../components/utils/notification.constants";
import { useEditWarningModal } from "../../../hooks";
import { MenuBuilderContext } from "../../../context";
import { services } from "../../../dependencies";

import "./index.scss";

export const TemplateList = ({ responsiveStyles }) => {
  const history = useHistory();
  const { getAllCategories, getAllModules } = useContext(MenuBuilderContext);

  const { menuBuilderService } = services;
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState();
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [deleteMenuModalOpen, setDeleteMenuModalOpen] = useState();
  const [editMenuModalOpen, setEditMenuModalOpen] = useState();
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState();
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState();
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [notifications, setNotifications] = useState([]);

  const [deleteMenuWarningModal, openDeleteMenuWarningModal] =
    useEditWarningModal({
      title: WarningMessages.menu.title,
      message: WarningMessages.menu.delete,
      icon: ["fa-solid", "fa-clock-rotate-left"],
      iconColor: "danger-red",
    });
  const [deleteCategoryWarningModal, openDeleteCategoryWarningModal] =
    useEditWarningModal({
      title: WarningMessages.category.title,
      message: WarningMessages.category.delete,
      icon: ["fa-solid", "fa-clock-rotate-left"],
      iconColor: "danger-red",
    });

  // Modal
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);

  const fetchMenuData = async () => {
    setLoading(true);
    const menuData = await menuBuilderService.getAllMenus();
    const mappedMenus = menuData.map((data) => ({
      ...data,
      author: {
        firstName: data.last_edited_by.first_name,
        lastName: data.last_edited_by.last_name,
      },
    }));
    setMenus(mappedMenus);
    setLoading();

    await fetchCategoryData();
  };

  const fetchCategoryData = async () => {
    const categoryData = await menuBuilderService.getTemplateCategories();
    setCategories(categoryData);
  };

  useEffect(() => {
    fetchMenuData();
  }, []);

  const onOpenDeleteMenuModal = (_, data) => {
    setSelectedMenu(data);
    if (data.organizations.length > 0) {
      openDeleteMenuWarningModal();
    } else {
      setDeleteMenuModalOpen(true);
    }
  };

  const onDeleteMenu = async (data) => {
    setLoading(true);
    try {
      await menuBuilderService.deleteTemplateCategory(data.id);
      setMenus(menus.filter(({ id }) => id !== data.id));
      showNotification(
        NotificationType.success,
        DeleteMenuSuccess(data),
        "circle-check"
      );
      setLoading();
    } catch {
      setLoading();
      showNotification(NotificationType.error, GenericError);
    }
  };

  const onOpenEditMenuModal = (_, data) => {
    setSelectedMenu(data);
    setEditMenuModalOpen(true);
  };

  const onEditMenu = (updatedMenuData) => {
    setMenus(
      menus.map((menu) => {
        if (menu.id !== updatedMenuData.id) {
          return menu;
        }
        return {
          ...menu,
          ...updatedMenuData,
        };
      })
    );
  };

  const onOpenDeleteCategoryModal = (_, data) => {
    setSelectedCategory(data);
    if (data.menus.length > 0) {
      openDeleteCategoryWarningModal();
    } else {
      setDeleteCategoryModalOpen(true);
    }
  };

  const onDeleteCategory = async (data) => {
    setLoading(true);
    try {
      await menuBuilderService.deleteCategory(data.id);
      setCategories(categories.filter(({ id }) => id !== data.id));
      showNotification(
        NotificationType.success,
        DeleteCategorySuccess(data),
        "circle-check"
      );
      setLoading();
    } catch {
      setLoading();
      showNotification(NotificationType.error, GenericError);
    }
  };

  const onOpenEditCategoryModal = (_, data) => {
    setSelectedCategory(data);
    setEditCategoryModalOpen(true);
  };

  const onEditCategory = (updatedCategoryData) => {
    setCategories(
      categories.map((category) => {
        if (category.id !== updatedCategoryData.id) {
          return category;
        }
        return {
          ...category,
          ...updatedCategoryData,
        };
      })
    );
  };

  const actionMenuOptions = {
    menu: {
      delete: new MenuOptionSpec(
        "Delete",
        ["fa-regular", "fa-trash-can"],
        onOpenDeleteMenuModal
      ),
      edit: new MenuOptionSpec(
        "Edit Menu Details",
        ["fa-regular", "fa-edit"],
        onOpenEditMenuModal
      ),
    },
    category: {
      delete: new MenuOptionSpec(
        "Delete",
        ["fa-regular", "fa-trash-can"],
        onOpenDeleteCategoryModal
      ),
      edit: new MenuOptionSpec(
        "Edit Category Details",
        ["fa-regular", "fa-edit"],
        onOpenEditCategoryModal
      ),
    },
  };

  const templateData = useMemo(() => {
    return {
      menuData: menus,
      categoryData: categories,
    };
  }, [categories, menus]);

  const toolbar = (
    <Toolbar>
      <Button
        icon={["fa-regular", "fa-plus"]}
        primary
        title={"Create Template"}
        onClick={() => setOpenCreateTemplate(true)}
      ></Button>
    </Toolbar>
  );

  const pageHeader = (
    <PageHeader title={"Templates"} badge={15}>
      {toolbar}
    </PageHeader>
  );

  const onTemplateCreated = async (template, isMenu) => {
    getAllCategories();
    getAllModules();
    goDetailPage(template, isMenu);
  };

  const goDetailPage = (template, isMenu) => {
    setOpenCreateTemplate();

    if (isMenu) {
      const { menuId } = template;
      history.push({
        pathname: `templates/menus/${menuId}`,
        state: {
          menuId,
          data: template,
          breadcrumbLabel: "templates",
        },
      });
    } else {
      const { categoryId } = template;
      history.push({
        pathname: `templates/categories/${categoryId}`,
        state: {
          data: template,
          breadcrumbLabel: "templates",
        },
      });
    }
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onCloseNotification = (indexToRemove) => {
    setNotifications(
      notifications.filter((n, index) => index !== indexToRemove)
    );
  };

  return (
    <PageLayout
      className="templates-list-page"
      responsiveStyles={responsiveStyles}
      header={pageHeader}
    >
      {loading && <Loader overlay></Loader>}
      {templateData && (
        <TemplatesTabs
          data={templateData}
          responsiveStyles={responsiveStyles}
          actionMenuOptions={actionMenuOptions}
          loading={loading}
        ></TemplatesTabs>
      )}
      <NewTemplateModal
        show={openCreateTemplate}
        setShow={() => setOpenCreateTemplate()}
        onTemplateCreated={onTemplateCreated}
      />
      {deleteMenuModalOpen && (
        <DeleteMenuModal
          open={deleteMenuModalOpen}
          onCancel={() => setDeleteMenuModalOpen()}
          onDeleteMenu={() => onDeleteMenu(selectedMenu)}
          title={selectedMenu?.name}
          setNotification={() => {}}
        />
      )}
      {editMenuModalOpen && (
        <EditMenuDetailsModal
          menu={selectedMenu}
          open={editMenuModalOpen}
          onEditMenuDetailsHandler={onEditMenu}
          onCancel={() => setEditMenuModalOpen()}
          showNotification={showNotification}
        ></EditMenuDetailsModal>
      )}
      {deleteCategoryModalOpen && (
        <DeleteCategoryModal
          open={deleteCategoryModalOpen}
          onCancel={() => setDeleteCategoryModalOpen()}
          onDeleteCategory={() => onDeleteCategory(selectedCategory)}
          category={selectedCategory}
        ></DeleteCategoryModal>
      )}
      {editCategoryModalOpen && (
        <EditCategoryModal
          category={selectedCategory}
          open={editCategoryModalOpen}
          onCancel={() => setEditCategoryModalOpen()}
          onEditCategoryHandler={onEditCategory}
          showNotification={showNotification}
          async
        ></EditCategoryModal>
      )}
      {deleteMenuWarningModal}
      {deleteCategoryWarningModal}
      <div className="notification-container">
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        />
      </div>
    </PageLayout>
  );
};
