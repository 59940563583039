import React from "react";
import clsx from "clsx";

import { SlashCircleIcon } from "@transfr-inc/dashboard-components";

export function ZeroStateMessage({ className }) {
  const emptyStateText = "No activity to display.";
  return (
    <div className={clsx("zero-state-message", className)}>
      <div className="text">
        <SlashCircleIcon />
        <span>{emptyStateText}</span>
      </div>
    </div>
  );
}
