import React, { useContext, useEffect, useState } from "react";
import { services } from "../dependencies";
import { SessionContext } from "./session.context";

export const UsersContext = React.createContext({});

export default ({ children }) => {
  const [users, setUsers] = useState();
  const [usersCount, setUsersCount] = useState();
  const { userService } = services;
  const { currentUser } = useContext(SessionContext);

  const contextValue = {
    users,
    usersCount,
    updateUsers: async () => {
      return userService
        .getCSUsers()
        .then(setUsers)
        .catch(() => setUsers());
    },
  };

  useEffect(() => {
    if (currentUser) {
      contextValue.updateUsers();
    } else {
      setUsers();
    }
  }, [currentUser]);

  useEffect(() => {
    setUsersCount(users?.length);
  }, [users]);

  return (
    <UsersContext.Provider value={contextValue}>
      {children}
    </UsersContext.Provider>
  );
};
