import React from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { OrgTypeDropdown } from "../../../components/forms/org-type-dropdown";
import { OrgDetailEditor } from "./org-detail-editor";
import {
  NotificationType,
  Notification,
  RequiredItem,
} from "@transfr-inc/dashboard-components";
import { OrgDetailsEditor } from "../../../components/forms/org-editor";

export function OrgInfo({
  responseNotification,
  onNotificationChange,
  childrenData,
  parentData,
  tsMenus,
  sims,
  trekCareers,
}) {
  const { control, reset } = useFormContext();

  const selectedType = useWatch({ name: "organizationType" });

  const onTypeSelected = async (type) => {
    reset({ organizationType: type });
  };

  return (
    <div>
      {responseNotification?.type == NotificationType.error && (
        <div className="error">
          <Notification
            {...responseNotification}
            closable
            animated
            onClose={() => onNotificationChange()}
          >
            {responseNotification.message}
          </Notification>
        </div>
      )}
      <div className="organization-type">
        <div className="section-title">
          <RequiredItem text="Organization Type" />
        </div>
        <Controller
          control={control}
          name="organizationType"
          rules={{ required: true }}
          render={({ field: { value } }) => {
            return (
              <OrgTypeDropdown
                className="org-dropdown"
                onTypeSelected={(type) => {
                  onTypeSelected(type);
                }}
                selectedType={value}
              />
            );
          }}
        />
        {selectedType && (
          <div>
            <OrgDetailsEditor
              className={"full-length-input"}
            ></OrgDetailsEditor>
            <OrgDetailEditor
              childrenData={childrenData}
              parentData={parentData}
              selectedType={selectedType}
              tsMenus={tsMenus}
              sims={sims}
              trekCareers={trekCareers}
            ></OrgDetailEditor>
          </div>
        )}
      </div>
    </div>
  );
}
