import { OptionItem } from "@transfr-inc/dashboard-components/forms";
import React from "react";

export const getTsCategoriesOptions = (data, displayTooltip = false) => {
  return data?.map((d) => {
    let htmlContent;
    if (displayTooltip) {
      htmlContent = (
        <div className="menu-categories-tooltip">
          <h3>Category Description</h3>
          <ul>
            <li>{d.category_description || d.description}</li>
          </ul>
          <h3>Modules</h3>
          <ul>
            {d.modules?.length === 0 && <li>No modules</li>}
            {d.modules?.map((m, i) => {
              if (m.moduleId !== null && m.moduleId !== undefined) {
                return <li key={m.moduleId + "-" + i}>{m.moduleName}</li>;
              }
            })}
          </ul>
        </div>
      );
    }
    return new OptionItem({
      id: d.id,
      text: d.name,
      data: d,
      tooltipHtmlContent: htmlContent || undefined,
    });
  });
};
