import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useApiRequest } from "../../../../../utils/http-client";

import { Loader } from "@transfr-inc/dashboard-components";
import { MenuOrgsModal } from "../../../list/components/menu-orgs.modal";
import {
  OrganizationBox,
  ORGS_DISPLAY_LIMIT,
  RemainderBox,
} from "./organization.box";
import { ZeroStateMessage } from "./zero-state-message";

import "./organizations.section.scss";

export const OrganizationsSection = ({ menu, className }) => {
  const { organizations } = menu;
  const [menuOrgsModalOpen, setMenuOrgsModalOpen] = useState();

  const firstThreeOrganizations = useMemo(() => {
    if (!organizations || organizations.length <= ORGS_DISPLAY_LIMIT) {
      return organizations;
    }
    return organizations.slice(0, ORGS_DISPLAY_LIMIT);
  }, [organizations]);

  return (
    <div className={clsx("organizations-section", className)}>
      <h2>Organizations</h2>
      <div className="box-section">
        {organizations && organizations.length > 0 ? (
          <>
            {firstThreeOrganizations.map((org) => (
              <OrganizationBox
                key={org.id}
                organization={org}
                breadcrumbLabel={org.name}
              />
            ))}
            <RemainderBox
              organizations={organizations}
              onClick={() => setMenuOrgsModalOpen(true)}
            />
          </>
        ) : (
          <ZeroStateMessage />
        )}
      </div>
      {organizations && organizations.length > ORGS_DISPLAY_LIMIT && (
        <MenuOrgsModal
          open={menuOrgsModalOpen}
          menu={menu}
          onClose={() => setMenuOrgsModalOpen()}
          breadcrumbLabel={menu.name}
        />
      )}
    </div>
  );
};
