import React, { cloneElement } from "react";

import {
  Tabs,
  Tab,
  TabHeaders,
  TabHeader,
} from "@transfr-inc/dashboard-components";

import { Product, getProduct } from "@transfr-inc/dashboard-components/utils";
import { DisciplinesSection } from "./disciplines.section";
import { CareersSection } from "./careers.section";
import { TreksSection } from "./treks.section";

import "./products.tabs.scss";

const getIconName = (productName) => {
  return `../../../../images/products/${productName?.toLowerCase()}.medium.svg`;
};

export const ProductsTabs = ({ organization }) => {
  const { products = [] } = organization;
  
  const getProductsTabs = () => {
    return products?.sort().map((p) => {
      const { id, name, displayName } = getProduct(p);
      return {
        name,
        header: (
          <TabHeader name={name}>
            <img src={getIconName(name)} alt={`${displayName} icon`} />
            <div className="tab-header-text">{displayName}</div>
          </TabHeader>
        ),
        content: (
          <Tab name={name} className="content-wrapper">
            {id === Product.TS && (
              <DisciplinesSection organization={organization} />
            )}
            {id === Product.CE && (
              <CareersSection organization={organization} />
            )}
            {id === Product.TRK && (
              <TreksSection organization={organization} />
            )}
          </Tab>
        ),
      };
    });
  };

  const tabs = getProductsTabs();

  return (
    <Tabs className={"products-tabs"} selectedTabName={tabs[0].name}>
      <TabHeaders>
        {tabs.map(({ header, name }, i) =>
          cloneElement(header, { key: `tab-header-${name}-${i}` })
        )}
      </TabHeaders>
      {tabs.map(({ content, name }, i) =>
        cloneElement(content, { key: `tab-content-${name}-${i}` })
      )}
    </Tabs>
  );
};
