import { MULTI_ORG_PARENT } from "../constants/organization-types";
import HttpClient from "../utils/http-client";
import { csvFormatRows } from "d3-dsv";

export class UserService extends HttpClient {
  async getCSUsers() {
    return this.http.get(`/client-services/users`);
  }

  async getOrganizationUsers(organizationCode, orgType) {
    return orgType === MULTI_ORG_PARENT.id
      ? this.getMultiOrgUsers(organizationCode)
      : this.getSingleOrgUsers(organizationCode);
  }

  async getMultiOrgUsers(organizationCode) {
    return this.http.get(`/multi-organization/${organizationCode}/users`);
  }

  async getSingleOrgUsers(organizationCode) {
    return this.http.get(`/organization/${organizationCode}/users`);
  }

  async createNewUser(user, orgCode) {
    const apiUrl = `/user/organization/${orgCode}/`;

    const data = await this.http.post(apiUrl, user);
    return data;
  }

  async getUserDetails(userId) {
    const apiUrl = `/user/${userId}/`;
    return this.http.get(apiUrl);
  }

  async resendInvite(userId) {
    const apiUrl = `/auth/invite/${userId}`;
    return await this.http.post(apiUrl);
  }

  async updateUserMetadata({
    userId,
    email,
    firstName,
    lastName,
    username,
    roleId,
  }) {
    const apiUrl = `/user/${userId}/`;
    const body = { email, lastName, firstName, username, roleId };
    return this.http.put(apiUrl, body);
  }

  async deleteUsers({ userIds, roleId }) {
    const apiUrl = `/user/bulk/deactivation`;
    return this.http.put(apiUrl, { userIds, roleId });
  }

  async searchUsers(search_str) {
    const apiUrl = `/client-services/users/search/${search_str}`;
    return this.http.get(apiUrl);
  }

  async uploadUsers(file) {
    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData.append("file", file);

    const apiUrl = "/client-services/user/upload";

    return this.http.post(apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  async getErrorsCsv(parsedData) {
    if (parsedData.errors.length === 0) {
      return "File imported successfully with no errors.";
    }

    const headerRowsArray = [parsedData.header_items];
    const dataArray = parsedData.errors.map((error) => {
      const values = parsedData.columns.map((column) => {
        return error.values[column.property_name];
      });

      values.push(error.message);
      return [...values];
    });

    const csvString = csvFormatRows([...headerRowsArray, ...dataArray]);
    return csvString;
  }
}
