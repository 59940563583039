import React, { useState, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

import {
  Badge,
  HyphenIcon,
  Loader,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { ModuleAccordion } from "./module.accordion";
import { ActivityLogModal } from "../../../../components/modals/activity-logs/activity-log.modal";
import { EditModulesModal } from "../../../../components/modals";
import {
  GenericError,
  UpdateModuleSimulationsSuccess,
  UpdateModulesSuccess,
} from "../../../../components/utils/notification.constants";
import { services } from "../../../../dependencies";
import { useApiRequest } from "../../../../utils/http-client";

import "./modules.section.scss";

export const ModulesSection = ({
  category,
  showNotification,
  editionEnabled = true,
  className,
}) => {
  const [loading, setLoading] = useState();
  const [modules, setModules] = useState([]);
  const [editModulesModalOpen, setEditModulesModalOpen] = useState();
  const [openActivityLogModal, setOpenActivityLogModal] = useState();

  const { menuBuilderService } = services;

  const {
    response: activityLogs,
    activityLogsLoading,
    sendRequest: getActivityLogs,
  } = useApiRequest(
    () =>
      menuBuilderService
        .getCategoryActivityLogs(category?.id)
        .catch((error) => {
          showNotification(NotificationType.error, GenericError);
          setOpenActivityLogModal();
          return;
        }),
    false
  );

  const fetchModulesWithSims = async () => {
    const categoryId = category?.id || category?.moduleId;
    setLoading(true);
    if (categoryId) {
      const modulesWithSims = await menuBuilderService.getCategoryModuleSims(
        categoryId
      );
      setModules(modulesWithSims);
    }
    setLoading();
  };

  useEffect(() => {
    fetchModulesWithSims();
  }, [category]);

  const modulesCount = useMemo(
    () => modules?.filter((s) => s.isVisible).length,
    [modules]
  );

  const onAddModules = () => {};

  const onEditModules = () => {
    setEditModulesModalOpen(true);
  };

  const onApplyEditModulesHandler = async (updatedModules) => {
    try {
      const updatedModulesData = updatedModules.map(
        ({ moduleId, orderNumber, isVisible }) => ({
          moduleId,
          orderNumber,
          isVisible,
        })
      );
      const response = await menuBuilderService.updateCategoryModules(
        category.id,
        updatedModulesData
      );
      setModules(updatedModules);
      setEditModulesModalOpen();
      showNotification(
        NotificationType.success,
        UpdateModulesSuccess(response),
        "circle-check"
      );
    } catch (error) {
      showNotification(NotificationType.error, GenericError);
    }
  };

  const onApplyEditSimulationsHandler = async (module, updatedSimulations) => {
    try {
      const updatedSimulationsData = updatedSimulations.map(
        ({ simulationCode, orderNumber, isVisible }) => ({
          simulationCode,
          orderNumber,
          isVisible,
        })
      );
      const response = await menuBuilderService.updateCategoryModuleSimulations(
        category.id,
        module.moduleId,
        updatedSimulationsData
      );
      setModules(
        modules.map((m) =>
          m.moduleId !== module.moduleId
            ? m
            : {
                ...m,
                simulations: updatedSimulations,
              }
        )
      );
      showNotification(
        NotificationType.success,
        UpdateModuleSimulationsSuccess(module),
        "circle-check"
      );
    } catch (error) {
      showNotification(NotificationType.error, GenericError);
    }
  };

  if (!modules || modules?.length === 0) {
    return (
      <div className="module-list-container">
        <div className="modules-header">
          <span className="modules-header-title">Modules</span>
        </div>
        <div className="no-modules-container">
          {loading && <Loader overlay></Loader>}
          <span className="no-modules-message">
            There are no modules for this category.
          </span>
          {editionEnabled && (
            <Button
              icon={["fa-regular", "head-side-goggles"]}
              onClick={onAddModules}
            >
              Add Modules
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="module-list-container">
        <div className={clsx("modules-header", className)}>
          <div className="left-section">
            <span className="modules-header-title">Modules</span>
            <Badge value={modulesCount ?? <HyphenIcon />} />
            {editionEnabled && (
              <>
                <Button
                  onClick={onEditModules}
                  icon={["fa-regular", "pen-to-square"]}
                />
              </>
            )}
          </div>
          <div className="right-section">
            <Button
              icon={["fa-regular", "list-timeline"]}
              onClick={() => setOpenActivityLogModal(true)}
              // disabled={actionsDisabled}
            >
              Activity Log
            </Button>
          </div>
        </div>
        {modules?.map(
          (module, index) =>
            module.isVisible && (
              <ModuleAccordion
                key={`${module.moduleId}.${index}`}
                module={module}
                onEditSimulations={onApplyEditSimulationsHandler}
                editionEnabled={editionEnabled}
                warningRequired={category?.menus?.length > 0}
              />
            )
        )}
      </div>
      <EditModulesModal
        open={editModulesModalOpen}
        modules={modules}
        category={category}
        onClose={() => setEditModulesModalOpen()}
        onApplyChanges={onApplyEditModulesHandler}
        async
      ></EditModulesModal>
      <ActivityLogModal
        open={openActivityLogModal}
        onClose={() => setOpenActivityLogModal()}
        logItemId={category?.id}
        logs={activityLogs}
        loading={activityLogsLoading}
        getActivityLogs={getActivityLogs}
        async
      ></ActivityLogModal>
    </>
  );
};
