import React, { useEffect, useState, useContext } from "react";

import { PageLayout } from "@transfr-inc/dashboard-components/layouts";
import { useHistory } from "react-router-dom";

import {
  Notification,
  NotificationType,
  Loader,
} from "@transfr-inc/dashboard-components";

import "./index.scss";
import { MenuDetailPageHeader } from "./components/page.header";
import { useLocation } from "react-router-dom";
import { OrganizationsSection } from "./components/organizations.section";
import { CategoriesSection } from "./components/categories.section";
import { EditMenuDetailsModal } from "../detail/components/modals/edit-menu-details/edit-menu-details.modal";
import { useApiRequest } from "../../../../utils/http-client";
import { GenericError } from "../../../../components/utils/notification.constants";
import { services } from "../../../../dependencies";
import { DeleteMenuModal } from "./components/menu-builder/modals/delete-menu/delete-menu.modal";
import { WarningMessages } from "../../../../components/modals/edit-warning/edit-warning.modal";
import { useEditWarningModal } from "../../../../hooks";
import { MenuBuilderContext } from "../../../../context";

export const MenuDetail = ({ responsiveStyles }) => {
  const { setGlobalNotification } = useContext(MenuBuilderContext);
  const history = useHistory();
  const [notification, setNotification] = useState();
  const { state } = useLocation();
  const { data, breadcrumbLabel } = state;
  const [editMenuDetailsModalOpen, setEditMenuDetailsModalOpen] = useState();
  const { menuBuilderService } = services;
  const [deleteMenuModalOpen, setDeleteMenuModalOpen] = useState();
  const [errorDeleteModal, openErrorDeleteModal] = useEditWarningModal({
    title: WarningMessages.menu.title,
    message: WarningMessages.menu.delete,
    icon: ["fa-solid", "fa-clock-rotate-left"],
    iconColor: "danger-red",
  });

  const [loading, setLoading] = useState();
  const [menuData, setMenuData] = useState(data);

  const onEditMenuDetails = async () => {
    getMenuDetails();
  };

  const mergeCategoryData = (updatedCategoryData) => {
    let sortedMenuCategories = menuData.categories.sort((a, b) => {
      const orderA =
        updatedCategoryData.find(
          (category) => category.categoryId === a.categoryId
        )?.orderNumber ?? 0;
      const orderB =
        updatedCategoryData.find(
          (category) => category.categoryId === b.categoryId
        )?.orderNumber ?? 0;
      return orderA - orderB;
    });

    for (const sortedMenuCategory of sortedMenuCategories) {
      sortedMenuCategory.isVisible = updatedCategoryData.find(
        (category) => category.categoryId === sortedMenuCategory.categoryId
      )?.isVisible;
    }

    const updatedState = { ...menuData, categories: sortedMenuCategories };
    setMenuData(updatedState);
  };

  const { sendRequest: getMenuDetails, loading: menuLoading } = useApiRequest(
    () =>
      menuBuilderService
        .getMenuDetails(data.id)
        .then((response) => setMenuData(response))
        .catch((error) => {
          setNotification({
            type: NotificationType.error,
            message: GenericError,
          });
        }),
    false
  );

  useEffect(() => {
    if (data) {
      getMenuDetails();
    }
  }, []);

  const onDeleteMenu = async () => {
    setLoading(true);
    await menuBuilderService.deleteTemplateCategory(data.id);
    setGlobalNotification({
      type: NotificationType.success,
      message: "Menu successfully deleted",
    });
    history.push("/app/templates");
    setLoading();
  };

  const handleDelete = (isError) => {
    if (isError) {
      openErrorDeleteModal();
    } else {
      setDeleteMenuModalOpen(true);
    }
  };

  return menuData ? (
    <PageLayout
      className="menu-detail-page"
      header={
        <MenuDetailPageHeader
          menu={menuData}
          breadcrumb={breadcrumbLabel}
          onEditMenuClick={() => setEditMenuDetailsModalOpen(true)}
          onDeleteMenuClick={handleDelete}
        />
      }
    >
      {(loading || menuLoading) && <Loader overlay></Loader>}
      <div className="menu-detail-body">
        <div className="text-with-info">
          <label>
            Changes made will be automatically propagated to organizations
            provisioned with this menu<sup>*</sup>
          </label>
        </div>
        <OrganizationsSection menu={menuData}></OrganizationsSection>
        <CategoriesSection
          menu={menuData}
          mergeCategoryData={mergeCategoryData}
        ></CategoriesSection>
      </div>
      {editMenuDetailsModalOpen && (
        <EditMenuDetailsModal
          menu={menuData}
          open={editMenuDetailsModalOpen}
          onEditMenuDetailsHandler={onEditMenuDetails}
          onCancel={() => setEditMenuDetailsModalOpen()}
        />
      )}
      <DeleteMenuModal
        open={deleteMenuModalOpen}
        onCancel={() => setDeleteMenuModalOpen()}
        onDeleteMenu={onDeleteMenu}
        title={data?.name}
        setNotification={setNotification}
      />
      {errorDeleteModal}
      <div className="notification-container success-notification">
        {notification && (
          <Notification
            type={notification.type}
            icon={notification.icon}
            onClose={() => {
              setNotification();
            }}
            closable
            animated
          >
            {notification.message}
          </Notification>
        )}
      </div>
    </PageLayout>
  ) : (
    <Loader overlay></Loader>
  );
};
