import React, { cloneElement } from "react";

import { TabHeaders, Tabs, Loader } from "@transfr-inc/dashboard-components";

import { UsersTabs } from "../organizations/detail/components/users.tabs";
import { getRoleMap } from "../organizations/detail/utils/user-roles.utils";

export const SearchList = ({
  users,
  responsiveStyles,
  bottomBarOptions,
  actionMenuOptions,
  dataFiltered,
  isLoading,
}) => {
  const usersTabs = UsersTabs({
    data: getRoleMap(users),
    isMultiOrg: true,
    responsiveStyles,
    actionMenuOptions,
    bottomBarOptions,
    dataFiltered,
    searchEnabled: false,
    isRowActive: (row) => row.isActive == undefined || row.isActive == true,
  });

  return (
    <>
      <Tabs className={"search-tabs"} selectedTabName={usersTabs[0].name}>
        <TabHeaders>
          {usersTabs.map(({ header, name }, i) =>
            cloneElement(header, { key: `tab-header-${name}-${i}` })
          )}
        </TabHeaders>
        {!isLoading &&
          usersTabs.map(({ content, name }, i) =>
            cloneElement(content, { key: `tab-content-${name}-${i}` })
          )}
      </Tabs>
      {isLoading && <Loader fixed />}
    </>
  );
};
