import { Role } from "./role";

export const RoleTransformations = [
  [Role.CS_ADMIN.id, [Role.CS_MANAGER]],
  [Role.CS_MANAGER.id, [Role.CS_ADMIN]],
];
export const RoleTransformationsMap = new Map(RoleTransformations);

export const getRoleTransformations = (role) => {
  return RoleTransformationsMap.get(role.id);
};
