import React, { useContext, useState } from "react";
import { SessionContext } from "../../context/session.context";
import { Toolbar } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { ProfileInformation } from "./profile-information";
import { services } from "../../dependencies";
import { useApiRequest } from "../../utils/http-client";
import { PageHeader } from "@transfr-inc/dashboard-components/layouts";
import { Color } from "@transfr-inc/dashboard-components/utils";
import "./profile.scss";

export default () => {
  const { currentUser, logout } = useContext(SessionContext);
  const [user, setUser] = useState();

  const { userService } = services;

  const { loading: loadingUser } = useApiRequest(() =>
    userService.getUserDetails(currentUser.userId).then(setUser)
  );

  return (
    !loadingUser && (
      <div className="profile-page">
        <div className="profile-header">
          <PageHeader
            title="Profile"
            drawerColorEnabled
            drawerBorderEnabled
            backgroundColor={Color.navBarGrey}
          >
            <Toolbar>
              <Button
                primary
                icon="fa-regular fa-door-open"
                onClick={logout}
                title="Sign Out"
              >
                Sign Out
              </Button>
            </Toolbar>
          </PageHeader>
        </div>
        <div className="profile-body">
          <ProfileInformation user={user} />
        </div>
      </div>
    )
  );
};
