import React, { useContext } from "react";
import clsx from "clsx";

import {
  Column,
  ColumnType,
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";

import { ZeroStateMessage } from "./zero-state-message";
import { UsersColumnDefinition } from "./users.columns";

import { SessionContext } from "../../../../context/session.context";

import "./users.table.scss";

export function UsersTable({
  data,
  role,
  responsiveStyles,
  bottomBarOptions,
  actionMenuOptions,
  onSetShowNewUser,
  dataFiltered,
  className = "cs-users-table",
}) {
  const { currentUser } = useContext(SessionContext);

  const columns = [
    ...(bottomBarOptions
      ? [new Column("select", /*title:*/ undefined, ColumnType.SELECTION)]
      : []),
    UsersColumnDefinition.NAME(undefined, currentUser.userId),
    UsersColumnDefinition.EMAIL,
  ];

  const zeroStateMessage = (
    <ZeroStateMessage
      role={role}
      onActionClicked={onSetShowNewUser}
      isFiltered={dataFiltered}
    ></ZeroStateMessage>
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      className={clsx(className, bottomBarOptions && "selection")}
      keyFields={["userId"]}
      keyPrefix={"cs-users-" + role.id}
      defaultSortPropName="name"
      defaultSortDirection={SortDirection.ASCENDING}
      responsiveStyles={responsiveStyles}
      zeroStateMessage={zeroStateMessage}
      bottomBarOptions={bottomBarOptions}
      tableContentName={{ singular: "user", plural: "users" }}
      actionMenuOptions={actionMenuOptions}
      isRowLocked={(row) => row.userId === currentUser.userId}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
      virtualRowEnabled
    />
  );
}
