import React, { useMemo, useState } from "react";
import clsx from "clsx";

import {
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
  AccordionBody,
  Badge,
  HyphenIcon,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { createPortal } from "react-dom";
// import { EditSimulationsModal } from "../modals";

import "./module.accordion.scss";

export const ModuleAccordion = ({
  module,
  onEditSimulations,
  editionEnabled,
  className,
}) => {
  const { moduleSimulations, moduleName } = module;
  // const [openEditSimulationsModal, setOpenEditSimulationsModal] = useState();

  const simulationsCount = useMemo(
    () => moduleSimulations.filter((s) => s.isVisible).length,
    [module]
  );

  const onApplyChanges = (updatedSimulations) => {
    // return onEditSimulations?.({ updatedSimulations, module }).then(() =>
    //   setOpenEditSimulationsModal()
    // );
    console.log(`ModuleAccordion simulations onApplyChanges`);
  };

  return (
    <Accordion className={clsx("module-accordion", className)}>
      <AccordionHeader>
        <AccordionHeaderName>
          {moduleName}
        </AccordionHeaderName>
      </AccordionHeader>
      <AccordionBody>
        <div className="simulations-header">
          <span>Simulations</span>
          <Badge value={simulationsCount ?? <HyphenIcon />} />
          {editionEnabled && (
            <Button
              // onClick={() => setOpenEditSimulationsModal(true)}
              icon={["fa-regular", "pen-to-square"]}
            />
          )}
        </div>
        <div className="simulations-list">
          {moduleSimulations?.map(
            (sim, index) =>
              sim.isVisible && (
                <div
                  className="simulation-container"
                  key={`${sim.learningExperienceId}.${index}`}
                >
                  <span>{sim.simulationName}</span>
                </div>
              )
          )}
        </div>
        {/* {createPortal(
          <EditSimulationsModal
            open={openEditSimulationsModal}
            onClose={() => setOpenEditSimulationsModal()}
            simulations={moduleSimulations}
            onApplyChanges={onApplyChanges}
            async
          />,
          document.body
        )} */}
      </AccordionBody>
    </Accordion>
  );
};
