import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToggleButton } from "@transfr-inc/dashboard-components/forms";
import { TextTooltip } from "@transfr-inc/dashboard-components";
import clsx from "clsx";

import "./org-form-toggle.scss";

export function OrgFormToggle({ name, title, infoText, className }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: false,
      }}
      render={({ field: { onChange, value } }) => {
        return (
          <div className={clsx("toggle-input", className)}>
            <h4>{title}</h4>
            <TextTooltip className="info" text={infoText}>
              <FontAwesomeIcon icon="fa-light fa-circle-info" />
            </TextTooltip>
            <ToggleButton
              className="toggle-button"
              onChange={onChange}
              value={value}
              affirmText={"YES"}
              negateText={"NO"}
            />
          </div>
        );
      }}
    />
  );
}
