import React, { useContext } from "react";
import { SessionContext } from "../../context/session.context";
import "./profile.scss";
import { ProfileAvatar, RoleBadge } from "@transfr-inc/dashboard-components";
import { getRoleAvatarColor } from "@transfr-inc/dashboard-components/utils";

export function ProfileInformation({ user }) {
  const { role } = useContext(SessionContext);
  const { firstName, lastName, email } = user;

  return (
    <div className="profile-information">
      <div className="top">
        <ProfileAvatar
          className="avatar"
          fillColor={getRoleAvatarColor(role)}
        />
        <h1 className="name-header">
          {firstName} {lastName}
        </h1>
        <div className="name-subheader">{email}</div>
      </div>
      <div className="bottom">
        <div className="box-section last">
          <h6 className="section-name">USER TYPE</h6>
          <RoleBadge role={role} />
        </div>
      </div>
    </div>
  );
}
