import React, { useContext, useEffect, useState } from "react";
import { services } from "../dependencies";
import { useApiRequest } from "../utils/http-client";
import { SessionContext } from "./session.context";

const getOrganizationsNames = (data) => {
  return data?.map((d) => d.name);
};

export const OrganizationContext = React.createContext({});

export default ({ children }) => {
  const [organizations, setOrganizations] = useState();
  const [orgsCount, setOrsCount] = useState();
  const [organizationsNames, setOrganizationsNames] = useState();
  const { organizationService } = services;
  const { currentUser } = useContext(SessionContext);

  const { loading, sendRequest } = useApiRequest(() => {
    return organizationService
      .getAllOrganizations()
      .then(setOrganizations)
      .catch(() => setOrganizations());
  }, false);

  const contextValue = {
    organizations,
    orgsCount,
    loadingOrgs: loading,
    updateOrganizations: sendRequest,
    organizationsNames,
  };

  useEffect(() => {
    if (currentUser) {
      contextValue.updateOrganizations();
    } else {
      setOrganizations();
    }
  }, [currentUser]);

  useEffect(() => {
    setOrsCount(organizations?.length);
    setOrganizationsNames(getOrganizationsNames(organizations));
  }, [organizations]);

  return (
    <OrganizationContext.Provider value={contextValue}>
      {children}
    </OrganizationContext.Provider>
  );
};
