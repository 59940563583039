import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import { RequiredItem } from "@transfr-inc/dashboard-components";
import { Input } from "@transfr-inc/dashboard-components/forms";

export function CategoryNameEditor({ className, name = "name" }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>
          <RequiredItem text="Category Name" />
        </h2>
      </div>
      <Controller
        control={control}
        name={name}
        rules={{ required: true, maxLength: 150 }}
        defaultValue={""}
        render={({ field: { onBlur, onChange, value, ref } }) => {
          return (
            <Input
              className="cat-name-input"
              label="Name"
              errors={errors[name]}
              name={name}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              maxLength={150}
            />
          );
        }}
      />
    </div>
  );
}
