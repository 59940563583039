import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Loader,
  RequiredItem,
} from "@transfr-inc/dashboard-components";

import {
  CheckboxStates,
  TriCheckBox,
  Button,
} from "@transfr-inc/dashboard-components/forms";

import { useOrderer, OrderMove } from "@transfr-inc/dashboard-components/hooks";
import { ListItemEdition, Movement } from "../../forms";
import { useEditWarningModal } from "../../../hooks";
import { WarningMessages } from "../edit-warning/edit-warning.modal";

import "./edit-simulations.modal.scss";

const EditSimulationsModal = ({
  simulations,
  open,
  onClose,
  onApplyChanges,
  async,
  warningRequired = false,
  className,
}) => {
  const { CHECKED, EMPTY, INDETERMINATE } = CheckboxStates;
  const [selectAllItems, setSelectAllItems] = useState(true);
  const [loading, setLoading] = useState();
  const [animationParent] = useAutoAnimate();

  const { items, updateItems, move, isFirstItem, isLastItem } = useOrderer({
    data: simulations,
    idProp: "simulationCode",
    orderProp: "orderNumber",
  });

  const simulationCount = useMemo(
    () => items?.filter((c) => c.isVisible).length,
    [items]
  );

  const checkboxValue = useMemo(() => {
    const selected = items?.filter((c) => c.isVisible).length;
    if (selected === items?.length) {
      return CHECKED;
    } else {
      return selected === 0 ? EMPTY : INDETERMINATE;
    }
  }, [items]);

  const onSelectAllItems = () => {
    const selectAllItemsValue = !selectAllItems;
    updateAllItems(selectAllItemsValue);
  };

  const onApplyHandler = async () => {
    if (async) {
      setLoading(true);
      await onApplyChanges?.(items);
      setLoading();
    } else {
      onApplyChanges?.(items);
    }
  };
  
  const [warningEditModal, openWarningEditModal] = useEditWarningModal({
    title: WarningMessages.category.title,
    message: WarningMessages.category.edit,
    buttons: [
      {
        id: "no",
        label: "No, Cancel",
        handler: onClose,
      },
      {
        id: "yes",
        label: "Yes, I'm Sure",
        handler: onApplyHandler,
        primary: true,
      }
    ],
  });

  const onApply = () => {
    if (warningRequired) {
      openWarningEditModal();
    } else {
      onApplyHandler();
    }
  }

  const onSimulationSelected = (index) => {
    updateItems(
      items.map((simulation, i) => {
        if (i === index) {
          simulation.isVisible = !simulation.isVisible;
        }
        return simulation;
      })
    );
  };

  const updateAllItems = (isAllSelected) => {
    updateItems(
      items.map((simulation) => ({
        ...simulation,
        isVisible: isAllSelected,
      }))
    );
    setSelectAllItems(isAllSelected);
  };

  const onChangeOrder = (movement, index) => {
    const orderMove =
      movement === Movement.Up ? OrderMove.Backward : OrderMove.Forward;
    move(orderMove, index);
  };

  useEffect(() => {
    updateItems(simulations?.map((c, i) => ({ ...c, orderNumber: i })));
  }, [open]);

  return (
    <>
      <Modal
        modalClassName={clsx("edit-simulations-modal", className)}
        open={open}
        onClose={onClose}
        preventClose={loading}
      >
        {loading && <Loader overlay />}
        <ModalHeader icon={["fa-regular", "pen-to-square"]}>
          <div className="modal-title">
            <h2>Simulations</h2>
            <Badge value={simulationCount} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <span>Check the simulations that you would like included.</span>
            <br />
            <span>Unchecked simulations can be recovered later.</span>
            <br />
            <RequiredItem
              className="required-container"
              text="At least 1 simulation must be selected"
            />
          </div>
          <div className="actions-container">
            <TriCheckBox
              labelPresent
              value={checkboxValue}
              onChange={onSelectAllItems}
            ></TriCheckBox>
          </div>
          <div className="category-list" ref={animationParent}>
            {items?.map((simulation, index) => (
              <ListItemEdition
                key={`${simulation.simulationCode}`}
                isChecked={simulation?.isVisible}
                onChecked={() => onSimulationSelected(index)}
                isDownEnabled={!isLastItem(simulation)}
                isUpEnabled={!isFirstItem(simulation)}
                label={simulation?.simulationName}
                onChangeOrder={(movement) => onChangeOrder(movement, index)}
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary disabled={simulationCount === 0} onClick={onApply}>
            Apply
          </Button>
        </ModalFooter>
      </Modal>
      {warningEditModal}
    </>
  );
};

export default EditSimulationsModal;