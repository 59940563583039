import React from "react";
import clsx from "clsx";

import {
  Loader,
  HyphenIcon,
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
} from "@transfr-inc/dashboard-components";

import { services } from "../../../../dependencies";
import { useApiRequest } from "../../../../utils/http-client";

import "./disciplines.section.scss";

export const TreksSection = ({ className, organization }) => {
  const { trekService } = services;
  const { response: data, loading } = useApiRequest(() =>
    trekService.getOrganizationTreks(organization?.code)
  );

  return (
    <div
      className={clsx("disciplines-section", loading && "loader", className)}
    >
      <h2>
        Careers <span className="badge">{data?.length ?? <HyphenIcon />}</span>
      </h2>

      <div className="content-section">
        {data?.map((d) => (
          <Accordion key={`career-${d.id}`}>
            <AccordionHeader>
              <AccordionHeaderName>{d.title}</AccordionHeaderName>
            </AccordionHeader>
          </Accordion>
        ))}
        {loading && <Loader overlay fixed />}
      </div>
    </div>
  );
};
