import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import { Link, TextAvatar } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { OrganizationsRoute, RootPath } from "../../../../../components/navbar";
import "./organization.box.scss";

export const OrganizationBox = ({
  organization,
  breadcrumbLabel,
  onRemove,
  enableRemove,
}) => {
  const { url } = useRouteMatch(RootPath);
  const { name, code, typeId } = organization ?? {};
  const pathConfig = {
    pathname: `${url}${OrganizationsRoute.path}/${code}`,
    state: {
      code: code,
      type: typeId,
      breadcrumbLabel,
    },
  };

  return (
    <div className="organization-box">
      <TextAvatar text={[name]} />
      <Link routerComponent={NavLink} to={pathConfig}>
        {name}
      </Link>
      {enableRemove && (
        <Button
          borderless
          icon={["fa-regular", "xmark"]}
          onClick={() => onRemove && onRemove(organization)}
        ></Button>
      )}
    </div>
  );
};

export const ORGS_DISPLAY_LIMIT = 3;

export const RemainderBox = ({ organizations, onClick }) => {
  if (organizations.length <= ORGS_DISPLAY_LIMIT) {
    return null;
  }

  return (
    <div className="organization-box">
      <Link className="organization-box-link" onClick={onClick}>
        + {organizations.length - ORGS_DISPLAY_LIMIT}
      </Link>
    </div>
  );
};
