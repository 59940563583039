import { getClientMultiOrgRoles } from "@transfr-inc/dashboard-components/utils";

export const getRoleMap = (data) => {
  const allRoles = getClientMultiOrgRoles();
  const roleMap = new Map();

  for (const user of data) {
    if (!roleMap.has(user.roleId)) {
      roleMap.set(user.roleId, []);
    }

    const userArray = roleMap.get(user.roleId);
    userArray.push(user);
  }

  for (const role of allRoles) {
    if (!roleMap.has(role.id)) {
      roleMap.set(role.id, []);
    }
  }
  return roleMap;
};
