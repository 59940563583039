import React from "react";
import { useRegisterSW } from "virtual:pwa-register/react";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./pwa.scss";

export default () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered: " + r);
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    },
  });

  return (
    <div className="update-notification-container">
      {needRefresh && (
        <Notification
          type={NotificationType.info}
          closable
          animated
          icon={["fa-solid", "download"]}
          onClose={() => setNeedRefresh(false)}
        >
          <div className="update-message">
            A new version of the Transfr dashboard is available.{" "}
            <Button
              className="update-button"
              borderless
              onClick={() => updateServiceWorker(true)}
            >
              Update Now
            </Button>
          </div>
        </Notification>
      )}
    </div>
  );
};
