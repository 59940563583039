import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalHeader,
  FullScreenModalFooter,
  NotificationType,
  Notification,
  Loader,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import {
  Role,
  getClientMultiOrgRoles,
  getClientSingleOrgRoles,
  getRoleName,
} from "@transfr-inc/dashboard-components/utils";

import { UserInfo } from "../../../components/forms/user-info";
import {
  ClassroomSelectorSection,
  OrganizationSelectorSection,
  RoleSelectorSection,
  UserFormSection,
} from "../../forms/user-form";

import { services } from "../../../dependencies";
import { catchCreateUserError } from "../../../services/error-handler";
import { MULTI_ORG_PARENT } from "../../../constants/organization-types";

import image from "../../../../public/images/create-user-hero.jpg";
import "./new-client-user.modal.scss";

const defaultValues = {
  email: "",
  firstName: "",
  lastName: "",
  role: undefined,
};

export default ({ open, onClose, organization, onUserCreated, className }) => {
  // State variables & functions for dropdowns
  const [roleOptions, setRoleOptions] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedOrg, setSelectedOrg] = useState();
  const [isTraineeUser, setIsTraineeUser] = useState();
  const [enableOrganization, setEnableOrganization] = useState();
  const [enableClassroom, setEnableClassroom] = useState();
  const [loading, setLoading] = useState();
  const [notification, setNotification] = useState();

  const { userService } = services;

  const onRoleSelected = (optionSelected) => {
    const { id } = optionSelected;
    const isOrgRequired =
      organization.typeId === MULTI_ORG_PARENT.id &&
      (id === Role.INSTRUCTOR.id || id === Role.ORG_ADMIN.id);
    const isClassroomRequired = !isOrgRequired && id === Role.INSTRUCTOR.id;

    setIsTraineeUser(id === Role.STUDENT.id);
    setEnableOrganization(isOrgRequired);

    if (!isOrgRequired) {
      setSelectedOrg(organization);
      setEnableClassroom(isClassroomRequired);
    } else {
      setSelectedOrg();
    }

    setSelectedRole(optionSelected);
    reset({ role: optionSelected });
  };

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues,
  });

  const onCloseHandler = () => {
    resetForm();
    onClose();
  };

  const resetForm = () => {
    reset(defaultValues);
    setSelectedRole();
    setSelectedOrg();
    setIsTraineeUser();
    setEnableClassroom();
    setEnableOrganization();
    setNotification();
  };

  const { handleSubmit, reset, formState } = methods;
  const { isValid } = formState;

  async function submitForm(values, e) {
    e.preventDefault();
    setLoading(true);

    const orgCode = selectedOrg.code;

    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleId: values.role.id,
      classrooms: values.classroom ? [values.classroom.classroomId] : undefined,
    };

    try {
      await userService.createNewUser(user, orgCode);
      const message = (
        <>
          <strong>{`${values.firstName} ${values.lastName}`}</strong> has been
          created as <strong>{`${getRoleName(selectedRole.id)}`}</strong> user
        </>
      );
      onUserCreated(message);
    } catch (error) {
      catchCreationError(error);
    } finally {
      setLoading();
    }
  }

  const catchCreationError = (error) => {
    const errorMessage = catchCreateUserError(error);
    setNotification({
      type: NotificationType.error,
      message: errorMessage,
    });
  };

  const onClick = (e) => {
    (async () => {
      handleSubmit(submitForm)(e);
    })();
  };

  const onOrganizationSelected = (value) => {
    setSelectedOrg(value);
    setEnableClassroom(selectedRole.id === Role.INSTRUCTOR.id);
  };

  useEffect(() => {
    const roles =
      organization?.typeId === MULTI_ORG_PARENT.id
        ? getClientMultiOrgRoles()
        : getClientSingleOrgRoles();
    const rolesFiltered = roles.filter((r) => r.id !== Role.STUDENT.id);
    setRoleOptions(rolesFiltered);
  }, [organization]);

  return (
    <FullScreenModal
      className={clsx("new-client-user-modal", className)}
      preventClose
      onClose={onCloseHandler}
      open={open}
    >
      <FullScreenModalHeader
        title="Create a New User"
        titleIcon="fa-regular fa-user-plus"
      />
      <FullScreenModalBody>
        <div className="new-client-user-form">
          {loading && <Loader overlay></Loader>}
          <div
            className="image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <UserFormSection>
            <FormProvider {...methods}>
              <form className="user-form" noValidate={true}>
                {notification && (
                  <Notification
                    type={notification.type}
                    icon={notification.icon}
                    onClose={() => {
                      setNotification();
                    }}
                    closable
                    animated
                  >
                    {notification.message}
                  </Notification>
                )}
                <RoleSelectorSection
                  required
                  options={roleOptions}
                  onRoleChange={onRoleSelected}
                />
                {enableOrganization && (
                  <OrganizationSelectorSection
                    required
                    organization={organization}
                    onOrgChange={onOrganizationSelected}
                  />
                )}
                {enableClassroom && (
                  <ClassroomSelectorSection
                    organization={selectedOrg}
                    clearEnabled
                  />
                )}
                {selectedRole && selectedOrg && (
                  <div className="section-title">
                    <UserInfo
                      className="user-info"
                      required
                      emailRequired={!isTraineeUser}
                      passwordEnabled={isTraineeUser}
                      passwordRequired={isTraineeUser}
                      usernameEnabled={isTraineeUser}
                    />
                  </div>
                )}
              </form>
            </FormProvider>
          </UserFormSection>
        </div>
      </FullScreenModalBody>
      <FullScreenModalFooter>
        <Button disabled={loading} onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button primary disabled={!isValid || loading} onClick={onClick}>
          Create User
        </Button>
      </FullScreenModalFooter>
    </FullScreenModal>
  );
};
