import React, { useState, cloneElement } from "react";

import {
  Tabs,
  Tab,
  TabHeaders,
  TabHeader,
  TabBadge,
} from "@transfr-inc/dashboard-components";

import { TemplatesTable } from "./templates.table";
import { UNIVERSAL_MENU_NAME } from "../../../../constants/template-types";
import { Category } from "../../../../constants/category.model";
import { MenuOrgsModal } from "./menu-orgs.modal";

import "./templates.tabs.scss";

export const TemplatesTabs = ({
  data,
  responsiveStyles,
  actionMenuOptions,
  loading = false,
}) => {
  const { menuData, categoryData } = data;
  const { menu: menuActions, category: categoryActions } = actionMenuOptions;
  const [selectedMenu4Orgs, setSelectedMenu4Orgs] = useState();

  const menusTab = {
    name: "templates-menus",
    header: (
      <TabHeader name="templates-menus" className="templates-menus-tab-header">
        <div className="tab-header-text">Menus</div>
        <TabBadge count={menuData.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="templates-menus">
        <TemplatesTable
          className="templates-table"
          data={menuData}
          dataType={"Menus"}
          responsiveStyles={responsiveStyles}
          actionMenuOptions={[menuActions.delete, menuActions.edit]}
          isMenuActionVisible={(menu, actionMenuOptions) => 
            (menu.name !== UNIVERSAL_MENU_NAME ? actionMenuOptions : [])
          }
          openMenuOrgsModal={setSelectedMenu4Orgs}
        ></TemplatesTable>
        {selectedMenu4Orgs && (
          <MenuOrgsModal
            open={!!selectedMenu4Orgs}
            menu={selectedMenu4Orgs}
            onClose={() => setSelectedMenu4Orgs()}
            breadcrumbLabel="All Menus"
          />
        )}
      </Tab>
    ),
  };

  const categoriesTab = {
    name: "templates-categories",
    header: (
      <TabHeader
        name="templates-categories"
        className="templates-categories-tab-header"
      >
        <div className="tab-header-text">Categories</div>
        <TabBadge count={categoryData.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="templates-categories">
        <TemplatesTable
          className="templates-table"
          data={categoryData}
          dataType={"Categories"}
          responsiveStyles={responsiveStyles}
          actionMenuOptions={[categoryActions.delete, categoryActions.edit]}
          isMenuActionVisible={(category, actionMenuOptions) => 
            (category?.categoryType === Category.INTERNAL_CUSTOM ? actionMenuOptions : [])
          }
        ></TemplatesTable>
      </Tab>
    ),
  };

  const getTemplatesTabs = () => {
    const tabs = [];
    tabs.push(menusTab, categoriesTab);

    return tabs;
  };

  const tabs = getTemplatesTabs();

  return (
    <Tabs className={"templates-tabs"} selectedTabName={tabs[0].name}>
      <TabHeaders>
        {tabs.map(({ header, name }, i) =>
          cloneElement(header, { key: `tab-header-${name}-${i}` })
        )}
      </TabHeaders>
      {!loading &&
        tabs.map(({ content, name }, i) =>
          cloneElement(content, { key: `tab-content-${name}-${i}` })
        )}
    </Tabs>
  );
};
