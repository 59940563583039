import HttpClient from "../utils/http-client";

export class MenuBuilderService extends HttpClient {
  async getAllMenus() {
    const apiUrl = "/client-services/menus";
    return this.http.get(apiUrl);
  }

  async createMenu(menuInfo) {
    const apiUrl = "/client-services/menus";
    return this.http.post(apiUrl, menuInfo);
  }

  async getMenuCategories(menuId) {
    const apiUrl = `/client-services/menus/${menuId}/categories`;
    return this.http.get(apiUrl);
  }

  async updateCategories(menuId, categories) {
    const apiUrl = `/client-services/menus/${menuId}/categories`;
    return this.http.put(apiUrl, { categories });
  }

  async getMenuOrganizations(menuId) {
    const apiUrl = `/client-services/menus/${menuId}/organizations`;
    return this.http.get(apiUrl);
  }

  async addTemplateCategory(templateData) {
    const apiUrl = `/client-services/templates/category`;
    return this.http.post(apiUrl, templateData);
  }

  async getTemplateCategories(includeAuthorInfo = true) {
    const apiUrl = `/client-services/templates/categories`;
    return this.http.get(apiUrl, {
      params: { include_author_info: includeAuthorInfo },
    });
  }

  async getCategoryMenus(categoryId) {
    const apiUrl = `/client-services/templates/categories/${categoryId}/menus`;
    return this.http.get(apiUrl);
  }

  async getAllModules() {
    const apiUrl = `/client-services/templates/modules`;
    return this.http.get(apiUrl);
  }

  async getCategoryModuleSims(categoryId) {
    const apiUrl = `/client-services/templates/modules/${categoryId}`;
    return this.http.get(apiUrl);
  }

  async updateCategoryModules(categoryId, modules) {
    const apiUrl = `/client-services/templates/categories/${categoryId}/modules`;
    return this.http.put(apiUrl, { modules });
  }

  async updateCategoryModuleSimulations(categoryId, moduleId, simulations) {
    const apiUrl = `/client-services/templates/categories/${categoryId}/modules/${moduleId}/simulations`;
    return this.http.put(apiUrl, { simulations });
  }

  async updateCustomCategory(categoryId, templateData) {
    const apiUrl = `/client-services/templates/category/${categoryId}`;
    return this.http.put(apiUrl, templateData);
  }

  async deleteCategory(categoryId) {
    const apiUrl = `/client-services/templates/category/${categoryId}`;
    return this.http.delete(apiUrl);
  }

  async getMenuActivityLogs(menuId) {
    const apiUrl = `/client-services/menus/${menuId}/activity`;
    return this.http.get(apiUrl);
  }

  async getCategoryActivityLogs(categoryId) {
    const apiUrl = `/client-services/templates/category/${categoryId}/activity`;
    return this.http.get(apiUrl);
  }

  async getMenuDetails(menuId) {
    const apiUrl = `/client-services/menus/${menuId}`;
    return this.http.get(apiUrl);
  }

  async deleteTemplateCategory(menuId) {
    const apiUrl = `/client-services/menus/${menuId}`;
    return this.http.delete(apiUrl);
  }

  async editMenuMetaDetails(menuId, menuData) {
    const apiUrl = `/client-services/menus/meta/${menuId}`;
    return this.http.put(apiUrl, menuData);
  }
}
