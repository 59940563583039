import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  CategoryNameEditor,
  CategoryModules,
  ProductDropdown,
  CategoryDescEditor,
  CategoryImageDropdown,
} from "../../../components/forms/cat-form";
import { RequiredItem } from "@transfr-inc/dashboard-components";

import "./index.scss";

const CategoriesForm = ({ register, control, formState, setValue }) => {
  if (!register && !formState && !control) {
    ({ register, formState, control, setValue } = useFormContext());
  } else if (!register || !formState || !control || !setValue) {
    throw (
      "MenuTemplate: for register, control, and formState, all values must be specified or entirely left unspecified " +
      "Specifying a value for only the errors prop, for example, will result in this error message."
    );
  }

  const onImageSelected = async (image) => {
    setValue("image", image, { shouldValidate: true });
  };

  return (
    <div className="categories-form">
      <div className="category-field">
        <CategoryNameEditor name="name" className={"full-length-input"} />
      </div>
      <div className="category-field">
        <div className="section-title">
          <h2>
            <RequiredItem text="Product" />
          </h2>
        </div>
        <p className="product-text">
          Custom menus are only available for Training Simulations *
        </p>
        <Controller
          control={control}
          name="products"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => {
            return (
              <ProductDropdown
                className="org-dropdown"
                onTypeSelected={(type) => {
                  onChange(type);
                }}
                selectedType={value}
                products={[value]}
                disabled
              />
            );
          }}
        />
      </div>
      <div className="category-field">
        <Controller
          control={control}
          name="image"
          rules={{
            required: {
              value: true,
              message: "Required fields are missing.",
            },
          }}
          render={({ field: { value } }) => {
            return (
              <CategoryImageDropdown
                className="category-image-dropdown"
                onCategoryImageSelected={(image) => onImageSelected(image)}
                selectedImage={value}
              />
            );
          }}
        />
      </div>
      <div className="category-field">
        <CategoryModules name={"tsmodules"} />
      </div>
      <div className="category-field">
        <CategoryDescEditor name={"description"} />
      </div>
    </div>
  );
};

export default CategoriesForm;
