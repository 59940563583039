import React from "react";
import clsx from "clsx";

import {
  Loader,
  HyphenIcon,
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
} from "@transfr-inc/dashboard-components";

import { services } from "../../../../dependencies";
import { useApiRequest } from "../../../../utils/http-client";

import "./disciplines.section.scss";

export const CareersSection = ({ className, organization }) => {
  const { organizationService } = services;
  const { response: data, loading } = useApiRequest(() =>
    organizationService.getOrganizationSimulations(organization?.code)
  );
  return (
    <div
      className={clsx("disciplines-section", loading && "loader", className)}
    >
      <h2>
        Simulations{" "}
        <span className="badge">
          {data?.simulations.length ?? <HyphenIcon />}
        </span>
      </h2>

      <div className="content-section">
        {data?.simulations.map((d) => (
          <Accordion key={`career-${d.id}`}>
            <AccordionHeader>
              <AccordionHeaderName>
                {d.name}
              </AccordionHeaderName>
            </AccordionHeader>
          </Accordion>
        ))}
        {loading && <Loader overlay fixed />}
      </div>
    </div>
  );
};
