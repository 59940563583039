import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import AccountLayout from "../../layouts/account";

import { Button, Input } from "@transfr-inc/dashboard-components/forms";

import {
  Link,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { SessionContext } from "../../context/session.context";
import personA from "/images/vr-person-a.jpg";
import personB from "/images/vr-person-b.jpg";

function getImage(value) {
  return value % 2 ? personA : personB;
}

export default () => {
  const history = useHistory();
  const imageRef = useRef(Math.floor(Math.random() * 100));
  const { isLoggedIn, login, error } = useContext(SessionContext);

  const { register, handleSubmit, formState } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
  });
  const [notification, setNotification] = useState();

  const fromResetPassword = history.location.state?.passwordResetSuccess;

  useEffect(() => {
    if (error) {
      setNotification({
        type: NotificationType.error,
        message: error?.message,
      });
    }
  }, [error]);

  useEffect(() => {
    if (fromResetPassword) {
      setNotification({
        type: NotificationType.success,
        message: "Password reset complete!",
        icon: "fa-lock",
      });
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace("/app");
    }
  }, [isLoggedIn]);

  const onSignIn = (payload) => {
    setNotification();
    login(payload);
  };

  return (
    <AccountLayout
      title={["Welcome", "Back"]}
      image={getImage(imageRef.current)}
    >
      {notification && (
        <Notification
          type={notification.type}
          icon={notification.icon}
          onClose={() => setNotification()}
          closable
          animated
        >
          {notification.message}
        </Notification>
      )}
      <form
        className="account-form"
        onSubmit={handleSubmit(onSignIn)}
        noValidate={true}
      >
        <div className="inputs">
          <Input
            label="Email"
            type="email"
            errors={formState.errors.username}
            {...register("username", {
              required: true,
              pattern: {
                value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
              },
            })}
          />

          <Input
            type="password"
            label="Password"
            errors={formState.errors.password}
            {...register("password", { required: true })}
          />
        </div>

        <Button label="Sign In" primary={true} type="submit" />
        <div className="form-options">
          <Link routerComponent={NavLink} underline to={"/forgot-password"}>
            Forgot Password
          </Link>
        </div>
      </form>
    </AccountLayout>
  );
};
