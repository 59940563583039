import React from "react";

import { AllOrgTypes } from "../../constants/organization-types";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

export function OrgTypeDropdown({ className, onTypeSelected, selectedType }) {
  return (
    <Dropdown
      className={className}
      selected={selectedType}
      onOptionSelected={onTypeSelected}
    >
      <DropdownButton
        placeholder={"Select an organization type..."}
        icon={selectedType?.icon}
      >
        {selectedType?.displayName}
      </DropdownButton>
      <DropdownItems>
        {AllOrgTypes.map((type) => (
          <DropdownItem key={type.id} value={type}>
            {type.displayName}
          </DropdownItem>
        ))}
      </DropdownItems>
    </Dropdown>
  );
}
