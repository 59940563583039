/* eslint-disable unicorn/consistent-function-scoping */
import React, { useEffect, useState, useContext } from "react";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../hooks";

import {
  Input,
  Button,
  ValidationItem,
} from "@transfr-inc/dashboard-components/forms";
import AccountLayout from "../../layouts/account";
import { SessionContext } from "../../context/session.context";
import { services } from "../../dependencies";
import {
  NotificationType,
  Notification,
} from "@transfr-inc/dashboard-components";

import forgotPasswordImage from "../../../public/images/forgot-password.svg";

export default () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  // const actions = useStoreActions((store) => store.resetPassword);
  // const state = useStoreState((store) => store.resetPassword);
  const [loadError, setLoadError] = useState();
  const [loadBlocking, setLoadBlocking] = useState();
  const [stateNotification, setStateNotification] = useState();

  const { logout } = useContext(SessionContext);
  const { authenticationService } = services;

  const history = useHistory();
  const queryParams = useQueryString();

  const [layoutTitle, setLayoutTitle] = useState(["Reset", "Password"]);

  async function submitForm(values, e) {
    e.preventDefault();
    try {
      await authenticationService.resetPassword(
        queryParams.token,
        values.password
      );
      goLoginPage();
    } catch {
      console.log("error");
      setStateNotification({
        type: NotificationType.error,
        message: "Something went wrong.",
      });
    }
  }

  const onClick = (e) => {
    (async () => {
      handleSubmit(submitForm)(e);
    })();
  };

  // onload
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        setLoadBlocking(true);
        await authenticationService.validateResetPassword(
          queryParams.email,
          queryParams.token
        );
        setLoadError(false);
        setLoadBlocking(false);
      } catch {
        setLayoutTitle(["Reset", "Password", "Expired"]);
        setLoadError(true);
        setLoadBlocking(false);
      }
    }
    fetchMyAPI();
  }, []);

  const goLoginPage = () => {
    logout();
    history.push({
      pathname: "/login",
      state: {
        passwordResetSuccess: true,
      },
    });
  };

  return (
    <AccountLayout title={layoutTitle} image={forgotPasswordImage}>
      <>
        <div>
          {!loadBlocking && loadError && (
            <div className="account-form">
              <div className="instructions">
                Your reset password link has expired. Please visit the sign in
                page and press “Forgot Password” to try again.
              </div>
              <div>
                <Button
                  className="primary block"
                  label="Return to Sign In"
                  onClick={goLoginPage}
                />
              </div>
            </div>
          )}

          {!loadError && (
            <form className="account-form" onSubmit={onClick} noValidate={true}>
              <div className="instructions">
                Please enter a new password for your Transfr account.
              </div>
              <div className="notification-container">
                {stateNotification && (
                  <Notification
                    type={stateNotification.type}
                    icon={stateNotification.icon}
                    onClose={() => {
                      setStateNotification();
                    }}
                    closable
                    animated
                  >
                    {stateNotification.message}
                  </Notification>
                )}
              </div>
              <div className="inputs">
                <Input
                  label="Password"
                  type="password"
                  errors={errors.password}
                  {...register("password", {
                    validate: {
                      count: (v) =>
                        v.length >= 8 ||
                        "Password must be at least 8 characters",
                      hasNumber: (v) =>
                        /\d+/.test(v) || "Password must include a number",
                      hasSpecial: (v) =>
                        /[!#$%&@^]+/.test(v) ||
                        "Password must include a special character",
                    },
                  })}
                />

                <Input
                  label="Confirm Password"
                  type="password"
                  errors={errors.passwordMatch}
                  {...register("passwordMatch", {
                    validate: {
                      matches: (v) => {
                        const password = getValues("password");
                        return password === v || "match";
                      },
                    },
                  })}
                />
              </div>
              <div className="requirements">
                <ul>
                  <li>
                    <div className="title">Password Requirements</div>
                  </li>
                  <li>
                    <ValidationItem
                      label={"Minimum of 8 characters"}
                      valid={!errors.password?.types.count}
                      enabled={dirtyFields.password}
                    />
                  </li>
                  <li>
                    <ValidationItem
                      label={"At least one number"}
                      valid={!errors.password?.types.hasNumber}
                      enabled={dirtyFields.password}
                    />
                  </li>
                  <li>
                    <ValidationItem
                      label={"At least one special character"}
                      valid={!errors.password?.types.hasSpecial}
                      enabled={dirtyFields.password}
                    />
                  </li>
                  <li>
                    <ValidationItem
                      label={"Confirm your new password"}
                      valid={!errors.passwordMatch?.types?.matches}
                      enabled={
                        dirtyFields.password && dirtyFields.passwordMatch
                      }
                    />
                  </li>
                </ul>
              </div>
              <div>
                <Button
                  className="primary block"
                  type="submit"
                  label="Reset Password"
                  disabled={!isValid}
                />
              </div>
            </form>
          )}
        </div>
      </>
    </AccountLayout>
  );
};
