import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { RequiredItem } from "@transfr-inc/dashboard-components";

export function OrgSelectParentEditor({
  orgs,
  required = false,
  clearEnabled,
}) {
  const fieldTitle = "Organization's Parent";
  const { control } = useFormContext();

  return (
    <div className="editor-section">
      <div className="section-title">
        <h2>{required ? <RequiredItem text={fieldTitle} /> : fieldTitle}</h2>
      </div>
      <Controller
        control={control}
        rules={{ required }}
        name="parent"
        render={({ field: { value, onChange } }) => {
          return (
            <Dropdown
              className="org-dropdown"
              selected={value}
              onOptionSelected={onChange}
            >
              <DropdownButton
                placeholder={"Select an organization..."}
                icon={"fa-light fa-sitemap"}
                clearEnabled={clearEnabled}
              >
                {value?.name}
              </DropdownButton>
              <DropdownItems>
                {orgs?.map((org) => (
                  <DropdownItem key={org.code} value={org} propName={"code"}>
                    {org.name}
                  </DropdownItem>
                ))}
              </DropdownItems>
            </Dropdown>
          );
        }}
      />
    </div>
  );
}
