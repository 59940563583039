import React, { useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { PageLayout } from "@transfr-inc/dashboard-components/layouts";

import { Loader, NotificationStack } from "@transfr-inc/dashboard-components";

import { CategoryDetailPageHeader } from "./components/page.header";
import { EditCategoryModal } from "../menus/detail/components/menu-builder/modals/edit-category/edit-category.modal";
import { DeleteCategoryModal } from "../menus/detail/components/menu-builder/modals/delete-category/delete-category.modal";
import { WarningMessages } from "../../../components/modals/edit-warning/edit-warning.modal";

import { MenusSection } from "./components/menus.section";
import { ModulesSection } from "./components/modules.section";
import { GenericError } from "../../../components/utils/notification.constants";
import { Category } from "../../../constants/category.model";
import { MenuBuilderContext } from "../../../context";

import { services } from "../../../dependencies";
import { useEditWarningModal } from "../../../hooks";

import "./index.scss";

export const CategoryDetail = ({ responsiveStyles }) => {
  const { state } = useLocation();
  const { getAllCategories } = useContext(MenuBuilderContext);

  const history = useHistory();
  const { data, breadcrumbLabel } = state;
  const [loading, setLoading] = useState();
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState();
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState();
  const [errorDeleteModal, openErrorDeleteModal] = useEditWarningModal({
    title: WarningMessages.category.title,
    message: WarningMessages.category.delete,
    icon: ["fa-solid", "fa-clock-rotate-left"],
    iconColor: "danger-red",
  });
  const [notifications, setNotifications] = useState([]);
  const [categoryData, setCategoryData] = useState(data);

  const { menuBuilderService } = services;

  const onEditCategoryHandler = async (value) => {
    setCategoryData(value);
    getAllCategories();
  };

  const onDeleteCategoryHandler = async () => {
    setLoading(true);
    try {
      await menuBuilderService.deleteCategory(categoryData.id);
      setLoading();
      setDeleteCategoryModalOpen();
      history.replace("/templates");
    } catch {
      setLoading();
      showNotification(NotificationType.error, GenericError);
    }
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onCloseNotification = (indexToRemove) => {
    setNotifications(
      notifications.filter((n, index) => index !== indexToRemove)
    );
  };

  const handleDeleteCategoryClick = () => {
    if (categoryData?.menus?.length === 0) {
      setDeleteCategoryModalOpen(true);
    } else {
      openErrorDeleteModal(true);
    }
  };

  const canEdit = categoryData?.categoryType === Category.INTERNAL_CUSTOM;

  return (
    <PageLayout
      className="category-detail-page"
      header={
        <CategoryDetailPageHeader
          category={categoryData}
          breadcrumb={breadcrumbLabel}
          onEditCategoryClick={() => setOpenEditCategoryModal(true)}
          onDeleteCategoryClick={handleDeleteCategoryClick}
        />
      }
    >
      {loading && <Loader overlay></Loader>}
      <div className="category-detail-body">
        <div className="text-with-info">
          <label>
            Changes made will be automatically propagated to menus provisioned
            with this category <sup>*</sup>
          </label>
        </div>
        <MenusSection category={categoryData} />
        <ModulesSection
          category={categoryData}
          showNotification={showNotification}
          editionEnabled={canEdit}
        />
        {openEditCategoryModal && (
          <EditCategoryModal
            category={categoryData}
            open={openEditCategoryModal}
            onCancel={() => setOpenEditCategoryModal()}
            onEditCategoryHandler={onEditCategoryHandler}
            onDeleteCategoryClickHandler={() => handleDeleteCategoryClick()}
            async
          ></EditCategoryModal>
        )}
        {deleteCategoryModalOpen && (
          <DeleteCategoryModal
            open={deleteCategoryModalOpen}
            onCancel={() => setDeleteCategoryModalOpen()}
            onDeleteCategory={onDeleteCategoryHandler}
            category={categoryData}
          ></DeleteCategoryModal>
        )}
        {errorDeleteModal}
      </div>
      <div className="notification-container">
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        />
      </div>
    </PageLayout>
  );
};
