import React, { useContext, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import { Select, OptionItem } from "@transfr-inc/dashboard-components/forms";
import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { MenuBuilderContext } from "../../../context";

import "./category-form.scss";

const defaultPlaceholder = "";

export const getDisciplinesOptions = (data) =>
  data?.map(
    (d) =>
      new OptionItem({
        id: d.moduleId,
        text: d.moduleName,
        data: d,
        header: d.appDescription || "N/A",
      })
  );

export const CategoryModules = ({ className, placeholder, name }) => {
  const { control } = useFormContext();
  const { modules } = useContext(MenuBuilderContext);

  const data = getDisciplinesOptions(modules);

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>TS Modules *</h2>
      </div>
      <Controller
        control={control}
        rules={{
          required: {
            value: true,
            message: "Required fields are missing.",
          },
        }}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              selected={value}
              placeholder={placeholder ?? defaultPlaceholder}
              onOptionsChange={onChange}
              className={"product-select"}
              options={data}
              clearEnabled={false}
              displayHeader
            />
          );
        }}
      />
    </div>
  );
};
