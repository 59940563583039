import HttpClient from "../utils/http-client";

export class AuthenticationService extends HttpClient {
  /**
   *
   * @param {string} username
   * @param {string} password
   * @returns {object}
   */
  async login(username, password) {
    const data = await this.http
      .post("/auth/login", {
        username,
        password,
      })
      .catch((error) => {
        const e =
          error?.status === 401
            ? new Error("Email or password incorrect")
            : error;
        throw e;
      });
    return data;
  }

  /**
   *
   * @param {string} email
   * @returns {object}
   */
  async sendResetPassword(email) {
    const data = await this.http.post("/auth/reset/send", email);
    return data;
  }

  /**
   *
   * @param {string} userId
   * @returns {boolean}
   */
  async resendInvite(userId) {
    const apiUrl = `/auth/invite/${userId}`;
    return await this.http.post(apiUrl);
  }

  /**
   * @param {string} email
   * @param {string} token
   * @returns {object}
   */
  async validateResetPassword(email, token) {
    const params = {
      token,
      email,
    };
    console.log(email, token);
    const data = await this.http.get(`/auth/reset/password`, { params });
    return data;
  }

  /**
   *
   * @param {string} token
   * @param {string} password
   * @returns {object}
   */
  async resetPassword(token, password) {
    const data = await this.http.post("/auth/reset/password", {
      token,
      password,
    });
    return data;
  }

  /**
   *
   * @param {string} userId
   * @param {string} destination
   * @returns {string}
   */
  async impersonate(userId, destination = process.env.DASHBOARD_URL) {
    const params = { userId };
    const data = await this.http.post("/client-services/impersonate", params);
    window.open(`${destination}/login?it=${data.token}`);
    return data;
  }
}
