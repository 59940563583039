import React from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ZeroStateMessage({ className }) {
  const emptyStateText = "There are no saved templates.";
  return (
    <div className={clsx("zero-state-message", className)}>
      <div className="text">
        <FontAwesomeIcon icon={["fa-light", "list"]} />
        <span>{emptyStateText}</span>
      </div>
    </div>
  );
}
