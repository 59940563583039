import React, { useEffect, useState } from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import {
  Loader,
  Notification,
  NotificationType,
  NotificationStack,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RequiredItem,
} from "@transfr-inc/dashboard-components";
import {
  Button,
  Input,
  Textarea,
} from "@transfr-inc/dashboard-components/forms";
import "./edit-category.modal.scss";
import { CategoryImageDropdown } from "../../components/category-images.dropdown";
import { CategoryModules } from "../../../../../../../../components/forms/cat-form";
import { services } from "../../../../../../../../dependencies";
import { getDisciplinesOptions } from "../../../../../../../../components/forms/cat-form/category-modules.dropdown";
import { WarningMessages } from "../../../../../../../../components/modals/edit-warning/edit-warning.modal";
import { useEditWarningModal } from "../../../../../../../../hooks";
import { EditCategorySuccess } from "../../../../../../../../components/utils/notification.constants";

export function EditCategoryModal({
  category,
  open,
  onCancel,
  onEditCategoryHandler,
  onDeleteCategoryClickHandler,
  showNotification,
}) {
  const [loading, setLoading] = useState();
  const [notifications, setNotifications] = useState();
  const [responseNotification, setResponseNotification] = useState();
  const { menuBuilderService } = services;

  const methods = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues: {
      ...category,
      modules: getDisciplinesOptions(
        category.modules?.filter((v) => v.isVisible)
      ),
    },
  });

  const { handleSubmit, reset, formState, control, setValue } = methods;

  const { errors, isValid } = formState;

  const showError = (...errors) => {
    setNotifications(
      errors.map((e) => ({
        type: NotificationType.error,
        message: e,
      }))
    );
  };

  const checkErrors = () => {
    if (Object.keys(errors).length > 0) {
      const messages = new Set(Object.values(errors).map((e) => e.message));
      showError(...messages.values());
    } else {
      setNotifications();
    }
  };

  useEffect(() => {
    if (errors) {
      checkErrors();
    }
  }, [formState]);

  useEffect(() => {
    !open && reset();
  }, [open]);

  const onCloseHandler = () => {
    onCancel && onCancel();
    setNotifications();
    reset(category, { keepDefaultValues: true });
  };

  const submitForm = async (values) => {
    setLoading(true);
    const mappedModules = values.modules.map((mod) => ({
      moduleId: mod.id,
      moduleName: mod.text,
    }));

    const data = {
      categoryName: values.name,
      categoryImage: values.image,
      categoryDescription: values.description,
      modules: mappedModules,
    };

    try {
      let updatedCategory = await editCategory(data);
      if (showNotification) {
        showNotification(
          NotificationType.success,
          EditCategorySuccess(updatedCategory),
          "circle-check"
        );
      } else {
        setNotifications([
          {
            type: NotificationType.success,
            message: "Category successfully edited.",
          },
        ]);
      }
      onCloseHandler();

      if (!updatedCategory.menus) {
        updatedCategory = {
          ...updatedCategory,
          menus: category.menus,
        };
      }
      onEditCategoryHandler(updatedCategory);
    } catch (error) {
      setResponseNotification({
        type: NotificationType.error,
        message: !error?.data ? "Something went wrong" : error.data.detail,
      });
    } finally {
      setLoading();
    }
  };

  const editCategory = async (data) => {
    const updatedCategory = await menuBuilderService.updateCustomCategory(
      category?.id,
      data
    );
    return updatedCategory;
  };

  const onImageSelected = async (image) => {
    setValue("image", image, { shouldValidate: true });
  };

  const editCustomCategoryHandler = () => {
    handleSubmit(submitForm)();
  };

  const [warningEditModal, openWarningEditModal] = useEditWarningModal({
    title: WarningMessages.category.title,
    message: WarningMessages.category.edit,
    buttons: [
      {
        id: "no",
        label: "No, Cancel",
        handler: onCancel,
      },
      {
        id: "yes",
        label: "Yes, I'm Sure",
        handler: editCustomCategoryHandler,
        primary: true,
      },
    ],
  });

  const onClickEditCategory = () => {
    if (category?.menus?.length > 0) {
      openWarningEditModal();
    } else {
      editCustomCategoryHandler();
    }
  };

  const onClickDeleteCategory = () => {
    onCancel();
    onDeleteCategoryClickHandler();
  };

  return (
    <>
      <Modal
        modalClassName={"edit-category-modal"}
        open={open}
        onClose={onCloseHandler}
        preventClose={loading}
      >
        {loading && <Loader overlay></Loader>}
        <ModalHeader
          className="blue-icon"
          icon={["fa-regular", "plus"]}
          title="Edit Category Details"
        ></ModalHeader>
        <ModalBody>
          <div className="validation-errors">
            {notifications?.length && (
              <NotificationStack
                notifications={notifications}
              ></NotificationStack>
            )}
            {responseNotification?.type == NotificationType.error && (
              <div className="error">
                <Notification
                  {...responseNotification}
                  closable
                  onClose={() => setResponseNotification()}
                >
                  {responseNotification.message}
                </Notification>
              </div>
            )}
          </div>
          <div className="text-with-info">
            <label>
              Required Fields<sup>*</sup>
            </label>
          </div>
          <FormProvider {...methods}>
            <form className="edit-category-form">
              <Controller
                control={control}
                name="name"
                defaultValue={category?.categoryName}
                rules={{
                  required: {
                    value: true,
                    message: "Required fields are missing.",
                  },
                }}
                render={({ field: { onBlur, onChange, value, ref } }) => {
                  return (
                    <Input
                      label={<RequiredItem text="Name" />}
                      type="text"
                      name="name"
                      errors={errors?.name}
                      ref={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      required
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="image"
                defaultValue={category?.categoryImage}
                rules={{
                  required: {
                    value: true,
                    message: "Required fields are missing.",
                  },
                }}
                render={({ field: { value } }) => {
                  return (
                    <CategoryImageDropdown
                      className="category-image-dropdown"
                      onCategoryImageSelected={(image) =>
                        onImageSelected(image)
                      }
                      selectedImage={value}
                    />
                  );
                }}
              />
              <CategoryModules name={"modules"} />
              <Controller
                control={control}
                defaultValue={category?.categoryDescription}
                name="description"
                rules={{
                  required: {
                    value: true,
                    message: "Required fields are missing.",
                  },
                }}
                render={({ field: { onBlur, onChange, value, ref } }) => {
                  return (
                    <Textarea
                      label={<RequiredItem text="Description" />}
                      name="categoryDescription"
                      errors={errors?.name}
                      ref={ref}
                      onBlur={onBlur}
                      onInputChange={onChange}
                      value={value}
                      required
                    />
                  );
                }}
              />
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseHandler}>Cancel</Button>
          <Button primary onClick={onClickEditCategory} disabled={!isValid}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
      {warningEditModal}
    </>
  );
}
