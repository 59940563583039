import React, { useMemo, useState } from "react";
import clsx from "clsx";

import {
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
  AccordionBody,
  Badge,
  HyphenIcon,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { EditSimulationsModal } from "../../../../components/modals";

import "./module.accordion.scss";

export const ModuleAccordion = ({
  module,
  onEditSimulations,
  editionEnabled,
  className,
  warningRequired = false,
}) => {
  const { simulations: moduleSimulations, moduleName, appDescription } = module;
  const [editSimulationsModalOpen, setEditSimulationsModalOpen] = useState();

  const simulationsCount = useMemo(
    () => moduleSimulations.filter((s) => s.isVisible).length,
    [module]
  );

  const onApplyEditSimulationsHandler = (updatedSimulations) => {
    return onEditSimulations?.(module, updatedSimulations).then(() =>
      setEditSimulationsModalOpen()
    );
  };

  return (
    <Accordion className={clsx("module-accordion", className)}>
      <AccordionHeader>
        <AccordionHeaderName>
          <div className="module-name-container">
            <div>{moduleName}</div>
            <div className="app-description">
              App: {appDescription || "N/A"}
            </div>
          </div>
        </AccordionHeaderName>
      </AccordionHeader>
      <AccordionBody>
        <div className="simulations-header">
          <span>Simulations</span>
          <Badge value={simulationsCount ?? <HyphenIcon />} />
          {editionEnabled && (
            <Button
              onClick={() => setEditSimulationsModalOpen(true)}
              icon={["fa-regular", "pen-to-square"]}
            />
          )}
        </div>
        <div className="simulations-list">
          {moduleSimulations?.map(
            (sim, index) =>
              sim.isVisible && (
                <div
                  className="simulation-container"
                  key={`${sim.id}.${index}`}
                >
                  <span>{sim.simulationName}</span>
                </div>
              )
          )}
        </div>
        <EditSimulationsModal
          open={editSimulationsModalOpen}
          simulations={moduleSimulations}
          onClose={() => setEditSimulationsModalOpen()}
          onApplyChanges={onApplyEditSimulationsHandler}
          async
          warningRequired={warningRequired}
        ></EditSimulationsModal>
      </AccordionBody>
    </Accordion>
  );
};
