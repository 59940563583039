import React from "react";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

export const ClassroomDropdown = ({
  data,
  className,
  onClassroomSelected,
  selectedClassroom,
  clearEnabled = false,
}) => {
  return (
    <Dropdown
      className={className}
      selected={selectedClassroom}
      onOptionSelected={onClassroomSelected}
    >
      <DropdownButton
        placeholder={"Select a classroom..."}
        icon={selectedClassroom?.icon}
        clearEnabled={clearEnabled}
      >
        {selectedClassroom?.name}
      </DropdownButton>
      <DropdownItems>
        {data?.map((classroom) => (
          <DropdownItem key={classroom.id} value={classroom}>
            {classroom.name}
          </DropdownItem>
        ))}
      </DropdownItems>
    </Dropdown>
  );
};
