import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import ContainerRoute from "../../components/routing/container-route";
import { default as Search } from "./search";

export default ({ responsiveStyles = {} }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={path}
        component={Search}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
    </Switch>
  );
};
