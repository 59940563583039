import React, { useEffect, useState } from "react";

import {
  Loader,
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { MenuBuilderCategories } from "../components/menu-builder/layout/menu-builder.categories";
import { MenuBuilderHeader } from "../components/menu-builder/layout/menu-builder.header";
import { services } from "../../../../../dependencies";
import { GenericError } from "../../../../../components/utils/notification.constants";
import { UNIVERSAL_MENU_NAME } from "../../../../../constants/template-types";

import "./categories.section.scss";

export const CategoriesSection = ({ menu, mergeCategoryData, menuLoading }) => {
  const [notifications, setNotifications] = useState([]);
  const { menuBuilderService } = services;
  const [loading, setLoading] = useState(false);

  const menuEditable = menu.name !== UNIVERSAL_MENU_NAME;

  const onEditCategoriesHandler = async (data) => {
    setLoading(true);
    const { id: menuId } = menu;
    const categoryData = data.map((c) => {
      const { categoryId, orderNumber, isVisible } = c;
      return { categoryId, orderNumber, isVisible };
    });

    try {
      const updatedCategories = await menuBuilderService.updateCategories(
        menuId,
        categoryData
      );
      mergeCategoryData(updatedCategories);
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
    setLoading();
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onCloseNotification = (indexToRemove) => {
    setNotifications(
      notifications.filter((n, index) => index !== indexToRemove)
    );
  };

  return (
    <div className="menu-builder-container">
      {loading && <Loader overlay fixed />}
      <MenuBuilderHeader
        categories={menu.categories}
        onEditCategoriesClick={onEditCategoriesHandler}
        menu={menu}
        showNotification={showNotification}
        menuEditable={menuEditable}
      ></MenuBuilderHeader>
      <MenuBuilderCategories
        categories={menu.categories}
        menu={menu}
        menuEditable={menuEditable}
      />
      <div className="notification-container">
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        />
      </div>
    </div>
  );
};
