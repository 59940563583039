import HttpClient from "../utils/http-client";

export class CareersService extends HttpClient {
  async getAllCareers() {
    return await this.http.get(`/occupations`);
  }
  async getAllSimulations() {
    return await this.http.get(`/client-services/ce/simulations`);
  }
}
