import React, { useState } from "react";
import clsx from "clsx";

import {
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
  AccordionBody,
  HyphenIcon,
} from "@transfr-inc/dashboard-components";

import { getCategoryImage } from "../../../../../../../components/utils/categories-images.mapper";

import "./category.accordion.scss";
import { CategoryImage } from "./category.image";
import { ModuleList } from "./module.list";
import { Category } from "../../../../../../../constants/category.model";

export const CategoryAccordion = ({
  category,
  className,
  onDeleteCategory,
  allModules,
  onApplyModulesHandler,
  menu,
  menuEditable,
}) => {
  const { categoryImage, categoryDescription, categoryModules } =
    category || {};
  const [showHeaderDetail, setShowHeaderDetail] = useState();
  const categoryImageConfig = getCategoryImage(categoryImage);

  return (
    <>
      <Accordion
        className={clsx("category-accordion", className)}
        onExpand={() => setShowHeaderDetail(true)}
        onCollapse={() => setShowHeaderDetail()}
      >
        <AccordionHeader>
          <AccordionHeaderName>
            <div className="category-container">
              <div className="category-name">
                <span>{category.categoryName}</span>
                {category?.categoryType == Category.CUSTOM && (
                  <span className="badge">Custom</span>
                )}
              </div>
            </div>
          </AccordionHeaderName>
          <div
            className={clsx("category-details", showHeaderDetail && "expanded")}
          >
            <span>Image</span>
            <CategoryImage categoryImageConfig={categoryImageConfig} />
            <span>Description</span>
            <div>{categoryDescription ?? <HyphenIcon />}</div>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <ModuleList modules={categoryModules} editionEnabled={false} />
        </AccordionBody>
      </Accordion>
    </>
  );
};
