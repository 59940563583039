import React from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./zero-state-message.scss";

export function ZeroStateMessage({
  role,
  className,
  onActionClicked,
  isFiltered,
}) {
  const emptyStateText = isFiltered
    ? `There are no ${role.pluralDisplayName} with the applied filter. `
    : `There are no ${role.pluralDisplayName} for this organization. `;
  return (
    <div
      className={clsx(
        "zero-state-message",
        className,
        onActionClicked && "clickable"
      )}
    >
      <div className="text">
        <FontAwesomeIcon icon={["fa-light", "user-plus"]} />
        {onActionClicked ? (
          <button onClick={onActionClicked}>
            {emptyStateText}
            Tap to add a new one.
          </button>
        ) : (
          <span>{emptyStateText}</span>
        )}
      </div>
    </div>
  );
}
