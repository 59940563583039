import React from "react";
import { toggles } from "../dependencies";

export const AppContext = React.createContext({});

export default ({ children }) => {
  const { ceEnabled } = toggles;
  const contextValue = { ceEnabled };
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
