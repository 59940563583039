import React, { useContext } from "react";
import clsx from "clsx";

import { Tab, TabBadge, TabHeader } from "@transfr-inc/dashboard-components";
import {
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";

import { OrganizationsColumnDefinition } from "../../utils/organizations.columns";

import { SessionContext } from "../../../../context";

import { useRouteMatch } from "react-router-dom";
import {
  OrganizationsRoute,
  RootPath,
} from "../../../../components/navbar/routes";

import "./child-orgs.tab.scss";

export function ChildOrganizationsTable({
  data,
  parentOrg,
  responsiveStyles,
  actionMenuOptions,
  className,
}) {
  const { path } = useRouteMatch(RootPath + OrganizationsRoute.path);
  const { features } = useContext(SessionContext);
  const { canEditOrg } = features;
  const { edit } = actionMenuOptions;
  const columns = [
    OrganizationsColumnDefinition.Name(path, parentOrg),
    OrganizationsColumnDefinition.OrgCode,
    OrganizationsColumnDefinition.ExpirationDate,
    OrganizationsColumnDefinition.MaxTrainees,
    OrganizationsColumnDefinition.Products,
  ];

  const actions = [canEditOrg && edit];

  return {
    name: "childOrgs",
    header: (
      <TabHeader name="childOrgs" className="child-orgs-tab-header">
        <div className="tab-header-text">Child Orgs</div>
        <TabBadge count={data?.length ?? "..."}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="childOrgs">
        <DataTable
          columns={columns}
          data={data ?? []}
          className={clsx("cs-child-organizations-table", className)}
          keyFields={["code"]}
          keyPrefix={"cs-child-organizations-table"}
          defaultSortPropName="name"
          defaultSortDirection={SortDirection.ASCENDING}
          responsiveStyles={responsiveStyles}
          actionMenuOptions={actions}
          rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
          virtualRowEnabled
        />
      </Tab>
    ),
  };
}
