import clsx from "clsx";
import React from "react";

import Footer from "./footer";
import { LogoSVGEmbed } from "@transfr-inc/dashboard-components";

import "./account.scss";

export default function AccountLayout({ title, image, className, children }) {
  const titles = Array.isArray(title) ? title : [title];
  const lastIndex = titles.length - 1;

  return (
    <div className={clsx("account-layout", className)}>
      <div className="left">
        <div className="content">
          <header>
            <LogoSVGEmbed />
          </header>
          <h1>
            {titles.map((t, i) => (
              <React.Fragment key={i}>
                {t}
                {i < lastIndex && <br />}
              </React.Fragment>
            ))}
          </h1>
          {children}
          <Footer />
        </div>
      </div>
      <div className="right" style={{ backgroundImage: `url(${image})` }}></div>
    </div>
  );
}
