/* eslint-disable unicorn/consistent-destructuring */
import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalHeader,
  FullScreenModalFooter,
  NotificationType,
  Loader,
} from "@transfr-inc/dashboard-components";
import { RoleSelector, Button } from "@transfr-inc/dashboard-components/forms";

import { UserInfo } from "../../../components/forms/user-info";
import { getRoleName, AllRoles } from "../../../constants/role";

import { services } from "../../../dependencies";
import { SessionContext } from "../../../context";

import "./new-user-modal.scss";

export const NewUserModal = ({ setShow, show, className, onUserCreated }) => {
  // State variables & functions for dropdowns
  const [selectedRole, setSelectedRole] = useState("");
  const { userService } = services;
  const [loading, setLoading] = useState();

  const { orgCode } = useContext(SessionContext);

  const onRoleSelected = (optionSelected) => {
    setSelectedRole(optionSelected);
    reset();
  };

  const [formInvalid, setFormInvalid] = useState(true);
  const [notification, setNotification] = useState();

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
  });

  const onClose = () => {
    setSelectedRole("");
    reset();
    setShow(false);
  };

  // refs:
  // https://react-hook-form.com/api/useform/formstate
  // https://react-hook-form.com/advanced-usage#FormProviderPerformance
  //
  // Although formState is not used in this component, we still need to "subscribe" to it due to the following mention
  // in the first link above:
  // "formState is wrapped with a Proxy to improve render performance and skip extra logic if specific state is not
  // subscribed to. Therefore make sure you invoke or read it before a render in order to enable the state update."
  // The second link demonstrates sample usage with FormProvider that we are employing here.

  const { handleSubmit, reset } = methods;

  methods.formState.errors;
  methods.formState.isValid;
  methods.formState.dirtyFields;

  async function submitForm(values, e) {
    e.preventDefault();
    setLoading(true);

    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleId: selectedRole.id,
    };

    let returnValue = false;
    try {
      await userService.createNewUser(user, orgCode);
      setSelectedRole("");
      reset();

      // CA TODO: temp hack employed due to issues with data being preserved between launches of this modal
      const notification = {
        type: NotificationType.success,
        message: `${values.firstName} ${
          values.lastName
        } has been created as a ${getRoleName(selectedRole.id)}`,
      };
      onUserCreated(notification);
      setShow(false);
      setLoading();
      returnValue = true;
    } catch (error) {
      const errorMessage =
        error.status == 409
          ? "Record already exists for this email."
          : "Unable to create user.";
      setNotification({
        type: NotificationType.error,
        message: errorMessage,
      });
      returnValue = false;
    }
    setLoading();
    return returnValue;
  }

  const onClick = (e) => {
    (async () => {
      handleSubmit(submitForm)(e);
    })();
  };

  useEffect(() => {
    setFormInvalid(!methods.formState.isValid || !selectedRole);
  }, [methods.formState.isValid]);

  return (
    <div className={clsx("new-user-modal-container", className)}>
      <FullScreenModal
        className="new-user-modal"
        preventClose
        onClose={onClose}
        open={show}
      >
        <FullScreenModalHeader
          title="Create a New User"
          titleIcon="fa-regular fa-user-plus"
        />
        <FullScreenModalBody>
          <div className="multi-org-new-user">
            {loading && <Loader overlay></Loader>}
            <div className="text-with-info">
              <label>
                Required Fields<sup>*</sup>
              </label>
            </div>
            <RoleSelector
              roles={AllRoles}
              onRoleSelected={onRoleSelected}
              selectedRole={selectedRole}
            />

            <div className="new-user-form">
              <FormProvider {...methods}>
                <form className="account-form" noValidate={true}>
                  {selectedRole && (
                    <UserInfo
                      className="user-info"
                      required
                      responseNotification={notification}
                      onNotificationChange={setNotification}
                    />
                  )}
                </form>
              </FormProvider>
            </div>
          </div>
        </FullScreenModalBody>
        <FullScreenModalFooter>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button primary disabled={formInvalid || loading} onClick={onClick}>
            Create User
          </Button>
        </FullScreenModalFooter>
      </FullScreenModal>
    </div>
  );
};

export default NewUserModal;
