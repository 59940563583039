import React, { useState } from "react";
import {
  Loader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { GenericError } from "../../../../../../../../components/utils/notification.constants";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./delete-menu.modal.scss";

export const DeleteMenuModal = ({
  onCancel,
  open,
  onDeleteMenu,
  title,
  setNotification,
}) => {
  const [loading, setLoading] = useState();

  const deleteMenu = async () => {
    setLoading(true);
    try {
      onDeleteMenu();
    } catch {
      setNotification(NotificationType.error, GenericError);
    } finally {
      setLoading();
      onCancel();
    }
  };

  return (
    <Modal
      open={open}
      modalClassName={"delete-modal"}
      onClose={onCancel}
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="red-icon"
        icon={["fa-solid", "fa-clock-rotate-left"]}
        title="Delete Menu"
      ></ModalHeader>
      <ModalBody>
        Are you sure that you would like to delete the menu
        <b> {title}</b>? This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button className="red-icon" destructive onClick={deleteMenu}>
          Yes, Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
