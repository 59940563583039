import React from "react";

import clsx from "clsx";

import { Select } from "@transfr-inc/dashboard-components/forms";
import { OrgOptionItem } from "./org-option-item";

export function SingleOrgSelect({
  selectedOrgs,
  onChange,
  placeholder,
  data,
  className = "",
}) {
  return (
    <Select
      selected={selectedOrgs}
      placeholder={placeholder}
      icon={["fa-light", "fa-folder-tree"]}
      onOptionsChange={onChange}
      className={clsx("user-select", className)}
      options={data?.map((org) => new OrgOptionItem(org))}
      searchEnabled
    ></Select>
  );
}
