import React from "react";
import clsx from "clsx";

import {
  Column,
  ColumnType,
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";
import { Role } from "@transfr-inc/dashboard-components/utils";

import { ClientUsersColumns } from "../../utils/users.columns";
import { ZeroStateMessage } from "../../../users/list/components/zero-state-message";

import "./instructor-users.tab.scss";

export const InstructorUsersTab = ({
  data,
  responsiveStyles,
  bottomBarOptions,
  actionMenuOptions,
  onSetShowNewUser,
  dataFiltered,
  className,
  acrossAllOrgs,
  isRowActive,
}) => {
  const columns = [
    ...(bottomBarOptions
      ? [new Column("select", undefined, ColumnType.SELECTION)]
      : []),
    ClientUsersColumns.AVATAR,
    ClientUsersColumns.LAST_NAME,
    ClientUsersColumns.FIRST_NAME,
    ClientUsersColumns.ORGANIZATION,
    ClientUsersColumns.CLASSROOM,
    ClientUsersColumns.EMAIL,
  ];

  const zeroStateMessage = (
    <ZeroStateMessage
      role={Role.INSTRUCTOR}
      onShowButton={onSetShowNewUser}
      isFiltered={dataFiltered || acrossAllOrgs}
    ></ZeroStateMessage>
  );

  return (
    <div className="instructors-tab">
      <DataTable
        columns={columns}
        data={data}
        className={clsx(
          "instructors-table",
          className,
          bottomBarOptions && "selection"
        )}
        keyFields={["userId"]}
        keyPrefix="instructor-users"
        defaultSortPropName="firstName"
        defaultSortDirection={SortDirection.ASCENDING}
        zeroStateMessage={zeroStateMessage}
        responsiveStyles={responsiveStyles}
        bottomBarOptions={bottomBarOptions}
        tableContentName={{ singular: "user", plural: "users" }}
        actionMenuOptions={actionMenuOptions}
        isRowActive={isRowActive}
        rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
        virtualRowEnabled
      />
    </div>
  );
};
