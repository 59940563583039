export const MULTI_ORG_PARENT = {
  id: 2,
  displayName: "Multi-Organization Parent",
  icon: "fa-light fa-sitemap",
  displayOrder: 1,
  description:
    "Can create clients, has full permissions to menu builder, manage users, and manage category templates.",
};
export const MULTI_ORG_CHILD = {
  id: 3,
  displayName: "Multi-Organization Child",
  icon: "fa-light fa-folder-tree",
  displayOrder: 2,
  description:
    "Can’t create clients, has limited permissions to menu builder, only view users, and has no access to category templates.",
};

export const SINGLE_ORG = {
  id: 1,
  displayName: "Single Organization",
  icon: "fa-light fa-building",
  description:
    "Can’t create clients, has limited permissions to menu builder, only view users, and has no access to category templates.",
};

const orgTypesMap = new Map();
orgTypesMap.set(MULTI_ORG_PARENT.id, MULTI_ORG_PARENT);
orgTypesMap.set(MULTI_ORG_CHILD.id, MULTI_ORG_CHILD);
orgTypesMap.set(SINGLE_ORG.id, SINGLE_ORG);

export function getOrgType(typeId) {
  return orgTypesMap.get(typeId) ?? {};
}

export function getOrgTypeName(typeId) {
  return orgTypesMap.get(typeId)?.displayName;
}

export const AllOrgTypes = [...orgTypesMap.values()];
