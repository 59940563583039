import React from "react";
import { useState } from "react";

import { Loader } from "@transfr-inc/dashboard-components";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { getRoleName } from "../../../constants/role";
import { getRoleName as getClientRoleName } from "@transfr-inc/dashboard-components/utils";

import "./delete-user.modal.scss";

export default ({
  onCancel,
  open,
  users,
  onConfirmDelete,
  async = false,
  clientUser = false,
}) => {
  const [loading, setLoading] = useState();

  const getDeletedRole = (id) => {
    return clientUser ? getClientRoleName(id) : getRoleName(id);
  };

  const getContent = (deleteUserList) => {
    return deleteUserList.length == 1 ? (
      <div className="delete-content">
        Are you sure you would like to delete the{" "}
        {getDeletedRole(deleteUserList[0].roleId)?.toLowerCase()}
        <b>
          {" "}
          {deleteUserList[0].firstName} {deleteUserList[0].lastName}{" "}
        </b>
        ? This action cannot be undone.
      </div>
    ) : (
      <div className="delete-content">
        Are you sure you would like to delete
        <b> {deleteUserList.length} </b>
        {getDeletedRole(deleteUserList[0]?.roleId)?.toLowerCase()}s? This action
        cannot be undone.
      </div>
    );
  };

  const onConfirmHandler = () => {
    if (async) {
      onConfirmHandlerAsync();
    } else {
      onConfirmDelete(users);
    }
  };

  const onConfirmHandlerAsync = async () => {
    try {
      setLoading(true);
      await onConfirmDelete(users);
    } catch {
      setLoading();
    } finally {
      setLoading();
    }
  };

  return (
    <div className={"delete-modal-container"}>
      <Modal modalClassName={"delete-modal"} open={open} onClose={onCancel}>
        {loading && <Loader overlay></Loader>}
        <ModalHeader
          className="red-icon"
          icon={["fa-regular", "fa-trash-can"]}
          title={users.length > 1 ? "Delete Users" : "Delete User"}
        ></ModalHeader>
        <ModalBody className="delete-body">{getContent(users)}</ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button destructive onClick={onConfirmHandler}>
            {users.length > 1
              ? `Yes, Delete ${getDeletedRole(users[0]?.roleId)}s`
              : `Yes, Delete ${getDeletedRole(users[0]?.roleId)}`}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
