import React from "react";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";

import "./edit-warning.modal.scss";

export const EditWarningModal = ({
  open,
  title,
  icon = ["fa-regular", "fa-triangle-exclamation"],
  iconColor = "yellow",
  message,
  onCancel,
  className,
  children,
}) => {
  return (
    <Modal
      open={open}
      modalClassName={clsx("edit-warning-modal", className)}
      onClose={onCancel}
    >
      <ModalHeader
        icon={icon}
        title={title}
        className={`icon-color-${iconColor}`}
      ></ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>{children}</ModalFooter>
    </Modal>
  );
};

export const WarningMessages = {
  menu: {
    title: "Menu in Use",
    updateTitle: "Menu Update",
    delete:
      "Before deleting, please remove this menu from any organizations where it is currently in use.",
    edit: "Edits made to this menu will affect multiple organizations. Are you sure you would like to make edits?",
    update:
      "Customized classroom menus for this organization will be erased as a result of this update. Are you sure you would like to proceed?",
  },
  category: {
    title: "Category in Use",
    delete:
      "Before deleting, please remove this category from any menus where it is currently in use.",
    edit: "Edits made to this category will affect multiple menus. Are you sure you would like to make edits?",
  },
};
