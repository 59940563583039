import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import PrivateRoute from "./components/routing/private-route";
import AppLayout from "./layouts/app";
import { LoginPage, ResetPassword, ForgotPassword } from "./views";

export default () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path={"/app"}>
          <AppLayout></AppLayout>
        </PrivateRoute>
        <Route path="/login" component={LoginPage} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/create-account" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="*">
          <Redirect to={"/app"} />
        </Route>
      </Switch>
    </Router>
  );
};
