import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import AccountLayout from "../../layouts/account";
import { Input, Button } from "@transfr-inc/dashboard-components/forms";

import forgotPasswordImage from "../../../public/images/forgot-password.svg";
import { useHistory } from "react-router-dom";
import { services } from "../../dependencies";

export default () => {
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    delayError: 1000,
    defaultValues: { email: "" },
  });

  const [emailSent, setEmailSent] = useState();

  const { authenticationService } = services;

  async function onSubmit(payload) {
    try {
      await authenticationService.sendResetPassword(payload);
      setEmailSent(true);
    } catch (error) {
      console.error(error);
      setEmailSent(true);
    }
  }

  useEffect(() => {
    if (formState.errors.email) {
      // TODO: Add notification component
      console.log(formState);
    }
  }, [formState]);

  return (
    <AccountLayout title={["Forgot", "Password"]} image={forgotPasswordImage}>
      {formState.isSubmitted && emailSent && (
        <form className="account-form">
          <div className="instructions">
            If an account exists you will receive an email allowing you to reset
            your password. If you can&rsquo;t find the email, check your spam
            folder or contact customer support for help.
          </div>
          <Button
            className="block"
            type="cancel"
            label="Return to Sign In"
            onClick={() => history.replace("/login")}
          />
        </form>
      )}

      {!(formState.isSubmitted && emailSent) && (
        <form
          className="account-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate={true}
        >
          <div className="instructions">
            Please enter the email associated with your Transfr account.
          </div>
          <div className="inputs">
            <Input
              label="Email Address"
              type="email"
              errors={formState.errors?.email}
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
                },
              })}
            />
          </div>
          <div>
            <Button
              label="Send Reset Email"
              className="block"
              primary
              type="submit"
              disabled={!formState.isValid}
            />
            <br />
            <Button
              className="block"
              type="cancel"
              label="Return to Sign In"
              onClick={() => history.replace("/login")}
            />
          </div>
        </form>
      )}
    </AccountLayout>
  );
};
