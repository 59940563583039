import automotiveImage from "../../../public/images/categories/automotive.png";
import aerospaceImage from "../../../public/images/categories/aerospace.png";
import culinaryImage from "../../../public/images/categories/culinary.png";
import dieselImage from "../../../public/images/categories/diesel.png";
import diesel2Image from "../../../public/images/categories/diesel2.png";
import electricalConstructionImage from "../../../public/images/categories/electrical_construction.png";
import hospitalityImage from "../../../public/images/categories/hospitality.png";
import industrialConstructionImage from "../../../public/images/categories/industrial_construction.png";
import manufacturingImage from "../../../public/images/categories/manufacturing.png";
import mechatronicsImage from "../../../public/images/categories/mechatronics.png";
import publicSafetyImage from "../../../public/images/categories/public_safety.png";
import skilledTradesImage from "../../../public/images/categories/skilled_trades.png";
import warehouseStorageImage from "../../../public/images/categories/warehouse_storage.png";
import workforceDevelopmentImage from "../../../public/images/categories/workforce_development.png";
import softSkillsImage from "../../../public/images/categories/soft_skills.png";
import autobodyCollisionImage from "../../../public/images/categories/autobody_collision.png";
import automotiveFundamentalsImage from "../../../public/images/categories/automotive_fundamentals.png";
import blueprintReadingImage from "../../../public/images/categories/blueprint_reading.png";
import constructionSafetyImage from "../../../public/images/categories/construction_safety.png";
import electricalFundamentalsImage from "../../../public/images/categories/electrical_fundamentals.png";
import paintRobotTroubleshoothingImage from "../../../public/images/categories/paint_robot_troubleshoothing.png";
import plantSafetyImage from "../../../public/images/categories/plant_safety.png";
import precisionMesurementImage from "../../../public/images/categories/precision_mesurement.png";
import vhcImage from "../../../public/images/categories/vhc.png";

export const CategoriesImages = [
  {
    bundlePath: "file://banners/categories/automotive.png",
    imagePath: automotiveImage,
    label: "Automotive",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/aerospace.png",
    imagePath: aerospaceImage,
    label: "Aerospace",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/culinary.png",
    imagePath: culinaryImage,
    label: "Culinary",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/diesel.png",
    imagePath: dieselImage,
    label: "Diesel I",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/diesel2.png",
    imagePath: diesel2Image,
    label: "Diesel II",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/electricalconstruction.png",
    imagePath: electricalConstructionImage,
    label: "Electrical Construction",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/hospitality.png",
    imagePath: hospitalityImage,
    label: "Hospitality",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/industrial_construction.png",
    imagePath: industrialConstructionImage,
    label: "Industrial Construction",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/manufacturing.png",
    imagePath: manufacturingImage,
    label: "Manufacturing",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/mechatronics.png",
    imagePath: mechatronicsImage,
    label: "Mechatronics",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/public_safety.png",
    imagePath: publicSafetyImage,
    label: "Public Safety",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/skilled_trades.png",
    imagePath: skilledTradesImage,
    label: "Skilled Trades",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/warehouse_storage.png",
    imagePath: warehouseStorageImage,
    label: "Warehouse Storage",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/mccwed.png",
    imagePath: workforceDevelopmentImage,
    label: "Workforce Development",
    isCustom: true,
  },
  {
    bundlePath: "file://banners/categories/soft_skills.png",
    imagePath: softSkillsImage,
    label: "Soft Skills",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/precision_mesurement.png",
    imagePath: precisionMesurementImage,
    label: "Precision Measurement",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/plant_safety.png",
    imagePath: plantSafetyImage,
    label: "Plant Safety",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/paint_robot_troubleshoothing.png",
    imagePath: paintRobotTroubleshoothingImage,
    label: "Paint Robot Troubleshooting",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/electrical_fundamentals.png",
    imagePath: electricalFundamentalsImage,
    label: "Electrical Fundamentals",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/construction_safety.png",
    imagePath: constructionSafetyImage,
    label: "Construction Safety",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/blueprint_reading.png",
    imagePath: blueprintReadingImage,
    label: "Blueprint Reading",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/automotive_fundamentals.png",
    imagePath: automotiveFundamentalsImage,
    label: "Automotive Fundamentals",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/autobody_collision.png",
    imagePath: autobodyCollisionImage,
    label: "Auto Body Collision",
    isCustom: false,
  },
  {
    bundlePath: "file://banners/categories/vhc.png",
    imagePath: vhcImage,
    label: "VHC",
    isCustom: false,
  },
];

const getNoImageFoundPlaceholder = (bundlePath) => {
  return {
    bundlePath,
    label: `No Image Found (${bundlePath})`,
  };
};

const CategoriesImagesMap = new Map(
  CategoriesImages.map((c) => [c.bundlePath, c])
);

export const getCategoryImage = (bundlePath) => {
  return (
    CategoriesImagesMap.get(bundlePath) ??
    getNoImageFoundPlaceholder(bundlePath)
  );
};

export const getCategoriesImages = () => {
  const categoriesImages = CategoriesImages.filter((c) => !c.isCustom);

  return categoriesImages;
};
