import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
  Input,
  useSearch,
} from "@transfr-inc/dashboard-components/forms";

import { RequiredItem, TextTooltip } from "@transfr-inc/dashboard-components";
import { WarningMessages } from "../../modals/edit-warning/edit-warning.modal";
import { useEditWarningModal } from "../../../hooks";

import "./org-select-menu.editor.scss";

const MenuCategoriesTooltip = ({ categories }) => {
  const tooltipHtmlContent = (
    <div className="menu-categories-tooltip">
      <h3>Categories</h3>
      <ul>
        {categories.map((c) => (
          <li key={c.categoryId}>{c.categoryName}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <TextTooltip
      theme={"menu-categories"}
      html={tooltipHtmlContent}
      overflowEnabled
    >
      <FontAwesomeIcon icon="fa-light fa-circle-info" />
    </TextTooltip>
  );
};

export function OrgSelectMenuEditor({
  menus,
  name = "menu",
  clearEnabled = false,
  className,
  isEdit = false,
}) {
  const [menuNameSearchTerm, setMenuNameSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [updatedValue, setUpdateValue] = useState();

  const { control, setValue } = useFormContext();

  const menuNameSearch = useSearch({
    data: menus,
    searchKeys: ["name"],
  });

  const onSearchTermChange = (value) => {
    setMenuNameSearchTerm(value);
    setSearchResults(menuNameSearch.queryData(value));
  };

  const handleChange = (value) => {
    setUpdateValue(value);
    onSearchTermChange("");
    isEdit && openWarningEditModal();
  };

  const onCancel = () => {
    setUpdateValue();
  };

  const onConfirmHandler = () => {
    setValue("menu", updatedValue, { shouldValidate: true, shouldDirty: true });
    setUpdateValue();
  };

  useEffect(() => {
    if (menus) {
      setSearchResults(menuNameSearch.updateData(menus));
    }
  }, [menus]);

  useEffect(() => {
    !isEdit && updatedValue && onConfirmHandler();
  }, [updatedValue]);

  const [warningEditModal, openWarningEditModal] = useEditWarningModal({
    title: WarningMessages.menu.updateTitle,
    message: WarningMessages.menu.update,
    icon: ["fa-regular", "fa-triangle-exclamation"],
    iconColor: "danger-red",
    buttons: [
      {
        id: "no",
        label: "No, Cancel",
        handler: onCancel,
      },
      {
        id: "yes",
        label: "Yes, Update",
        handler: onConfirmHandler,
        destructive: true,
      },
    ],
  });

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>
          <RequiredItem text="TS Menu" />
        </h2>
      </div>
      <div className="section-description">
        <RequiredItem text="Custom menus are only available for Training Simulations" />
      </div>
      <Controller
        control={control}
        rules={{ required: true }}
        name={name}
        render={({ field: { value } }) => {
          return (
            <Dropdown
              className="menu-dropdown"
              selected={value}
              onOptionSelected={handleChange}
            >
              <DropdownButton
                placeholder={"Select an existing TS menu template"}
                icon={"fa-regular fa-layer-group"}
                clearEnabled={clearEnabled}
              >
                {value?.name}
              </DropdownButton>
              <DropdownItems>
                <Input
                  className="menu-name-search"
                  value={menuNameSearchTerm}
                  onChange={({ target }) => onSearchTermChange(target.value)}
                  placeholder="Search"
                  type="text"
                />
                {searchResults?.map((menu) => (
                  <DropdownItem
                    key={menu.id}
                    value={menu}
                    propName={"id"}
                    className="menu-dropdown-item"
                  >
                    <div className="menu-dropdown-item-info">
                      <h3>{menu.name}</h3>
                      <span title={menu.description}>{menu.description}</span>
                    </div>
                    <div className="menu-dropdown-item-tooltip">
                      <MenuCategoriesTooltip categories={menu.categories} />
                    </div>
                  </DropdownItem>
                ))}
              </DropdownItems>
            </Dropdown>
          );
        }}
      />
      {isEdit && warningEditModal}
    </div>
  );
}
