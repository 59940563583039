import React from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import { Input } from "@transfr-inc/dashboard-components/forms";

export function OrgTrekLicensesEditor({ className, name = "trekLicenses" }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>Trek License Limit</h2>
      </div>
      <Controller
        control={control}
        name={name}
        rules={{
          required: false,
          max: 99999,
          min: 0,
          maxLength: 5,
        }}
        defaultValue={""}
        render={({ field: { onBlur, onChange, value, ref } }) => {
          return (
            <Input
              className="org-trek-input"
              label="Max Trek licenses for this organization"
              errors={errors[name]}
              name={name}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value || ""}
              maxLength={5}
              onlyNumbers
            />
          );
        }}
      />
    </div>
  );
}
