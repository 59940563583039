import HttpClient from "../utils/http-client";

export class OrganizationService extends HttpClient {
  async getAllOrganizations() {
    return await this.http.get(`/client-services/organizations`);
  }

  async getSingleOrgs() {
    return await this.http.get(`/client-services/organizations/single`);
  }

  async getParentOrgs() {
    return await this.http.get(`/client-services/organizations/parent`);
  }

  async getChildOrganizations(multiOrgCode) {
    const apiUrl = `/multi-organization/${multiOrgCode}/organizations`;

    const data = await this.http.get(apiUrl);
    return data;
  }

  async getChildOrgs(code) {
    return await this.http.get(`/client-services/organization/${code}/child`);
  }

  async getOrganizationDetails(code) {
    return await this.http.get(`/organization/${code}`);
  }

  async getOrganizationDisciplines(code) {
    return await this.http.get(
      `/client-services/organizations/${code}/disciplines`
    );
  }

  async getOrganizationMenu(code) {
    return await this.http.get(`/client-services/organizations/${code}/menu`);
  }

  async getOrganizationSimulations(code) {
    return await this.http.get(
      `/client-services/organizations/${code}/ce/simulations`
    );
  }

  async createOrg(org) {
    return await this.http.post(`/client-services/organization`, org);
  }

  async updateOrgMetadata(org) {
    return await this.http.put(
      `/client-services/organization/${org.code}`,
      org
    );
  }

  exportParentChildPairs(filename) {
    const requestConfig = {
      method: "get",
      url: "/client-services/organizations/parent-child-pairs/export",
    };

    return this.downloadBlob(requestConfig, filename, "csv");
  }
}
