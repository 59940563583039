import React, { useEffect, useState } from "react";
import { Role, getRoleFeatures } from "../constants/role";

import { services } from "../dependencies";
import { parseJwt } from "../utils/browser";

const validateSession = (user) => {
  return user?.exp > Math.floor(Date.now() / 1000);
};

const validateCSRole = (role) => {
  if (role === Role.CS_ADMIN.id || role === Role.CS_MANAGER.id) {
    return true;
  } else {
    throw new Error("User unauthorized");
  }
};

export const SessionContext = React.createContext({});

export default ({ userCache, tokenCache, children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [token, setToken] = useState();
  const [role, setRole] = useState();
  const [orgCode, setOrgCode] = useState();
  const [features, setFeatures] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [error, setError] = useState();
  const [isMount, setIsMount] = useState();

  const { authenticationService } = services;

  const contextValue = {
    currentUser,
    token,
    orgCode,
    features,
    isLoggedIn,
    error,
    role,
    login: async ({ username, password }) => {
      try {
        setError(false);
        const user = await authenticationService.login(username, password);
        setValidUser(user);
      } catch (error) {
        console.error(error);
        tokenCache.remove();
        userCache.remove();
        setError(error);
      }
    },
    logout: () => {
      setCurrentUser();
      tokenCache.remove();
      userCache.remove();
    },
  };

  useEffect(() => {
    setIsLoggedIn(validateSession(currentUser));
  }, [currentUser]);

  useEffect(() => {
    const user = userCache?.get();
    const decodedToken = user && parseJwt(user?.token);
    setCurrentUser(user);
    setToken(tokenCache?.get());
    setIsLoggedIn(validateSession(user));
    setRole(decodedToken?.act);
    setOrgCode(user?.organizationCode);
    setFeatures(getRoleFeatures(decodedToken?.act));
    setIsMount(true);
  }, []);

  const setValidUser = (user) => {
    const currentToken = parseJwt(user.token);
    const currentUser = { exp: currentToken.exp, ...user };
    const role = currentToken?.act;

    validateCSRole(role);
    tokenCache.set(user.token, { exp: currentToken.exp });
    userCache.set(currentUser, { exp: currentToken.exp });
    setCurrentUser(currentUser);
    setToken(currentToken);
    setRole(role);
    setOrgCode(currentUser?.organizationCode);
    setFeatures(getRoleFeatures(role));
  };

  return (
    isMount && (
      <SessionContext.Provider value={contextValue}>
        {children}
      </SessionContext.Provider>
    )
  );
};
